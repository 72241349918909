import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { Layout } from '../app/app';
import ErrorPage from './../pages/Error/Error';
import PrivateRoute from './privateRoute'
import { HRListing, Login, Forgot, ChangePassword, Dashboard, AddUser, NotFound, UserAccessListing, MasterModule, ModuleListing, UserAccessForm, SinglePdfView,SinglePdfPreview } from '../pages'
import Verified from '../pages/login/Verified';
import UserProfile from '../pages/profile/UserProfile';
import EditProfile from '../pages/profile/EditProfile';
import { UserAccessFormLoader } from '../pages/humanResources/UserAccessForm';
import { perm } from '../assets/constant';
import PdfView from '../pages/pdf/PdfView';

const router = (accessToken) => createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Layout />} errorElement={<ErrorPage />}>
            {/* {(accessToken && !user) && <Route path="*" element={<>Loading</>} />} */}

            {/* ============== Public Routes ==============  */}
            {!accessToken && <Route index element={<Login />} />}
            <Route exact path='/forgot' element={<Forgot />} />
            <Route exact path='/verify-email/:token' element={<Verified />} />
            <Route path="/change-password/:token" element={<ChangePassword />} />


            {/* ============== Private Routes ==============  */}
            <Route path='/' element={<PrivateRoute permissions="dashboard" displayName="Dashboard" component={Dashboard} />} />
            {/* ================ HR Module =======================*/}
            <Route path="human-resources" element={<PrivateRoute permissions="human-resources" displayName="Human resource" component={HRListing} />} />
            <Route path="/human-resources/master-module" element={<PrivateRoute permissions="human-resources" component={MasterModule} />} >
            <Route path=":type" element={<ModuleListing />} />
            </Route>

            <Route path="/human-resources/single-pdf-preview/:id" element={<PrivateRoute permissions="human-resources" displayName="Pdf Preview" component={SinglePdfPreview} />} />
            {/* <Route path="/human-resources/single-pdf-preview/:id" element={<PrivateRoute permissions="human-resources" displayName="Pdf Preview" component={PdfView} />} /> */}
            <Route path="/human-resources/add-employee" element={<PrivateRoute permissions="human-resources" displayName="Add User" component={AddUser} />} />
            <Route path="/human-resources/edit-employee/:id" element={<PrivateRoute permissions="human-resources" access="edit"  component={EditProfile} mode={perm.edit} />} />
            <Route path="/human-resources/view-employee/:id" element={<PrivateRoute permissions="human-resources" access="view" component={EditProfile} mode={perm.view} />} />

            {/* ================ Access Panel ======================= */}
            <Route path="user-access-listing" element={<PrivateRoute displayName="User List" role={['super_admin', 'admin']} component={UserAccessListing} />} />
            <Route path="add-access-user" element={<PrivateRoute displayName="Add User" role={['super_admin', 'admin']} component={UserAccessForm} mode={perm.add} />} />
            <Route path="edit-access-user/:id" loader={UserAccessFormLoader} element={<PrivateRoute displayName="Edit User" role={['super_admin', 'admin']} component={UserAccessForm} mode={perm.edit} />} />

            <Route path="user-profile" element={<PrivateRoute displayName="Add User" component={UserProfile} />} />
            <Route path="leave-management" element={<PrivateRoute permissions="dashboard" displayName="Dashboard" component={Dashboard} />} />
            <Route path="da-wiki" element={<PrivateRoute permissions="dashboard" displayName="Dashboard" component={Dashboard} />} />
            <Route path="project-management" element={<PrivateRoute permissions="dashboard" displayName="Dashboard" component={Dashboard} />} />
            <Route path="english-translation" element={<PrivateRoute permissions="dashboard" displayName="Dashboard" component={Dashboard} />} />
            <Route path="calendar" element={<PrivateRoute permissions="dashboard" displayName="Dashboard" component={Dashboard} />} />
            <Route path="istirshad" element={<PrivateRoute permissions="dashboard" displayName="Dashboard" component={Dashboard} />} />
            <Route path="webinar" element={<PrivateRoute permissions="dashboard" displayName="Dashboard" component={Dashboard} />} />
            <Route path="event-logs" element={<PrivateRoute permissions="event-logs" displayName="Dashboard" component={Dashboard} />} />
            <Route path="edit-profile" element={<PrivateRoute permissions="dashboard" displayName="Dashboard" component={EditProfile} mode={perm.edit} />} />



            {/* ============== Not Found ==============  */}
            <Route path="*" element={<NotFound />} />
            <Route path="/notfound" element={<NotFound />} />
        </Route>
    )
);

export default router;