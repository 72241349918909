import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    width={20}
    height={20}
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 150 150",
    }}
    viewBox="0 0 150 150"
    {...props}
  >
    <style>{".st0{fill:#ac1c30}"}</style>
    <path
      d="M136.8 103.7c13.2-31.6-2.4-70.3-33.8-84-1.3-.6-2.7 0-3.3 1.2-.6 1.3 0 2.7 1.2 3.3 29.3 13.3 43.1 47.2 31.4 77.2-6.2-1.6-12.6 2.2-14.1 8.5-1.6 6.2 2.2 12.6 8.5 14.1 6.2 1.6 12.6-2.2 14.1-8.5 1-4.3-.5-8.9-4-11.8zm-7.3 15.7c-3.7 0-6.6-3-6.6-6.6 0-3.7 3-6.6 6.6-6.6 3.7 0 6.6 3 6.6 6.7-.1 3.6-3 6.5-6.6 6.5zM13 87.5c4-.4 1.9-5.4 2.3-8 0-29.5 21.3-54.7 50.5-59.7 1.8 6.2 8.2 9.7 14.4 7.9 5-1.4 8.4-6 8.4-11.2C88.3 2.3 67.8.8 65.4 14.8 31.5 20.3 7.6 51 10.5 85.2c.2 1.3 1.2 2.3 2.5 2.3zM76.9 9.9c3.7 0 6.6 3.1 6.6 6.7s-3 6.5-6.6 6.6c-3.7 0-6.6-3.1-6.6-6.7.1-3.6 3-6.5 6.6-6.6zM109.9 129.6c-26.2 17.8-61.6 12.4-81.3-12.3 4.2-4.8 3.7-12.1-1.1-16.4-4.8-4.2-12.1-3.7-16.4 1.1-4.2 4.8-3.7 12.1 1.1 16.4 3.4 3 8.2 3.7 12.3 1.8 21.3 26.9 59.8 32.8 88.2 13.5 1.1-.8 1.4-2.4.6-3.5-.8-1.1-2.3-1.4-3.4-.6zm-96.7-20c0-3.7 3.1-6.6 6.7-6.6s6.5 3 6.6 6.6c0 3.7-3 6.6-6.7 6.6-3.6-.1-6.5-3-6.6-6.6z"
      className="st0"
    />
    <path
      d="M74.8 42.7c-15.9-.2-24.7 19.3-14.3 31C50.3 78.5 43 88.1 41.2 99.2c-.6 3.8 2 7.3 5.8 7.9.3.1.7.1 1 .1h53.7c3.8 0 6.9-3.2 6.9-7 0-.3 0-.7-.1-1-1.9-11.2-9.2-20.7-19.4-25.4 10.4-11.7 1.6-31.3-14.3-31.1zm0 5c18.2.6 18.2 27 0 27.6-18.2-.5-18.2-27 0-27.6zm28.7 52.3c.2 1-.5 2-1.5 2.2H48c-.6 0-1.1-.2-1.4-.7-.4-.4-.5-1-.4-1.5a29.6 29.6 0 0 1 18.7-22.6c6.1 3.9 13.9 3.9 20 0 9.7 3.7 16.9 12.3 18.6 22.6z"
      className="st0"
    />
  </svg>
)
export default SvgComponent
