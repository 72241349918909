import React from 'react';
import { Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import Quicksand from '../../assets/fonts/Quicksand-Medium.ttf' 
import Nouser from './img/no-user.png'

Font.register({ family: 'Quicksand', src: Quicksand });

const FamilyCardPDF = ({ name, image, title, idara, its_no, age }) => (
    <View style={styles.familyCardWrapper}>
      <View style={styles.cardTitle}>
        <Text style={{color:'#fff',fontSize:8}}>{title}</Text>
      </View>
      <View style={{width:'25%'}}>
        
        { image ? <Image src={`${process.env.REACT_APP_FILE_BASE_URL}/${image}`} crossOrigin="anonymous" style={styles.imageRounded} /> : <Image src={Nouser} crossOrigin="anonymous" style={styles.imageRoundedNouser} />}
      </View>
      <View style={styles.familyInfo}>
        {name && <View style={styles.contentPairBlock}><Text style={styles.label}>Name.: </Text><Text style={styles.textStyle}>{name || '-'}</Text></View>}
        {idara && <View style={styles.contentPairBlock}><Text style={styles.label}>Idara.: </Text><Text style={styles.textStyle}>{idara || '-'}</Text></View>}
       <View style={{display:'flex',flexDirection:'row',gap:50}}> {its_no && <Text style={styles.contentPair}><Text style={styles.label}>ITS No.: </Text><Text style={styles.textStyle}>{its_no}</Text></Text>}{age && <Text style={styles.contentPair}><Text style={styles.label}>Age: </Text><Text style={styles.textStyle}>{age}</Text></Text>}</View>
      </View>
    </View>
  );
export default FamilyCardPDF

const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      padding: 20,
    },
    contentPair:{
        paddingBottom:3
      },
      contentPairBlock:{
        display: 'flex',
        flexDirection:'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingBottom:3,
        gap:3
      },
      label: {
        fontSize: 8,
        color: "#ac1c30",
        fontFamily: 'Quicksand',
        marginRight:5
      },
      textStyle: {
        fontWeight: 700,
        fontSize: 8,
        fontFamily: 'Quicksand',      
      },
    familyCardWrapper: {
      width: '49%',
      height:'auto',
      // maxHeight: 100,
      border: '1px solid #ac1c30',
      display: 'flex',
      flexDirection:'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      borderRadius: 10,
      padding: 10,
      paddingTop:15,
      paddingRight:20,
      marginBottom: 20,
      gap:15
    },
    imageRounded:{
      height:40,
      width:40,
      objectFit:'fill',
      borderRadius:'50%',
      borderWidth:1,
      borderColor:'#ac1c30',
      borderStyle:'solid',
    },
    cardTitle: {
      position: 'absolute',
      top: -10,
      width: 70,
      fontSize:8,
      backgroundColor: '#ac1c30',
      color: '#fff',
      height:20,
      borderRadius: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Quicksand',
    },
    familyInfo: {
      width: '75%',
    },
    imageRoundedNouser:{
      height:40,
      width:40,
      borderRadius:'50%',
      borderWidth:1,
      objectFit:'cover',
      borderColor:'#ac1c30',
      borderStyle:'solid',
    },
  });