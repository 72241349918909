import React from 'react';
import { Dropdown } from '../../components';

const SkillSet = ({ data, setData, mastermodule }) => {
    let skillOpt = [];
    let industryOpt = [];
    let competenceOpt = [
    { value: 'a', label: 'A' },
    { value: 'b', label: 'B' },
    { value: 'c', label: 'C' },
    { value: 'd', label: 'D' },
];
    if (mastermodule.skill_set_type) {
        mastermodule.skill_set_type.forEach((item) => {
            skillOpt.push({ value: item.id, label: item.title });
        })
    }
    if (mastermodule.industry_type) {
        mastermodule.industry_type.forEach((data) => {
            industryOpt.push({ value: data.id, label: data.title });
        })
    }

    return (
        <div className="personal-sec">
            <h2 className='section-name'>Skill set</h2>
            <div className="row mt-2">

                <div className="col-md-4 mt-3 "><label htmlFor="skill_set_type" style={{ margin: '0 0 5px 12px' }}>Skill Set Type</label><Dropdown options={skillOpt} onChange={(e) => setData({ ...data, skill_set_type: e.value })} set_value={data.skill_set_type} placeholder="Skill Set Type" gap={'0px'} padding={'0 10px'} width={'100%'} /></div>

                <div className="col-md-4 mt-3 "><label htmlFor="skill_set_type" style={{ margin: '0 0 5px 12px' }}>Level of Competence</label><Dropdown options={competenceOpt} onChange={(e) => setData({ ...data, level_of_competence: e.value })} set_value={data.level_of_competence} placeholder="Level of Competence" gap={'0px'} padding={'0 10px'} width={'100%'} /></div>
                <div className="col-md-4 mt-3 "><label htmlFor="industry_type" style={{ margin: '0 0 5px 12px' }}>Industry Type</label><Dropdown options={industryOpt} onChange={(e) => setData({ ...data, industry_type: (e.map(e => e.value)).toString()  })} set_value={data.industry_type} placeholder="Industry Type" isMulti gap={'0px'} padding={'0 10px'} width={'100%'} /></div>
            </div>
        </div>)
}

export default SkillSet