import * as React from "react"
const LeaveIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={20}
    height={20}
    style={{
      enableBackground: "new 0 0 150 150",
    }}
    viewBox="0 0 150 150"
    {...props}
  >
    <path d="M125.3 127.3H81.4c-1.1 0-2.1-.9-2.1-2.1 0-1.1.9-2.1 2.1-2.1h43.9c1.9 0 3.5-1.5 3.5-3.5V30.1c0-1.9-1.5-3.5-3.5-3.5h-9.4c-1.1 0-2.1-.9-2.1-2.1s.9-2.1 2.1-2.1h9.4c4.2 0 7.6 3.4 7.6 7.6v89.5c-.1 4.4-3.5 7.8-7.6 7.8zM56.5 127.3H12.6c-4.2 0-7.6-3.4-7.6-7.6V30.2c0-4.2 3.4-7.6 7.6-7.6H22c1.1 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1h-9.4c-1.9 0-3.5 1.5-3.5 3.5v89.5c0 1.9 1.5 3.5 3.5 3.5h43.9c1.1 0 2.1.9 2.1 2.1s-1 1.9-2.1 1.9zM62.9 26.8H34.2c-1.1 0-2.1-.9-2.1-2.1s.9-2.1 2.1-2.1h28.7c1.1 0 2.1.9 2.1 2.1s-1 2.1-2.1 2.1zM103.7 26.8H75c-1.1 0-2.1-.9-2.1-2.1s.9-2.1 2.1-2.1h28.7c1.1 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1z" />
    <path d="M137.4 139.5H24.7c-4.2 0-7.6-3.4-7.6-7.6v-6.6c0-1.1.9-2.1 2.1-2.1s2.1.9 2.1 2.1v6.6c0 1.9 1.5 3.5 3.5 3.5h112.6c1.9 0 3.5-1.5 3.5-3.5V42.4c0-1.9-1.5-3.5-3.5-3.5h-6.6c-1.1 0-2.1-.9-2.1-2.1s.9-2.1 2.1-2.1h6.6c4.2 0 7.6 3.4 7.6 7.6v89.5c0 4.3-3.4 7.7-7.6 7.7z" />
    <path d="M130.8 60H7.1C6 60 5 59.1 5 57.9c0-1.1.9-2.1 2.1-2.1h123.7c1.1 0 2.1.9 2.1 2.1 0 1.1-1 2.1-2.1 2.1zM28.1 39c-4.5 0-8.1-3.7-8.1-8.1V18.8c0-4.5 3.7-8.1 8.1-8.1s8.1 3.7 8.1 8.1v12.1c0 4.4-3.7 8.1-8.1 8.1zm0-24.3c-2.2 0-4 1.8-4 4v12.1c0 2.2 1.8 4 4 4s4-1.8 4-4V18.7c0-2.2-1.8-4-4-4zM68.9 39c-4.5 0-8.1-3.7-8.1-8.1V18.8c0-4.5 3.7-8.1 8.1-8.1 4.5 0 8.1 3.7 8.1 8.1v12.1c.1 4.4-3.6 8.1-8.1 8.1zm0-24.3c-2.2 0-4 1.8-4 4v12.1c0 2.2 1.8 4 4 4s4-1.8 4-4V18.7c0-2.2-1.8-4-4-4zM109.8 39c-4.5 0-8.1-3.7-8.1-8.1V18.8c0-4.5 3.7-8.1 8.1-8.1s8.1 3.7 8.1 8.1v12.1c0 4.4-3.6 8.1-8.1 8.1zm0-24.3c-2.2 0-4 1.8-4 4v12.1c0 2.2 1.8 4 4 4s4-1.8 4-4V18.7c0-2.2-1.8-4-4-4zM80.5 111.428c-.3 0-.7-.1-1-.2l-10.6-5.6-10.6 5.6c-.7.4-1.5.3-2.2-.2-.6-.5-1-1.2-.8-2l2-11.8-8.6-8.4c-.6-.6-.8-1.4-.5-2.1.2-.7.9-1.3 1.7-1.4l11.8-1.7 5.3-10.7c.3-.7 1.1-1.2 1.9-1.2.8 0 1.5.4 1.9 1.2l5.3 10.7 11.8 1.7c.8.1 1.4.7 1.7 1.4.2.7 0 1.6-.5 2.1l-8.6 8.4 2 11.8c.1.8-.2 1.6-.8 2-.4.3-.8.4-1.2.4zm-11.6-10.2c.3 0 .7.1 1 .2l7.8 4.1-1.5-8.7c-.1-.7.1-1.4.6-1.8l6.3-6.2-8.8-1.3c-.7-.1-1.3-.5-1.6-1.1l-3.9-7.9-3.9 7.9c-.3.6-.9 1-1.6 1.1l-8.8 1.3 6.3 6.2c.5.5.7 1.2.6 1.8l-1.5 8.7 7.8-4.1c.6-.1.9-.2 1.2-.2zM68.9 127.3h-.4c-.1 0-.3-.1-.4-.1-.1-.1-.2-.1-.4-.2l-.3-.3-.3-.3c-.1-.1-.1-.2-.2-.4-.1-.1-.1-.3-.1-.4v-.8c0-.1.1-.3.1-.4.1-.1.1-.2.2-.4l.3-.3.3-.3c.1-.1.2-.1.4-.2.1-.1.3-.1.4-.1.3-.1.5-.1.8 0 .1 0 .3.1.4.1.1.1.2.1.4.2l.3.3.3.3c.1.1.1.2.2.4.1.1.1.3.1.4v.8c0 .1-.1.3-.1.4-.1.1-.1.2-.2.4l-.3.3-.3.3c-.1.1-.2.1-.4.2-.1.1-.3.1-.4.1h-.4z" />
  </svg>
)
export default LeaveIcon
