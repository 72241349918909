import * as React from "react"
import NoImage from "./icons/no-image"
const Defaultprof = ({ gender, ...props }) => (
  gender === 'M'
    ?
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      {...props}
    >
      <path
        fill="#ECF4FF"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20Z"
      />
      <path
        fill="#DDEAFB"
        d="m27.867 9.36-2.458 3.098-6.868 14.71-9.652 9.463c3.178 2.128 7 3.369 11.11 3.369 10.562 0 19.21-8.186 19.949-18.56L27.868 9.36Z"
      />
      <path fill="#FCD088" d="M23.334 22.83h-6.667v6.666h6.667V22.83Z" />
      <path fill="#DDAB62" d="M23.334 22.83h-3.378v6.666h3.378V22.83Z" />
      <path
        fill="#D9EDEC"
        d="m15.583 27.457-3.533.707a3.848 3.848 0 0 0-3.093 3.773v4.673a19.785 19.785 0 0 0 11.044 3.348c4.086 0 7.885-1.233 11.043-3.348v-4.673a3.848 3.848 0 0 0-3.093-3.773l-3.533-.707-.624-.911a.433.433 0 0 0-.528-.154L20 27.8l-3.246-1.405a.433.433 0 0 0-.527.148l-.645.915Z"
      />
      <path
        fill="#A9BFBE"
        d="m27.95 28.164-3.534-.707-.624-.91a.433.433 0 0 0-.528-.154l-3.265 1.406-.045-.02v12.179H20c4.087 0 7.885-1.233 11.044-3.348v-4.673a3.848 3.848 0 0 0-3.094-3.773Z"
      />
      <path
        fill="#FFEDB5"
        d="M27.15 16.05c.036-.332.055-.658.055-.977 0-4.217-3.226-7.635-7.205-7.635-3.98 0-7.206 3.418-7.206 7.635 0 .319.019.645.055.977-.7.182-1.032 1.257-.743 2.408.29 1.154 1.095 1.947 1.798 1.77a.82.82 0 0 0 .182-.072c1.302 2.613 3.465 4.734 5.914 4.734 2.448 0 4.611-2.12 5.913-4.734.058.03.118.056.182.072.703.176 1.508-.616 1.798-1.77.289-1.151-.044-2.226-.743-2.408Z"
      />
      <path
        fill="#E8CF89"
        d="M27.15 16.05c.036-.332.054-.658.054-.977 0-4.217-3.226-7.635-7.205-7.635l-.045.001v17.45H20c2.449 0 4.611-2.12 5.914-4.733.057.03.117.056.181.072.704.176 1.509-.616 1.798-1.77.29-1.151-.043-2.226-.742-2.408Z"
      />
      <path
        fill="#494948"
        d="M15.95 13.333s3.13 2.997 9.528 1.92c0 0 .62-.156 1.674.797 0 0 1.76-2.548.716-6.69 0 0-3.232-1.043-4.747-1.986-1.515-.943-5.556-1.953-6.465.875 0 0-2.929-.37-3.737 2.66-.808 3.03-.069 5.14-.069 5.14s1.517-3.659 3.1-2.716Z"
      />
      <path
        fill="#282826"
        d="M27.867 9.36s-3.233-1.044-4.748-1.986c-.701-.437-1.943-.886-3.165-.964v8.779c1.445.318 3.282.44 5.522.064 0 0 .62-.156 1.674.797 0 0 1.76-2.548.717-6.69Z"
      />
      <path
        fill="#B7CECC"
        d="m20 37.614-3.491-6.263 3.49-3.48 3.491 3.48L20 37.614Z"
      />
      <path
        fill="#8EA5A2"
        d="m23.49 31.35-3.491-3.479-.045.045v9.618l.045.08 3.49-6.263Z"
      />
      <path
        fill="#386895"
        d="m21.46 29.337-1.459-1.466-1.459 1.466.82 1.053v6.36h1.279v-6.36l.82-1.053Z"
      />
      <path
        fill="#273B7A"
        d="m19.999 27.871-.045.045v8.835h.685V30.39l.82-1.053-1.46-1.466Z"
      />
      <path
        fill="#273B7A"
        d="M27.998 28.134 25.2 27.61 20 36.46l-5.208-8.844-2.791.519a3.872 3.872 0 0 0-3.112 3.796v4.701c3.178 2.127 7 3.369 11.11 3.369 4.112 0 7.934-1.242 11.112-3.369V31.93a3.872 3.872 0 0 0-3.112-3.796Z"
      />
      <path
        fill="#121149"
        d="m27.998 28.133-2.798-.522L20 36.46l-.045-.076v3.616H20c4.112 0 7.933-1.24 11.111-3.368V31.93a3.872 3.872 0 0 0-3.112-3.797Z"
      />
    </svg>
    :
    gender === 'F'
      ?
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        fill="none"
        {...props}
      >
        <path
          fill="#ECF4FF"
          d="M7.57 35.669C2.957 32.005 0 26.348 0 20 0 8.954 8.954 0 20 0s20 8.954 20 20c0 6.348-2.957 12.005-7.57 15.669H7.57Z"
        />
        <path
          fill="#DDEAFB"
          d="m27.507 8.086-.405 27.583h5.328c4.473-3.554 7.39-8.983 7.561-15.098L27.507 8.086Z"
        />
        <path
          fill="#636978"
          d="M19.999 4.687a9.99 9.99 0 0 0-9.974 9.438L8.858 35.233h11.236l.78-13.526-.78-17.019-.095-.001Z"
        />
        <path
          fill="#414952"
          d="M29.973 14.125A9.989 9.989 0 0 0 20 4.687h-.028v30.546H31.14l-1.167-21.108Z"
        />
        <path
          fill="#fff"
          d="M12.242 28.203a4.12 4.12 0 0 0-4.074 3.505l-.599 3.96A19.915 19.915 0 0 0 20 40l.096-.001.78-6.015-.78-5.78h-7.853Z"
        />
        <path
          fill="#E9EDF5"
          d="m32.43 35.669-.599-3.961a4.12 4.12 0 0 0-4.074-3.505h-7.785V40h.027c4.698 0 9.018-1.62 12.43-4.331Z"
        />
        <path
          fill="#FFBEAA"
          d="M16.768 25.025v4.329A3.233 3.233 0 0 0 20 32.587c.032 0 .064-.002.095-.003l.782-3.778-.782-3.78h-3.327Z"
        />
        <path
          fill="#FAA68E"
          d="M19.972 25.025v7.561l.027.001a3.233 3.233 0 0 0 3.233-3.233v-4.329h-3.26Z"
        />
        <path
          fill="#FFCDBE"
          d="M12.491 15.87v4.168A7.508 7.508 0 0 0 20 27.546l.095-.001.78-6.235-.78-5.44H12.49Z"
        />
        <path
          fill="#FFBEAA"
          d="M19.972 15.87v11.676h.027a7.508 7.508 0 0 0 7.508-7.508V15.87h-7.535Z"
        />
        <path
          fill="#636978"
          d="M15.116 38.574a4.334 4.334 0 0 1-4.334-4.334V14.544h4.334v24.03Z"
        />
      </svg>
      :
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        fill="none"
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill="#989898"
            d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20Z"
          />
          <path
            fill="#fff"
            d="M24.547 19.37a7.568 7.568 0 0 0 3.027-6.054c0-4.176-3.398-7.574-7.574-7.574-4.177 0-7.574 3.398-7.574 7.574 0 2.472 1.19 4.67 3.027 6.054-4.92 1.846-8.43 6.597-8.43 12.153h2.344c0-5.863 4.77-10.632 10.633-10.632 5.863 0 10.633 4.77 10.633 10.632h2.344c0-5.556-3.51-10.307-8.43-12.153Zm-9.778-6.054A5.236 5.236 0 0 1 20 8.086a5.236 5.236 0 0 1 5.231 5.23A5.236 5.236 0 0 1 20 18.547a5.236 5.236 0 0 1-5.23-5.23Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h40v40H0z" />
          </clipPath>
        </defs>
      </svg>
)
export default Defaultprof
