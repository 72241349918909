/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { setCredentials } from '../store/auth/authSlice';

let store;

export const injectStore = (_store) => {
  store = _store;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    const { accessToken } = store.getState().auth;

    if (accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: accessToken,
        Accept: 'application/json',
      };
    }

    return config;
  },
  (err) => Promise.reject(err)
);

let isRefreshing = false;
let failedRequests = [];

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // if (error.response !== null && error.response.status === 403 && !originalRequest._retry) {
    //   if (!isRefreshing) {
    //     isRefreshing = true;
    //     try {
    //       const refreshData = await instance.get('/verify/user');
    //       if (refreshData) {
    //         const { user } = store.getState().auth;
    //         axios.defaults.headers.common.Authorization = refreshData.data.accessToken;

    //         store.dispatch(setCredentials({
    //           user,
    //           accessToken: refreshData.data.accessToken,
    //         }));

    //         originalRequest.headers.Authorization = refreshData.data.accessToken;
    //         return axios(originalRequest);
    //       }
    //     } catch (error) {
    //       if (error.response || error.response.data) {
    //         return Promise.reject(error.response.data);
    //       }

    //       return Promise.reject(error);
    //     } finally {
    //       isRefreshing = false;
    //       failedRequests.forEach((request) => request());
    //       failedRequests = [];
    //     }
    //   }

    //   return new Promise((resolve, reject) => {
    //     failedRequests.push(() => {
    //       originalRequest._retry = true;
    //       resolve(axios(originalRequest));
    //     });
    //   });
    // }
    return Promise.reject(error);
  }
);

export default instance;
