import { CustomInput, DataTableComponent, DateControl, Dropdown, PrimaryButton } from '../../components'

import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EditIcon } from "../../assets/images/icons";
import Button from '../../components/controls/Button';
import { saveModifyRespInfo } from '../../store/emp/empAction';
import { promiseToast } from '../../utils/Toast';


const options = [
    { value: 'INDIAN', label: 'INDIAN' },
    { value: 'NON-INDIAN', label: 'NON-INDIAN' },
]



const GovtDoc = ({ data, setData, mastermodule }) => {
    let initOpt = []
    if (mastermodule.tagged_initiative) {
        initOpt = mastermodule.tagged_initiative.map((data) => ({ value: data.id, label: data.title }))
    }
    let employees = [];
    const dispatch = useDispatch();
    let { id } = useParams();
    const ref = useRef(null)

    const [userData, setUserData] = useState({
        id: '',
        initiative: '',
        status: "",
        start_date: '',
        end_date: ''
    });

    const columns = [
        {
            key: "name",
            name: 'Initiative Name',
            cell: row => row.name,
            width: "30%",
        },
        {
            key: "start_date",
            name: 'Start Date',
            cell: row => row?.start_date,
            width: "20%"
        },
        {
            key: "end_date",
            name: 'End Date',
            cell: row => row?.end_date,
            width: "20%"
        },
        {
            key: "duration",
            name: 'Duration',
            cell: row => row?.duration,
            width: "20%"
        },

        {
            cell: row => <Button aria-label="edit" className='action-icon' onClick={() => setUserData({ id: row.id, initiative: row.name, status: row.status, start_date: row.start_date, end_date: row.end_date })} data-bs-toggle="modal" data-bs-target="#initiativeResp"><EditIcon height={15} width={15} /></Button>,
            width: "10%",
            style: { paddingRight: 0, justifyContent: 'flex-end' },
        },
    ];

    if (data.length > 0) {
        data.forEach((item) => {
            employees.push({
                id: item.res_id,
                status: item.status,
                name: item.initiative_name,
                start_date: item.start_date,
                end_date: item.end_date,
                duration: '10/02/2023',
            });
        })
    }

    const handleSubmit = async () => {
        if (userData.status === 'current') {
            setUserData({ ...userData, end_date: '' });
        }

        let user_id = id;
        let row_id = userData.id;
        let initiative_name = userData.initiative;
        let status = userData.status;
        let start_date = userData.start_date;
        let end_date = userData.end_date;
        const res = await promiseToast(dispatch(saveModifyRespInfo({ row_id, user_id, initiative_name, status, start_date, end_date })));
        if (!res.error) {
            employees = [];
            employees = res?.data.map((item) => {
                return {
                    res_id: item.res_id,
                    status: item.status,
                    initiative_name: item.initiative_name,
                    start_date: item.start_date,
                    end_date: item.end_date,
                }
            });
            setData(employees)
            ref.current.click();
        }
    }
    const handleChange = e => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value })

    }
    return (<>
        {/* <div className="modal fade section-modal" id="govt" tabIndex="-1" aria-labelledby="govtLabel" data-bs-keyboard="false" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content" style={{ position: 'relative' }}>
                    <div className="modal-header" style={{ position: 'absolute', top: '0', visibility: 'hidden' }}>
                        <button type="button" ref={ref} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                           
                            <div className="col-md-6"><CustomInput label={'Passport Name'} name='passport_name' value={data.passport_name} onChange={handleChange} placeholder='Enter Passport Name' /></div>



                            <div className="col-md-6"><CustomInput type={'number'} label={'Passport Number'} name='passport_number' value={data.passport_number} onChange={handleChange} placeholder='Enter Passport Number' /></div>

                            <div className="col-md-6">
                                <DateControl placeholder='Issue Date' id='issue_date' label={'Issue Date'} format="YYYY-MM-DD" value={data.issue_date} maxDate={new Date()} onChange={(e) => setData({ ...data, issue_date: e?.format() })} />
                            </div>
                            <div className="col-md-6">
                                <DateControl placeholder='Expiry Date' id='expiry_date' label={'Expiry Date'} format="YYYY-MM-DD" value={data.expiry_date} minDate={data.issue_date} onChange={(e) => setData({ ...data, expiry_date: e?.format() })} />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="nationalityDropdown" style={{ margin: '0 0 5px 12px' }}>Initiative</label>
                                <div className="dropdown">
                                    <button className="dropdown-toggle modal-dropdown" type="button" aria-label="initiative" id="nationalityDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                        {data.nationality ? data.nationality : "Select Initiative"}
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="13" viewBox="0 0 320 512">
                                                <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" />
                                            </svg>
                                        </span>
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="nationalityDropdown">
                                        {options.map(option => (
                                            <Button aria-label="label" className={`dropdown-item ${options.value === option.label ? 'selected-item' : ''}`} key={option.value} onClick={() => setData({ ...data, nationality: option.value })}>
                                                {option.label}
                                            </Button>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6"><CustomInput type={'number'} label={'Aadhar No'} name='aadhar_no' value={data.aadhar_no} onChange={handleChange} placeholder='Enter Aadhar No' /></div>
                            <div className="col-md-6"><CustomInput label={'PAN No'} name='pan_no' value={data.pan_no} onChange={handleChange} placeholder='Enter PAN No' /></div>

                        </div>
                        <div className="row">
                        <div className="col-md-4 mt-4 offset-md-4">
                                <PrimaryButton type={'button'} onClick={handleSubmit}>Submit Data</PrimaryButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div > */}
        {/* <div className="tab-section-container" id={'home856'} >
            <div className="action-button d-flex align-items-center justify-content-between horizontal-p-20">
                <h2 className='section-name'>government documents</h2>
                <PrimaryButton textTransform={'capitalize'} aria-label="add" data-bs-toggle="modal" data-bs-target="#govt" onClick={() => setUserData({ id: '', initiative: '', status: '', start_date: '', end_date: '' })}>Add +</PrimaryButton>
            </div>
            <DataTableComponent
                columns={columns}
                style={{ overflowX: 'auto' }}
                data={employees}
                progressPending={false}

            />
        </div> */}
        <div className="personal-sec">
            <h2 className='section-name'>government documents</h2>
            <div className="row mt-2">

                <div className="col-md-4 mt-3"><CustomInput label={'Passport Name'} name='passport_name' value={data.passport_name} onChange={handleChange} placeholder='Enter Passport Name' /></div>
                <div className="col-md-4 mt-3"><CustomInput label={'Passport Number'} name='passport_number' value={data.passport_number} onChange={handleChange} placeholder='Enter Passport Number' /></div>

                <div className="col-md-4 mt-3">
                    <DateControl placeholder='Issue Date' id='issue_date' label={'Issue Date'} format="YYYY-MM-DD" value={data.issue_date} maxDate={new Date()} onChange={(e) => setData({ ...data, issue_date: e?.format() })} />
                </div>
                <div className="col-md-4 mt-3">
                    <DateControl placeholder='Expiry Date' id='expiry_date' label={'Expiry Date'} format="YYYY-MM-DD" value={data.expiry_date} minDate={data.issue_date} onChange={(e) => setData({ ...data, expiry_date: e?.format() })} />
                </div>
                <div className="col-md-4 mt-3"><label htmlFor="Nationality" style={{ margin: '0 0 5px 12px' }}>Nationality</label><Dropdown options={options} onChange={(e) => setData({ ...data, nationality: (e.map(e => e.value).toString()) })} set_value={data.nationality} placeholder="Nationality" isMulti gap={'0px'} padding={'0 10px'} width={'100%'} /></div>


                <div className="col-md-4 mt-3"><CustomInput type={'number'} label={'Aadhar No'} name='aadhar_no' value={data.aadhar_no} onChange={handleChange} placeholder='Enter Aadhar No' /></div>
                <div className="col-md-4 mt-3"><CustomInput label={'PAN No'} name='pan_no' value={data.pan_no} onChange={handleChange} placeholder='Enter PAN No' /></div>

            </div>
        </div>
    </>)
}

export default GovtDoc