
import './pdfpreview.css'
import { FamilyCard} from '../../components'
import PdfHeader from './PdfHeader'


const SinglePdfView = ({data,masterData}) => {

  const { user_info } = data || {};
  const { whats_app, phone, email, address,age,blood_group,dob,gender,misaq,marital_status,date_of_nikah,date_of_nikah_hijri,vatan,nationality,jamaat,jamiaat,idara,category,organisation } = user_info || {};
  const { education } = data || {};
  const { qualification, occupation, jamea_standard, jamea_degree,tr_no,hifz_degree_year,hifz_degree } = education || {};
  const { bank } = data || {};
  const { account_name, account_number, bank_name,branch_name,swift,ifsc } = bank || {};
  const { govt_docs } = data || {};
  const { passport_name, passport_number, issue_date, expiry_date, aadhar_no, pan_no} = govt_docs || {};
  const { mawze } = data || {};
  const { mawze_name, nature_of_visit, tagged_initiative, other_participants} = mawze || {};
  const { skill } = data || {};
  const { level_of_competence,industry_type,skill_set_type} = skill || {};
  const mawze_tag_init= masterData?.tagged_initiative?.filter(res=>mawze?.tagged_initiative==res.id)

  const mawzeName = masterData?.mawze_name?.filter(res => {
    const parsedResId = parseInt(res.id);
    return mawze?.mawze_name?.includes(parsedResId);
});
  const ind_type = masterData?.industry_type?.filter(res => {
    const parsedResId = parseInt(res.id);
    return skill?.industry_type?.includes(parsedResId);
});
  const nature_visit = masterData?.nature_of_visit?.filter(res => {
    const parsedResId = parseInt(res.id);
    return mawze?.nature_of_visit?.includes(parsedResId);
});
  const tag_ini = masterData?.tagged_initiative?.filter(res => {
    const parsedResId = parseInt(res.id);
    return mawze?.tagged_initiative?.includes(parsedResId);
});
  const other_parti = masterData?.other_participants?.filter(res => {
    const parsedResId = parseInt(res.id);
    return mawze?.other_participants?.includes(parsedResId);
});

  const skill_set= masterData?.skill_set_type?.filter(res=>skill?.skill_set_type==res.id)
  
  return (
    <div className="container-rounded">
      {
        data ?
          <>
            <div id='singlePdf' className="pdf-wrapper">
            <PdfHeader user={data} masterData={masterData} />
              <div className="pdf-body-two-col">
                <div className="pdf-sidebar-container">
                  <div className="contact-info-box common-bg-box">
                    <center><h2 className="main-heading pdf-heading heading-both"><span>Contact Info</span></h2></center>
                    <div className="content-pair-group">
                      <p className="content-pair"><span className="info-label">WhatsApp: </span><span>{whats_app ? whats_app:"-"}</span></p>
                      <p className="content-pair"><span className="info-label">Phone: </span><span>{phone ? phone : "-"}</span></p>
                      <p className="content-pair"><span className="info-label">Email: </span><span>{email ? email : "-"}</span></p>
                      <p className="content-pair"><span className="info-label">Address:</span><span>{address ? address : "-"}</span></p>
                    </div>
                  </div>
                  <div className="contact-info-box common-bg-box">
                    <center><h2 className="main-heading pdf-heading heading-both"><span>Education Details</span></h2></center>
                    <div className="content-pair-group">
                      <p className="content-pair"><span className="info-label">Qualification:</span><span>{qualification ? qualification : "-"}</span></p>
                      <p className="content-pair"><span className="info-label">Occupation:</span><span>{occupation ? occupation : "-"}</span></p>
                      <p className="content-pair"><span className="info-label">Jamea Standard:</span><span>{jamea_standard ? jamea_standard : "-"}</span></p>
                      <p className="content-pair"><span className="info-label">Jamea Degree:</span><span>{jamea_degree ? jamea_degree : "-"}</span></p>
                      <p className="content-pair"><span className="info-label">Farig Year:</span><span>2021 | ١٤٤٢</span></p>
                      <p className="content-pair"><span className="info-label">TR No.:</span><span>{tr_no ? tr_no : "-"}</span></p>
                    </div>
                    <div className="deggree-box">
                        <table>
                          <thead>
                            <tr  className='degree-header'>
                              <td>Hifz Degree:</td>
                              <td>Year</td>
                            </tr>
                            <tr>
                              <td><p className="content-pair"><span>{hifz_degree || '-'}</span></p></td>
                              <td><p className="content-pair"><span>{hifz_degree_year || '-'}</span></p></td>
                            </tr>
                          </thead>
                        </table>
                    </div>
                  </div>
                </div>
                <div className="body-right-info">
                  <center><h2 className="main-heading pdf-heading heading-both"><span>Personal Information</span></h2></center>
                  <table className='table-info'>
                  <thead>
                    <tr>
                      <td className='pt-15'><p className="content-pair"><span className="info-label">Age:</span><span>{age ? age : "-"}</span></p></td>
                      <td className='pt-15'><p className="content-pair"><span className="info-label">Blood Group:</span><span>{blood_group ? blood_group : "-"}</span></p></td>
                    </tr>
                    <tr>
                      <td><p className="content-pair"><span className="info-label">D.O.B:</span><span>{dob ? dob : "-"}</span></p></td>
                      <td><p className="content-pair"><span className="info-label">Gender:</span><span>{gender ? gender=="M"?"Male":gender=="F"? "Female" :"Other" : "-"}</span></p></td>
                    </tr>
                    <tr>
                      <td><p className="content-pair"><span className="info-label">Misaq:</span><span>{misaq ? misaq : "-"}</span></p></td>
                      <td><p className="content-pair"><span className="info-label">Marital Status:</span><span>{marital_status ? marital_status : "-"}</span></p></td>
                    </tr>
                    <tr>
                      <td><p className="content-pair"><span className="info-label">Date_Of_Nikah:</span><span>{date_of_nikah ? date_of_nikah : "-"}</span></p></td>
                      {/* <td><p className="content-pair"><span className="info-label">Date_Of_Nikah_Hijri:</span><span>{date_of_nikah_hijri ? date_of_nikah_hijri : "-"}</span></p></td> */}
                      <td><p className="content-pair"><span className="info-label"></span><span></span></p></td>
                    </tr>
                    <tr>
                      <td><p className="content-pair"><span className="info-label">Vatan:</span><span>{vatan ? vatan : "-"}</span></p></td>
                      <td><p className="content-pair"><span className="info-label">Nationality:</span><span>{nationality ? nationality : "-"}</span></p></td>
                    </tr>
                    <tr>
                      <td><p className="content-pair"><span className="info-label">Jamaat:</span><span>{jamaat ? jamaat : "-"}</span></p></td>
                      <td><p className="content-pair"><span className="info-label">Jamiaat:</span><span>{jamiaat ? jamiaat : "-"}</span></p></td>
                    </tr>
                    <tr>
                      <td><p className="content-pair"><span className="info-label">Idara:</span><span>{idara ? idara : "-"}</span></p></td>
                      <td><p className="content-pair"><span className="info-label">Category:</span><span>{category ? category : "-"}</span></p></td>
                    </tr>
                    <tr>
                      <td className='pt-15 pb-15' colSpan={2}><p className="content-pair"><span className="info-label">Organisation:</span><span>{organisation ? organisation : "-"}</span></p></td>
                    </tr>
                    </thead>
                  </table>
                  <div className="family-info-sec">
                    {data.user_info?.familly_tree?.map((member) => (
                        <FamilyCard
                          key={member.its_no}
                          member={member}
                          name={member.fullname}
                          image={member.profile_pic}
                          title={member.title}
                          idara={member.idara}
                          its_no={member.its_no}
                          age={member.age}
                        />
                    ))}
                  </div>
                </div>
              </div>
              <div className="pdf-body-full">
                  <center className='section-top-space'><h2 className="main-heading pdf-heading heading-both"><span>Banking Details</span></h2></center>
                  <table className='table-info table-bank'>
                    <thead>
                    <tr>
                      <td className='pt-15'><p className="content-pair"><span className="info-label">Bank Name:</span><span>{bank_name ? bank_name:"-"}</span></p></td>
                      <td className='pt-15'><p className="content-pair"><span className="info-label">Branch Name:</span><span>{branch_name ? branch_name:"-"}</span></p></td>
                    </tr>
                 
                    <tr>
                      <td><p className="content-pair"><span className="info-label">Account Name:</span><span>{account_name ? account_name:"-"}</span></p></td>
                      <td><p className="content-pair"><span className="info-label">Account No:</span><span>{account_number ? account_number:"-"}</span></p></td>
                    </tr>
                    <tr>
                      <td className="pb-15"><p className="content-pair"><span className="info-label">IFSC:</span><span>{ifsc ? ifsc:"-"}</span></p></td>
                      <td className="pb-15"><p className="content-pair"><span className="info-label">SWIFT:</span><span>{swift ? swift:"-"}</span></p></td>
                    </tr>
                    </thead>
                  </table>
                  <center className='section-top-space'><h2 className="main-heading pdf-heading heading-both"><span>Government Documents</span></h2></center>
                  <table className='table-info table-govt'>
                    <thead>
                    <tr>
                      <td className='pt-15'><p className="content-pair"><span className="info-label">Passport Name:</span><span>{passport_name ? passport_name:"-"}</span></p></td>
                      <td className='pt-15'><p className="content-pair"><span className="info-label">Passport No.:</span><span>{passport_number ? passport_number:"-"}</span></p></td>
                    </tr>
                    <tr>
                      <td><p className="content-pair"><span className="info-label">Issue Date:</span><span>{issue_date ? issue_date:"-"}</span></p></td>
                      <td><p className="content-pair"><span className="info-label">Expiry Date:</span><span>{expiry_date ? expiry_date:"-"}</span></p></td>
                    </tr>
                    <tr>
                      <td className="pb-15"><p className="content-pair"><span className="info-label">Aadhar No.:</span><span>{aadhar_no ? aadhar_no:"-"}</span></p></td>
                      <td className="pb-15"><p className="content-pair"><span className="info-label">PAN No.:</span><span>{pan_no ? pan_no:"-"}</span></p></td>
                    </tr>
                    </thead>
                  </table>
                <center className='section-top-space'><h2 className="main-heading pdf-heading heading-both"><span>Skill Set (evaluated)</span></h2></center>
                <table className='table-skillset'>
                <thead>
                  <tr className='table-head-highlight'>
                    <td><p className="content-pair"><span className="info-label">Skill Set</span></p></td>
                    <td><p className="content-pair"><span className="info-label">Level of Competence</span></p></td>
                    <td><p className="content-pair"><span className="info-label">Industry Type</span></p></td>
                  </tr>
                  <tr>
                    <td><p className="content-pair"><span>{skill_set?.[0]?.title || '-'}</span></p></td>
                    <td><p className="content-pair"><span>{level_of_competence?.toUpperCase() || '-'}</span></p></td>
                    <td><p className="content-pair"><span>{ind_type?.map(ind => ind.title).join(', ') || '-'}</span></p></td>
                  </tr>
                  </thead>
                </table>
                {/* mawze */}
                <center className='section-top-space'><h2 className="main-heading pdf-heading heading-both"><span>Mawze Visit List</span></h2></center>
                <table className='table-info table-mawze'>
                <thead>
                  <tr className='table-head-highlight'> 
                    <td><p className="content-pair"><span className="info-label">Mawze Name</span></p></td>
                    <td><p className="content-pair"><span className="info-label">Nature of Visit</span></p></td>
                    <td><p className="content-pair"><span className="info-label">Tagged Initiative</span></p></td>
                    <td><p className="content-pair"><span className="info-label">Other Participants</span></p></td>
                  </tr>
                  <tr>
                    <td><p className="content-pair"><span>{ mawzeName?.map(ind => ind.title) || "-"}</span></p></td>
                    <td><p className="content-pair"><span>{nature_visit?.map(ind => ind.title).join(', ') || '-'}</span></p></td>
                    {/* <td><p className="content-pair"><span>{tagged_initiative ? mawze_tag_init[0]?.title :"-"}</span></p></td> */}
                    <td><p className="content-pair"><span>{tag_ini?.map(ind => ind.title).join(', ') || '-'}</span></p></td>
                    <td><p className="content-pair"><span>{other_parti?.map(ind => ind.title).join(', ') || '-'}</span></p></td>
                  </tr>
                  </thead>
                  </table>
              </div>
            </div>
            <div className="page-number"></div>
          </> : 
        <span>Loading</span>
      }
    </div>
  )
}

export default SinglePdfView