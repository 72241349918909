import React from 'react';
import './icons.css'
const NotificationIcon = ({ color,isActive }) => {

    return (
        <span className="notifi-icon border-hover">
            <svg viewBox="0 0 32 32" width="20.631" height="25.144" xmlns="http://www.w3.org/2000/svg"><g id="_28" data-name="28"><path fill={color || '#000'} d="m28.07 22.66-1.71-2.46a2 2 0 0 1 -.36-1.14v-7.06a10 10 0 0 0 -20 0v7.06a2 2 0 0 1 -.36 1.14l-1.71 2.46a2 2 0 0 0 1.48 3.34h5.69a5 5 0 0 0 9.8 0h5.69a2 2 0 0 0 1.48-3.34zm-12.07 5.34a3 3 0 0 1 -2.82-2h5.64a3 3 0 0 1 -2.82 2zm-10.59-4a.81.81 0 0 0 .12-.14l1.75-2.52a4 4 0 0 0 .72-2.28v-7.06a8 8 0 0 1 16 0v7.06a4 4 0 0 0 .72 2.28l1.75 2.52a.81.81 0 0 0 .12.14z" /></g></svg>
            <span className={`notification-indicator ${isActive && 'note-active'}`}></span>
        </span>
    );
};
export default NotificationIcon;
