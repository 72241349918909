import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import './humanresource.css'
import { Calendar, DataTableComponent, Filter, PrimaryButton, Reset, SearchField, Status } from '../../components';
import { CsvIcon, EditIcon, Pdf, PdfIcon, View } from '../../assets/images/icons';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from "react-csv";
import { exportCols } from './ExportCols';
import TableSkeleton from '../../components/loader/tableSkeleton';
import { useAccessControl } from '../../hooks/useAccessControl';
import Button from '../../components/controls/Button';
import { getEMP } from '../../store/emp/empAction';
import { Roles, perm } from '../../assets/constant';
import CustomSelect from '../../components/controls/CustomSelect';
import { updatePagination } from '../../store/emp/empSlice';
import moment from 'moment';
import { customFilter } from '../../utils/commonHelpers';

const HRListing = () => {
  const { checkPermission, checkRoleAccess } = useAccessControl()
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const { filterText } = useSelector(state => state.auth);

  const [modalType, setModalType] = useState('')
  const { loading, pagination } = useSelector(state => state.employee)
  const { perPage, totalRows, currentPage } = pagination
  const dispatch = useDispatch();


  const selectRef = useRef(null);
  const selectRef1 = useRef(null);
  const selectRef2 = useRef(null);
  const selectRef3 = useRef(null);
  const selectRef4 = useRef(null);
  const selectRef5 = useRef(null);

  const [role_option, setRole_option] = useState([]);
  const [nameOrder_option] = useState([
    { value: "ASC", label: "A to Z" },
    { value: "DESC", label: "Z to A" },
  ]);
  const [idara_option, setIdara_option] = useState([]);
  const [user_type_option, setUser_type_option] = useState([]);
  const [category_option, setCategory_option] = useState([]);
  const [filterData, setFilterData] = useState({
    role_val: null,
    nameOrder_val: null,
    idara_val: null,
    user_type_val: null,
    category_val: null,
  });

  // Run at first Load only 
  const fetchEmpData = useCallback(async (info = filterData, page = currentPage, size = perPage) => {
    try {
      if (checkRoleAccess([], ["human-resources"])) {
        let return_data = await dispatch(getEMP({ page, size, filterData: info }));
        const { emp_data, count, role, idara, user_type, category } = return_data.payload.data
        setEmployeeData(emp_data);
        dispatch(updatePagination({ totalRows: count || emp_data.length }))
        setRole_option(role?.map((data) => ({ value: data.role, label: data.title })));
        setIdara_option(idara?.map((data) => ({ value: data.idara, label: data.idara })));
        setUser_type_option(user_type?.map((data) => ({ value: data.user_type, label: data.user_type })));
        setCategory_option(category?.map((data) => ({ value: data.category, label: data.category })));
      }
    } catch (error) { console.log(error, 'error') }
  }, [dispatch, perPage, checkRoleAccess, currentPage, filterData])

  // // Run On Every Filter or pagination Change 
  // const handleInputChange = useCallback(async (info = filterData, page = currentPage, size = perPage) => {
  //   let return_data = await dispatch(getEMP({ page, size, filterData: info }));
  //   let { emp_data, count } = return_data.payload.data
  //   setEmployeeData(emp_data);
  //   dispatch(updatePagination({ totalRows: count || emp_data.length }))

  // }, [dispatch, setEmployeeData, perPage, currentPage, filterData])

  const handlePageChange = useCallback(async (page) => {
    await dispatch(updatePagination({ currentPage: page }))
    await fetchEmpData(filterData, page)
  }, [dispatch, fetchEmpData, filterData])

  const handlePerRowsChange = useCallback(async (newPerPage, page) => {
    await dispatch(updatePagination({ perPage: newPerPage, currentPage: page }))
    await fetchEmpData(filterData, page, newPerPage)
  }, [dispatch, fetchEmpData, filterData])

  useEffect(() => {
    fetchEmpData();
  }, [])

  const handleCheckboxChange = useCallback((value, name, key, expoCols) => {
    const { section } = expoCols
    value = section ? `${section}.${value}` : value
    if (key === "all_members") {
      return setSelectedColumns((prevCols) => {
        let updatedColumns = [...prevCols];
        let additionalFields = expoCols?.hiddenCols || []
        if (!prevCols.some(e => e.id === key)) {
          additionalFields?.forEach(field => {
            if (!prevCols.some(col => col.id === field.key)) {
              updatedColumns.push({
                label: field.name, key: value, id: field.key
              });
            }
          });
        } else {
          additionalFields?.forEach(field => {
            updatedColumns = updatedColumns.filter(col => col.id !== field.key);
          });
        }
        return updatedColumns;
      });
    }
    setSelectedColumns((prevCols) => {
      const updatedColumns = prevCols.some(e => e.id === key) ? prevCols.filter((col) => col.id !== key) : [...prevCols, { label: name, key: value, id: key }];
      return updatedColumns;
    });
  }, [setSelectedColumns])

  const handleSelectAllCheckbox = useCallback((e, expoCols) => {
    const { section, cols } = expoCols

    try {
      let additionalFields = expoCols?.hiddenCols || []
      let newColumns = [...selectedColumns]

      if (section === 'family') {
        if (e.target.checked) {
          additionalFields?.forEach(elm => {
            let key = section ? `${section}.${elm?.value}` : elm?.value
            if (newColumns?.some(c => c.id === elm.key)) return console.log('already include')
            newColumns.push({ label: elm.name, key, id: elm.key })
          })
        } else {
          additionalFields?.forEach(elm => {
            newColumns.filter(col => col.id !== elm.key)
          })
        }
        setSelectedColumns(newColumns)
        return;
      }

      if (e.target.checked) {
        cols?.forEach(elm => {
          if (newColumns?.some(c => c.id === elm.key)) return console.log('already include')
          let key = section ? `${section}.${elm?.value}` : elm?.value
          newColumns.push({ label: elm.name, key, id: elm.key })
        })
      } else {
        cols?.forEach(elm => {
          newColumns = newColumns.filter(col => col.id !== elm.key)
        })
      }
      setSelectedColumns(newColumns)
    } catch (error) { console.log(error); }
  }, [setSelectedColumns, selectedColumns])

  const handleReset = useCallback((e) => {
    let initialValues = {
      nameOrder_val: null,
      role_val: null,
      idara_val: null,
      user_type_val: null,
      category_val: null,
    }
    setFilterData(initialValues);

    selectRef?.current?.clearValue();
    selectRef1?.current?.clearValue();
    selectRef2?.current?.clearValue();
    selectRef3?.current?.clearValue();
    selectRef4?.current?.clearValue();
    selectRef5?.current?.clearValue();
    fetchEmpData(initialValues)
  }, [fetchEmpData, setFilterData])

  const columns = [
    {
      key: "id",
      name: 'ID',
      cell: row => `HR${String(row.id).padStart(3, '0')}`,
      width: "80px",
    },
    {
      key: "its_no",
      name: 'ITS No.',
      cell: row => row.its_no ? <span className='text-nowrap'>{row.its_no}</span> : '-',
      width: "90px",
    },
    {
      key: "name",
      name: 'Full Name',
      cell: row => <span className='ellipsis' title={row.name}>{row.name ? row.name : '-'}</span>,
      width: "auto"
    },
    {
      key: "role",
      name: 'Role',
      cell: row => <span className='ellipsis' title={row?.office?.role}>{row?.office?.role ? row?.office?.role : '-'}</span>,
      width: "auto"
    },
    {
      key: "category",
      name: 'Category',
      cell: row => <span className='ellipsis' title={row.category}>{row.category ? row.category : '-'}</span>,
      width: "120px"
    },
    {
      key: "idara",
      name: 'Idara',
      cell: row => <span className='ellipsis' title={row.idara}>{row.idara ? row.idara : '-'}</span>,
      width: "120px",
    },

    {
      key: "jamaat",
      name: 'Jamaat & Jamiat',
      cell: row => <span className='ellipsis' title={row.jamaat}>{row.jamaat ? row.jamaat : '-'}</span>,
      width: "150px"
    },
    {
      key: "status",
      name: 'Status',
      cell: row => <Status status={row.status} />,
      width: "80px",
      fixed: "left",
    },
    {

      key: "action",
      fixed: "left",
      name: 'Action',
      cell: row => {
        return (
          <>
            {checkPermission("human-resources", perm.pdf, <NavLink to={`/human-resources/single-pdf-preview/${row.id}`} className='action-icon' aria-label="pdf" ><Pdf height={15} width={15} /></NavLink>)}
            {checkPermission("human-resources", perm.view, <NavLink to={`/human-resources/view-employee/${row.id}`} className='action-icon' aria-label="view" > <View height={15} width={15} /></NavLink>)}
            {checkPermission("human-resources", perm.edit, <NavLink to={`/human-resources/edit-employee/${row.id}`} className='action-icon' aria-label="edit" > <EditIcon height={15} width={15} /></NavLink>)
            }
          </>
        )
      },
      width: "130px",
      style: { paddingRight: 0, justifyContent: 'flex-end' },
    }
  ];


  const handleFilterChange = useCallback(async (e, section) => {
    if (!e?.value) return;
    let info = { ...filterData, [section]: e?.value };
    setFilterData(info);
    fetchEmpData(info);
  }, [fetchEmpData, setFilterData, filterData])

  const handleCSV = useCallback((_, done) => {
    try {
      const formattedData = [];
      employeeData.forEach(item => {
        const { initiave, jamiat, family, ...otherData } = item;
        const maxCount = Math.max(initiave?.length, jamiat?.length, family?.length);
        if (maxCount === 0) return formattedData.push(otherData);
        for (let i = 0; i < maxCount; i++) {
          const newItem = {
            initiave: initiave[i] || {},
            jamiat: jamiat[i] || {},
            family: family[i] || {}
          };
          if (i === 0) Object.assign(newItem, otherData);
          formattedData.push(newItem);
        }
      });
      setCsvData(formattedData)
      done();
    } catch (error) {
      console.log(error, 'error');
      done(false);
    }
  }, [employeeData, setCsvData])


  const searchResult = useMemo(() => {
    return customFilter(employeeData, [{ id: 'global-filter', value: filterText?.["human-resources"] || "" }], ['id', 'its_no', 'name', 'office.role', 'category', 'idara', 'jamaat']);
  }, [filterText, employeeData]);

  return (<>
    <div className="modal fade human-resources-modal" id="csv" tabIndex="-1" aria-labelledby="csvLabel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Select Columns</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setSelectedColumns([])}></button>
          </div>
          <div className="modal-body">
            <input
              type="text"
              hidden
              className="form-control"
              defaultValue={modalType}
            />
            <div className="row">
              {exportCols.map((expoCols) => {
                const { title, cols } = expoCols
                return (
                  <div key={title} className='row mt-3'>
                    <div className="d-flex  align-items-center ">
                      <h6 className=''>{title}</h6>
                      <div className="col-md-4">
                        <div className="form-check chekcbox">
                          <input
                            type="checkbox"
                            className="form-check-input mb-1 "
                            id={title}
                            checked={cols.every(a => selectedColumns.some(b => b.id === a.key))}
                            onChange={(e) => handleSelectAllCheckbox(e, expoCols)}
                          />
                          <label htmlFor={title} className="form-check-label ml-2">
                            Select All
                          </label>
                        </div>
                      </div>
                    </div>
                    {!!cols?.length && cols?.map(({ key, name, value }) => (
                      <div key={key} className="col-md-4">
                        <div className="form-check chekcbox">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={key}
                            checked={selectedColumns.some(e => e.id === key)}
                            onChange={() => handleCheckboxChange(value, name, key, expoCols)}
                          />
                          <label htmlFor={key} className="form-check-label ml-2">
                            {name}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="modal-footer">
            <CSVLink
              data={csvData || []}
              headers={selectedColumns}
              filename={`${moment().format('DD-MM-YYYY')}.csv`}
              asyncOnClick={true}
              onClick={handleCSV}
            >
              <PrimaryButton type="button" onClick={() => { }} >
                submit
              </PrimaryButton>
            </CSVLink>
          </div>
        </div>
      </div>
    </div >
    <div className="container-listing hr-listing">
      <div className='resource-header  horizontal-p-20'>
        <div className="modulename">
          <span className='main-heading text-nowrap'>Human Resources</span>
        </div>
        <div className="filter-section">
          <CustomSelect inputRef={selectRef1} placeholder="Order" width={110} option={nameOrder_option} onChange={(e) => handleFilterChange(e, 'nameOrder_val')} />
          <CustomSelect inputRef={selectRef} placeholder="Role" option={role_option} onChange={(e) => handleFilterChange(e, 'role_val')} />
          <CustomSelect inputRef={selectRef3} placeholder="User Type" width={100} option={user_type_option} onChange={(e) => handleFilterChange(e, 'user_type_val')} />
          <CustomSelect inputRef={selectRef2} placeholder="Idara" option={idara_option} onChange={(e) => handleFilterChange(e, 'idara_val')} />
          <CustomSelect inputRef={selectRef4} placeholder="Category" width={90} option={category_option} onChange={(e) => handleFilterChange(e, 'category_val')} />

          <Calendar inputRef={selectRef5} format="YYYY-MM-DD" range rangeHover onChange={(e) => {
            if (e[1]?.format()) {
              let info = { ...filterData, start_date: e[0]?.format(), end_date: e[1]?.format() };
              setFilterData(info);
              fetchEmpData(info);
            }
          }} />

          <Reset onClick={handleReset} />

          <div className="download-sec">
            <Button aria-label="csv" data-bs-toggle="modal" className='icon-wrapper' data-bs-target="#csv" onClick={() => setModalType('csv')}>
              <CsvIcon height={20} width={20} />
            </Button>
            {/* <Button aria-label="pdf" data-bs-toggle="modal" className='icon-wrapper' data-bs-target="#pdf" onClick={() => setModalType('pdf')}>
              <PdfIcon height={20} width={20} />
            </Button> */}
          </div>
          <div className="action-btn">
            {checkPermission("human-resources", perm.add,
              <NavLink to='/human-resources/add-employee'>
                <PrimaryButton textTransform={'capitalize'} background={'#fff'} height={'35px'}>Add +</PrimaryButton>
              </NavLink>)}

            {checkRoleAccess([Roles.superAdmin, Roles.admin], []) && <NavLink to='/human-resources/master-module/achievement-type'>
              <PrimaryButton textTransform={'capitalize'} background={'#fff'} height={'35px'}>Masters</PrimaryButton>
            </NavLink>}
          </div>
        </div>
      </div>

      <div className="resource-body hr-listing-body">
        <DataTableComponent
          columns={columns}
          // data={employeeData}
          data={searchResult}
          progressPending={loading}
          pagination
          paginationServer
          paginationPerPage={perPage}
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressComponent={<TableSkeleton columns={columns} />}
        />

      </div>
    </div >
  </>
  )
}

export default HRListing