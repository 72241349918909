import React from 'react'
import { Dropdown } from '../../components'
const MawzeVisit = ({ data, setData, mastermodule }) => {
    let initOpt = []
    let natureOpt = []
    let initiveOpt = []
    let otherOpt = []


    if (mastermodule.mawze_name) {
        mastermodule.mawze_name.forEach((data) => {
            initOpt.push({ value: data.id, label: data.title });
        })
    }

    if (mastermodule.nature_of_visit) {
        mastermodule.nature_of_visit.forEach((data) => {
            natureOpt.push({ value: data.id, label: data.title });
        })
    }
    if (mastermodule.tagged_initiative) {
        mastermodule.tagged_initiative.forEach((data) => {
            initiveOpt.push({ value: data.id, label: data.title });
        })
    }
    if (mastermodule.other_participants) {
        mastermodule.other_participants.forEach((data) => {
            otherOpt.push({ value: data.id, label: data.title });
        })
    }
    return (
        <div className="personal-sec">
            <h2 className='section-name'>mawze visit list</h2>
            <div className="row mt-2">

                <div className="col-md-4 mt-3 "><label htmlFor="mawze_name" style={{ margin: '0 0 5px 12px' }}>Mawze Name</label><Dropdown options={initOpt} onChange={(e) => setData({ ...data, mawze_name: e.value })} placeholder="Mawze Name" gap={'0px'} padding={'0 10px'} width={'100%'} set_value={data.mawze_name} /></div>
                <div className="col-md-4 mt-3 "><label htmlFor="nature_of_visit" style={{ margin: '0 0 5px 12px' }}>Nature of Visit</label><Dropdown options={natureOpt} onChange={(e) => setData({ ...data, nature_of_visit: (e.map(e => e.value)).toString()  })} placeholder="Nature of Visit" gap={'0px'} isMulti padding={'0 10px'} width={'100%'} set_value={data.nature_of_visit} /></div>
                <div className="col-md-4 mt-3 "><label htmlFor="tagged_initiative" style={{ margin: '0 0 5px 12px' }}>Tagged Initiative</label><Dropdown options={initiveOpt} onChange={(e) => setData({ ...data, tagged_initiative: (e.map(e => e.value)).toString()  })} placeholder="Tagged Initiative" isMulti gap={'0px'} padding={'0 10px'} width={'100%'} set_value={data.tagged_initiative} /></div>
                <div className="col-md-4 mt-3 "><label htmlFor="other_participants" style={{ margin: '0 0 5px 12px' }}>Other Participants</label><Dropdown options={otherOpt} onChange={(e) => setData({ ...data, other_participants: (e.map(e => e.value)).toString()  })} placeholder="Other Participants" isMulti gap={'0px'} padding={'0 10px'} width={'100%'} set_value={data.other_participants} /></div>

            </div>
        </div>)
}

export default MawzeVisit