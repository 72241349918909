import React, { useEffect, useState } from 'react'
import { CustomInput, Date, FamilyCard, FileControl, PrimaryButton, ProfileImg } from '../../components'
import { imageToBase64 } from '../../utils/commonHelpers';

const BankDetails = ({ data, setData }) => {
   
    const handleChange = e => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value })
    }
    const handleChequeChange = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                const base64String = await imageToBase64(file);
                setData({ ...data, new_cancelled_chq: base64String })
            }
        } catch (error) {
            console.error('Error converting image to base64:', error);
        }
    };
    return (
        <div className="personal-sec">
            <h2 className='section-name'>Banking Details</h2>
            <div className="row mt-2">

                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Bank Name'} name='bank_name' value={data.bank_name} onChange={handleChange} placeholder='Enter Bank Name' /></div>
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Branch Name'} name='branch_name' value={data.branch_name} onChange={handleChange} placeholder='Enter Branch Name' /></div>
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Account Name'} name='account_name' value={data.account_name} onChange={handleChange} placeholder='Enter Account Name' /></div>
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Account Number'} type={'number'} name='account_number' value={data.account_number} onChange={handleChange} placeholder='Enter Account Number' /></div>

                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'IFSC'} name='ifsc' value={data.ifsc} onChange={handleChange} placeholder='Enter Address' /></div>

                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'SWIFT'} name='swift' value={data.swift} onChange={handleChange} placeholder='Enter SWIFT' /></div>
                <div className="col-lg-3 col-md-3 mt-3 position-relative d-flex w-auto">
                    <div className="me-2 file-upload-wrapper">
                        <FileControl id={'cancelled_chq'} label={"Cancelled Cheque"} onChange={handleChequeChange} filename={data?.cancelled_chq?.split('/')?.pop()} />
                    </div>
                    <button type="button" title='Cancelled Cheque' className="m-0 p-0 col-lg-2 col-0 col-md-2 mt-1 justify-content-start align-items-center d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        {/* Preview */}
                        {(data?.new_cancelled_chq || data?.cancelled_chq) && <div className=" w-auto h-auto preview ">
                            {
                                data?.new_cancelled_chq ? <ProfileImg image={data?.new_cancelled_chq} local CustomClass={" shadow-sm border-0 rounded-0 "} />
                                    : data?.cancelled_chq && <ProfileImg image={data?.cancelled_chq} CustomClass={" shadow-sm border-0 rounded-0 "} />
                            }
                            <span className='show-preview'>preview</span>
                        </div>}
                    </button>
                </div>
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content preview">
                            <div className=" modal-body col-lg-1 col-0 col-md-1 mt-1 d-flex align-items-center  w-full h-full justify-content-start align-items-center w-auto ">
                                {
                                    data?.new_cancelled_chq ? <ProfileImg image={data?.new_cancelled_chq} local CustomClass={" shadow-sm border-0 rounded-0 full-hw"} />
                                        : data?.cancelled_chq && <ProfileImg image={data?.cancelled_chq} CustomClass={" shadow-sm border-0 rounded-0 full-hw"} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default BankDetails