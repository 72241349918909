export const exportCols =
  [
    {
      title: "Personal Information",
      section: null,
      cols: [
        {
          key: 'its_id',
          name: 'ITS ID',
          value: 'its_no'
        },
        {
          key: 'full_name',
          name: 'Full Name',
          value: 'name'
        },
        {
          key: 'full_name_arabic',
          name: 'Full Name Arabic',
          value: 'arabic_name'
        },
        {
          key: 'age',
          name: 'Age',
          value: 'age'
        },
        {
          key: 'gender',
          name: 'Gender',
          value: 'gender'
        },
        {
          key: 'misaq',
          name: 'Misaq',
          value: 'misaq'
        },
        {
          key: 'marital_status',
          name: 'Marital Status',
          value: 'marital_status'
        },
        {
          key: 'blood_group',
          name: 'Blood Group',
          value: 'blood_group'
        },
        {
          key: 'dob',
          name: 'D.O.B',
          value: 'dob'
        },
        {
          key: 'date_of_nikah',
          name: 'Date Of Nikah',
          value: 'date_of_nikah'
        },
        {
          key: 'idara',
          name: 'Idara',
          value: 'idara'
        },
        {
          key: 'category',
          name: 'Category',
          value: 'category'
        },
        {
          key: 'organisation',
          name: 'Organisation',
          value: 'organisation'
        },
        {
          key: 'vatan',
          name: 'Vatan',
          value: 'vatan'
        },
        {
          key: 'personal_nationality',
          name: 'Nationality',
          value: 'nationality'
        },
        {
          key: 'jamaat',
          name: 'Jamaat',
          value: 'jamaat'
        },
        {
          key: 'jamiaat',
          name: 'Jamiaat',
          value: 'jamiaat'
        },
        {
          key: 'role',
          name: 'Role',
          value: 'role'
        },
      ]
    },
    {
      title: "Family Information",
      section: "family",
      cols: [
        {
          key: 'all_members',
          name: 'All Members',
          value: 'all_members'
        }
      ],
      hiddenCols: [
        {
          key: 'family_name',
          name: 'Member | fullname',
          value: 'fullname'
        },
        {
          key: 'family_idara',
          name: 'Member | Idara',
          value: 'idara'
        },
        {
          key: 'all_members',
          name: 'Member | ITS ID',
          value: 'its_id'
        },
        {
          key: 'family_age',
          name: 'Member | Age',
          value: 'age'
        },
      ]
    },
    {
      title: "Educational Details",
      section: "education",
      cols: [
        {
          key: 'qualification',
          name: 'Qualification',
          value: 'qualification'
        },
        {
          key: 'occupation',
          name: 'Occupation',
          value: 'occupation'
        },
        {
          key: 'hifz_degree',
          name: 'Hifz Degree',
          value: 'hifz_degree'
        },
        {
          key: 'hifz_degree_year',
          name: 'Hifz Degree Year',
          value: 'hifz_degree_year'
        },
        {
          key: 'jamea_standard',
          name: 'Jamea Standard',
          value: 'jamea_standard'
        },
        {
          key: 'jamea_degree',
          name: 'Jamea Degree',
          value: 'jamea_degree'
        },
        {
          key: 'farig_year',
          name: 'Farig Year',
          value: 'farig_year'
        },
        {
          key: 'tr_no',
          name: 'TR No',
          value: 'tr_no'
        },

      ]
    },
    {
      title: "Contact Information",
      section: null,
      cols: [
        {
          key: 'whats_app',
          name: 'WhatsApp',
          value: 'whats_app'
        },
        {
          key: 'email',
          name: 'Email',
          value: 'email'
        },
        {
          key: 'phone',
          name: 'Phone',
          value: 'phone'
        },
        {
          key: 'type_of_house',
          name: 'Type_of_House',
          value: 'type_of_house'
        },
        {
          key: 'address',
          name: 'Address',
          value: 'address'
        },
        {
          key: 'building',
          name: 'Building',
          value: 'building'
        },
        {
          key: 'street',
          name: 'Street',
          value: 'street'
        },
        {
          key: 'area',
          name: 'Area',
          value: 'area'
        },
        {
          key: 'city',
          name: 'City',
          value: 'city'
        },
        {
          key: 'pincode',
          name: 'Pincode',
          value: 'pincode'
        },
      ]
    },
    {
      title: "Banking Details",
      section: "bank",
      cols: [
        {
          key: 'bank_name',
          name: 'Bank Name',
          value: 'bank_name'
        },
        {
          key: 'branch_name',
          name: 'Branch Name',
          value: 'branch_name'
        },
        {
          key: 'account_name',
          name: 'Account Name',
          value: 'account_name'
        },
        {
          key: 'account_number',
          name: 'Account Number',
          value: 'account_number'
        },
        {
          key: 'ifsc',
          name: 'IFSC',
          value: 'ifsc'
        },
        {
          key: 'swift',
          name: 'SWIFT',
          value: 'swift'
        }
      ]
    },
    {
      title: "Government Documents",
      section: "govt",
      cols: [
        {
          key: 'passport_name',
          name: 'Passport Name',
          value: 'passport_name'
        },
        {
          key: 'passport_number',
          name: 'Passport Number',
          value: 'passport_number'
        },
        {
          key: 'issue_date',
          name: 'Issue Date',
          value: 'issue_date'
        },
        {
          key: 'expiry_date',
          name: 'Expiry Date',
          value: 'expiry_date'
        },
        {
          key: 'govt_doc_nationality',
          name: 'Gov | Nationality',
          value: 'nationality'
        },
        {
          key: 'aadhar_no',
          name: 'Aadhar No',
          value: 'aadhar_no'
        },
        {
          key: 'pan_no',
          name: 'PAN No',
          value: 'pan_no'
        },
      ]
    },
    {
      title: "Skill Set (evaluated)",
      section: "skill",
      cols: [
        {
          key: 'skill_set_type',
          name: 'Skill Set Type',
          value: 'skill_set_type'
        },
        {
          key: 'level_of_competence',
          name: 'Level of Competence',
          value: 'level_of_competence'
        },
        {
          key: 'industry_type',
          name: 'Industry Type',
          value: 'industry_type'
        },
      ]
    },
    {
      title: "Mawze Visit List ",
      section: "mawze",
      cols: [
        {
          key: 'mawze_name',
          name: 'Mawze Name',
          value: 'mawze_name'
        },
        {
          key: 'nature_of_visit',
          name: 'Nature of Visit',
          value: 'nature_of_visit'
        },
        {
          key: 'mawze_visit_tagged_initiative',
          name: 'Tagged Initiative',
          value: 'tagged_initiative'
        },
        {
          key: 'other_participants',
          name: 'Other Participants',
          value: 'other_participants'
        },
      ]
    },
    {
      title: "Initiave Responsibility ",
      section: "initiave",
      cols: [
        {
          key: 'initiative_name',
          name: 'Initiative Name',
          value: 'initiative_name'
        },
        {
          key: 'initiaveResp_status',
          name: 'Initiative | Status',
          value: 'status'
        },
        {
          key: 'initiaveResp_start_date',
          name: 'Initiative | Start Date',
          value: 'start_date'
        },
        {
          key: 'initiaveResp_end_date',
          name: 'Initiative | End Date',
          value: 'end_date'
        },
      ]
    },
    {
      title: "Jamiat Responsibility ",
      section: "jamiat",
      cols: [
        {
          key: 'jamiat_name',
          name: 'Jamiat Name',
          value: 'jamiat_name'
        },
        {
          key: 'jamiatResp_status',
          name: 'jamiat | Status',
          value: 'status'
        },
        {
          key: 'jamiatResp_start_date',
          name: 'jamiat | Start Date',
          value: 'start_date'
        },
        {
          key: 'jamiatResp_end_date',
          name: 'jamiat | End Date',
          value: 'end_date'
        },
      ]
    },
    {
      title: "Project Worked On ",
      section: "project",
      cols: [
        {
          key: 'project_name',
          name: 'Project Name',
          value: 'project_name'
        },
        {
          key: 'project_worked_tagged_initiative',
          name: 'Tagged Initiative',
          value: 'tagged_initiative'
        },
        {
          key: 'projectWorked_start_date',
          name: 'Project | Start Date',
          value: 'start_date'
        },
        {
          key: 'projectWorked_end_date',
          name: 'Project | End Date',
          value: 'end_date'
        },
        {
          key: 'no_of_hours',
          name: 'No of Hours Worked?',
          value: 'no_of_hours'
        },
      ]
    },
    {
      title: "Achievements",
      section: "achievement",
      cols: [
        {
          key: 'achievement_type',
          name: 'Achievement Type',
          value: 'achievement_type'
        },
        {
          key: 'achievement_tagged_initiative',
          name: 'Tagged Initiative',
          value: 'tagged_initiative'
        },
        {
          key: 'remarks',
          name: 'Remarks',
          value: 'remarks'
        },
      ]
    },
    {
      title: "Office Info",
      section: "office",
      cols: [
        {
          key: 'office_role',
          name: 'Office | Role ',
          value: 'role'
        },
        {
          key: 'responsibility',
          name: 'Responsibility',
          value: 'responsibility'
        },
        {
          key: 'current_assigned_initiative',
          name: 'Current Assigned Initiative',
          value: 'current_assigned_initiative'
        },
        {
          key: 'current_assigned_jamiat',
          name: 'Current Assigned Jamiat',
          value: 'current_assigned_jamiat'
        },
      ]
    },
  ]