import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CustomInput, DataTableComponent, PrimaryButton } from '../../components';
import { BackIcon, EditIcon } from '../../assets/images/icons';
import { Link, NavLink, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveModifyModule, getModuleInfo, deleteModuleInfo } from '../../store/master_module/master_module_action';
import { promiseToast } from '../../utils/Toast';
import DeleteIcon from '../../assets/images/icons/DeleteIcon';
import GoBack from '../../components/controls/GoBack';

const ModuleListing = () => {
    const dispatch = useDispatch();
    const { type } = useParams();
    let current_slug = window.location.pathname.split('/');
    let module_slug = current_slug[1] ? current_slug[1] : '';

    const ref = useRef(null)
    const [data, setData] = useState([]);
    const [moduleinfo, setModuleInfo] = useState({ id: '', title: '' });
    let page_title = type.replace("-", " ");

    const columns = [
        {
            key: "title",
            name: 'Title',
            cell: row => row.title,
            width: "50%"
        },
        {
            key: "action",
            name: 'Action',
            cell: row => {
                return (
                    <>
                        <Link to="/" className='action-icon' onClick={() => setModuleInfo({ id: row.id, title: row.title })} data-bs-toggle="modal" data-bs-target="#popup" ><EditIcon height={15} width={15} /></Link>
                        <Link to="javascript:void(0)" className='action-icon' onClick={() => { if (window.confirm('Delete the item?')) { deleteData(row.id) } }} ><DeleteIcon height={15} width={15} /></Link>
                    </>
                )
            },
            width: "20%",
            style: { paddingRight: 0, justifyContent: 'flex-end' },
        },
    ];

    const deleteData = async (id) => {
        const res = await promiseToast(dispatch(deleteModuleInfo({ module_type: type, module_slug, id })));
        if (!res.error) fetchData();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let id = moduleinfo.id;
        let title = moduleinfo.title;
        const res = await promiseToast(dispatch(saveModifyModule({ module_type: type, module_slug, title, id })));
        if (!res.error) fetchData();
        ref.current.click();
    };

    const fetchData = useCallback(async () => {
        const retrun_data = await dispatch(getModuleInfo({ module_type: type, module_slug }));
        const active_data = retrun_data?.payload?.data?.moduleInfo;
        setData(active_data);
    }, [dispatch, module_slug, type])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            <div className="container-listing">
                <div className="">
                    <div className='resource-header'>
                        <div className="modulename d-flex gap-3">
                            <GoBack to={'/human-resources'} />
                            <span className='main-heading text-capitalize'>{page_title}</span>

                        </div>
                        <div className="filter-section">
                            <PrimaryButton onClick={() => setModuleInfo({ id: '', title: '' })} data-bs-toggle="modal" data-bs-target="#popup" textTransform={'capitalize'} background={'#fff'} height={'35px'}>Add +</PrimaryButton>
                        </div>
                    </div>
                </div>
                <div className='resource-body'>
                    <div className="resource-body">
                        <DataTableComponent
                            columns={columns}
                            pagination
                            style={{ overflowX: 'auto' }}
                            data={data}
                        />
                    </div>
                </div>
            </div>

            <div className="modal fade human-resources-modal" id="popup" tabIndex="-1" aria-labelledby="csvLabel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-capitalize">{page_title}</h5>
                            <button type="button" ref={ref} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <CustomInput type="text" value={moduleinfo.title} borderColor={'var(--color-primary)'} label="Title" onChange={(e) => setModuleInfo({ ...moduleinfo, title: e.target.value })} />
                                    </div>
                                    <div className='col-md-12 text-end mt-3'>
                                        <PrimaryButton type="submit">
                                            Submit
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModuleListing