import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { PrimaryButton, StatusDropdown } from '../../components'
import GoBack from '../../components/controls/GoBack'
import Tooltips from '../../components/controls/Tooltips'
import { deleteUser, getEMP, getEmpInfo, updateEmp } from '../../store/emp/empAction'
import { errorToast, promiseToast } from '../../utils/Toast'
import { calculateDifference, genrate_date_time } from '../../utils/commonHelpers'
import Achievements from './Achievements'
import BankDetails from './BankDetails'
import ContactInfo from './ContactInfo'
import EducationalDetails from './EducationalDetails'
import GovtDoc from './GovtDoc'
import InitiativeResp from './InitiativeResp'
import JamiatResp from './JamiatResp'
import MawzeVisit from './MawzeVisit'
import OfficeInfo from './OfficeInfo'
import Personal from './Personal'
import ProjectWorked from './ProjectWorked'
import SkillSet from './SkillSet'
import './profile.css'
import { Roles, color, perm } from '../../assets/constant'
import { useAccessControl } from '../../hooks/useAccessControl'
import Swal from 'sweetalert2'
import axiosConfig from '../../utils/axiosConfig'
const BlackDiv = (id, deleteHandler, checkRoleAccess) => <div className='empty-div'>
    {id && checkRoleAccess([], ['human-resources'], perm.delete) &&
        <PrimaryButton
            onClick={deleteHandler}
            customStyle={{ marginTop: 20, marginLeft: 10 }}
        >
            Delete User
        </PrimaryButton>
    }

</div>
const options = [
    { value: 'pending', label: 'Pending' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'active', label: 'Active' },
]
const user_options = [
    { value: 'draft', label: 'Draft' },
    { value: 'publish', label: 'Publish' }
]
const EditProfile = ({ mode }) => {
    const isEdit = mode === 'edit'
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    let param = useParams();
    const navigate = useNavigate()
    let id = param?.id || user?.id
    const { checkRoleAccess } = useAccessControl()
    const [UserType, setUserType] = useState('')
    const [names, setNames] = useState({
        staticName: '',
        staticNameA: '',
    })

    const deleteHandler = useCallback(() => {
        if (param?.id) {
            Swal.fire({
                text: "Are you sure, you want to delete this user?",
                showCancelButton: true,
                confirmButtonColor: color.colorPrimary,
                cancelButtonColor: '#fff',
                focusCancel: true,
                fontSize: '20px',
                width: '30em',
                padding: '4em 0 5.25em'
            }
            ).then(async (result) => {
                if (result.isConfirmed) {

                    await promiseToast(() => axiosConfig.get(`delete/${param?.id}`), "Deleting User...", "User Deleted")
                    navigate("/human-resources")
                }
            })
        }
    }, [navigate, param?.id])
    const [personalData, setPersonalData] = useState(
        {
            prof: null,
            name: '',
            arabic_name: '',
            first_prefix: '',
            age: '',
            gender: '',
            misaq: '',
            marital_status: '',
            blood_group: '',
            dob: '',
            date_of_nikah: '',
            date_of_nikah_hijri: '',
            idara: '',
            category: '',
            organisation: '',
            vatan: '',
            nationality: '',
            jamaat: '',
            type_of_house: '',
            address: '',
            building: '',
            street: '',
            area: '',
            city: '',
            pincode: '',
            jamiaat: '',
            familly_tree: [
                { title: "Spouse", idara: "Zakreen", its_no: "40460067", age: "18" },
                { title: "Children", age: "18", its_no: "40460068" },
                { title: "Mother", its_no: "40460079" },
                { title: "Father", its_no: "40460010" }
            ]

        })
    const [educationInfo, setEducationInfo] = useState(
        {
            qualification: '',
            occupation: '',
            hifz_degree: '',
            hifz_degree_year: '',
            jamea_standard: '',
            jamea_degree: '',
            farig_year: '',
            tr_no: '',
        })
    const [bankDetails, setBankDetails] = useState(
        {
            account_name: "",
            account_number: "",
            bank_name: "",
            branch_name: "",
            cancelled_chq: "",
            ifsc: "",
            swift: ''
        })
    const [govtDocs, setGovtDocs] = useState(
        {
            passport_name: '',
            passport_number: '',
            issue_date: '',
            expiry_date: '',
            nationality: '',
            aadhar_no: '',
            pan_no: '',
        })
    const [skillSet, setSkillSet] = useState(
        {
            skill_set_type: '',
            level_of_competence: '',
            industry_type: '',
        })
    const [masterData, setMasterData] = useState(
        {
            skill_set_type: '',
            level_of_competence: '',
            industry_type: '',
        })
    const [mawze, setMawze] = useState(
        {
            mawze_name: '',
            nature_of_visit: '',
            tagged_initiative: '',
            other_participants: '',
        })
    const [project, setProject] = useState(
        {
            project_name: '',
            tagged_initiative: '',
            no_of_hours: '',
            start_date: null,
            end_date: null,
        })
    const [achivements, setAchivements] = useState(
        {
            achievement_type: '',
            tagged_initiative: '',
            remarks: '',

        })
    const [officeInfo, setOfficeInfo] = useState(
        {
            role: '',
            responsibility: '',
            current_assigned_initiative: '',
            current_assigned_jamiat: '',
        })

    const [resInfo, setResInfo] = useState(
        {
            initiative_name: '',
            status: '',
            start_date: null,
            end_date: null,
        })
    const [jamiatInfo, setJamiatInfo] = useState(
        {
            jamiat_name: '',
            status: '',
            start_date: null,
            end_date: null,
        })
    const [EmpActivity, setEmpActivity] = useState([
        {
            status: '',
            createdAt: '',
        }])




    const [activeSection, setActiveSection] = useState('section1');
    const componentsWithState = [
        { component: Personal, data: personalData, setData: setPersonalData },
        { component: EducationalDetails, data: educationInfo, setData: setEducationInfo },
        { component: ContactInfo, data: personalData, setData: setPersonalData },
        { component: BankDetails, data: bankDetails, setData: setBankDetails },
        { component: GovtDoc, data: govtDocs, setData: setGovtDocs },
        { component: SkillSet, data: skillSet, setData: setSkillSet },
        { component: MawzeVisit, data: mawze, setData: setMawze },
        { component: InitiativeResp, data: resInfo, setData: setResInfo },
        { component: JamiatResp, data: jamiatInfo, setData: setJamiatInfo },
        { component: ProjectWorked, data: project, setData: setProject },
        { component: Achievements, data: achivements, setData: setAchivements },
        { component: OfficeInfo, data: officeInfo, setData: setOfficeInfo },
        { component: () => BlackDiv(id, deleteHandler, checkRoleAccess) },

    ]

    const fetchEmpInfo = useCallback(async () => {
        let login_user_id = user?.id;
        const retrun_data = await dispatch(getEmpInfo({ id, login_user_id }));
        const emp_details = retrun_data?.payload?.data?.user_info;
        const bank = retrun_data?.payload?.data?.bank;
        const education_details = retrun_data?.payload?.data?.education;
        const govt_docs = retrun_data?.payload?.data?.govt_docs;
        const mawze = retrun_data?.payload?.data?.mawze;
        const project = retrun_data?.payload?.data?.project;
        const achievement = retrun_data?.payload?.data?.achievement;
        const office = retrun_data?.payload?.data?.office;
        const skill_info = retrun_data?.payload?.data?.skill;
        const emp_activity_info = retrun_data?.payload?.data?.emp_activity;
        if (emp_details) {
            setPersonalData(emp_details)
            personalData && setNames({ staticName: emp_details.name, staticNameA: emp_details.arabic_name })
        }
        if (bank) setBankDetails(bank)
        if (education_details) setEducationInfo(education_details)
        if (govt_docs) setGovtDocs(govt_docs)
        if (mawze) setMawze(mawze)
        if (project) setProject(project)
        if (achievement) setAchivements(achievement)
        if (office) setOfficeInfo(office)
        setUserType(emp_details?.user_type)

        const master_module_details = retrun_data?.payload?.master_module;
        // const data = retrun_data?.payload;
        setMasterData(master_module_details);
        const ini_rep_info = retrun_data?.payload?.data?.ini_rep;
        if (ini_rep_info) setResInfo(ini_rep_info)
        const jaimiat_info = retrun_data?.payload?.data.jaimiat;
        if (jaimiat_info) setJamiatInfo(jaimiat_info);
        if (skill_info) setSkillSet(skill_info);

        if (emp_activity_info) setEmpActivity([...emp_activity_info, { status: "data insert", createdAt: emp_details.createdAt }]);
    }, [dispatch, id])

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section');
            let found = false;

            sections.forEach((sec) => {
                const top = window.scrollY;
                const offset = sec.offsetTop - 150;
                const height = sec.offsetHeight;
                const id = sec.getAttribute('id');
                if (top >= offset && top < offset + height) {
                    setActiveSection(id);
                    found = true;
                }
            });
            if (!found) {
                setActiveSection(null);
            }
        };
        window.addEventListener('scroll', handleScroll);
        fetchEmpInfo();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [fetchEmpInfo]);


    const [formData, setFormData] = useState({});

    // Callback function to update the data with data from child components
    const updateFormData = useCallback((componentName, data) => {
        setFormData(prevData => ({
            ...prevData,
            [componentName]: data,
        }))
    }, [formData, setFormData])


    const handleSubmit = async (e) => {
        e.preventDefault();

        let login_user_id = user?.id;
        try {
            let { familly_tree, createdAt, email_verify, password, role, user_type, updatedAt, module_assign, ...user_info } = personalData
            let sendData = {
                login_user_id,
                user_info,
                achievement: achivements,
                bank: bankDetails,
                education: educationInfo,
                govt_docs: govtDocs,
                mawze: mawze,
                office: officeInfo,
                project: project,
                skill: skillSet
            }

            const res = await promiseToast(dispatch(updateEmp(sendData)));
            if (!res?.error) throw new Error(res?.error)
            fetchEmpInfo();
        } catch (error) {
            if (error.message) errorToast(error.message);
            return false
        }
    };

    const displayUserName = (username) => {
        if (username) {
            const userArray = username.split(" ");
            const nameReturn = userArray.slice(0, 2).join(" ");
            return nameReturn || username;
        }
        return username;
    }

    return (

        <div className='profile-container2'>
            <header className='edit-prof-header'>
                <nav className='tab-nav'>
                    <a aria-label="Personal Information" href="#section1" className={`section-com ${activeSection === 'section1' ? 'active' : ''}`}>
                        <button type='button' className={`menu-tab`}>Personal Information</button>
                    </a>
                    <a aria-label="Education details" href="#section2" className={`section-com ${activeSection === 'section2' ? 'active' : ''}`}>
                        <button type='button' className={`menu-tab`}>Education details</button>
                    </a>
                    <a aria-label="Contact Information" href="#section3" className={`section-com ${activeSection === 'section3' ? 'active' : ''}`}>
                        <button type='button' className={`menu-tab`}>Contact Information</button>
                    </a>
                    <a aria-label="Banking Details" href="#section4" className={`section-com ${activeSection === 'section4' ? 'active' : ''}`}>
                        <button type='button' className={`menu-tab`}>Banking Details</button>
                    </a>
                    <a aria-label="government documents" href="#section5" className={`section-com ${activeSection === 'section5' ? 'active' : ''}`}>
                        <button type='button' className={`menu-tab`}>government documents</button>
                    </a>
                    <a aria-label="Skill set" href="#section6" className={`section-com ${activeSection === 'section6' ? 'active' : ''}`}>
                        <button type='button' className={`menu-tab`}>Skill set</button>
                    </a>
                    <a aria-label="mawze visit list" href="#section7" className={`section-com ${activeSection === 'section7' ? 'active' : ''}`}>
                        <button type='button' className={`menu-tab`}>mawze visit list</button>
                    </a>
                    <a aria-label="initiative responsibility" href="#section8" className={`section-com ${activeSection === 'section8' ? 'active' : ''}`}>
                        <button type='button' className={`menu-tab`}>initiative responsibility</button>
                    </a>
                    <a aria-label="jamiat responsibility" href="#section9" className={`section-com ${activeSection === 'section9' ? 'active' : ''}`}>
                        <button type='button' className={`menu-tab`}>jamiat responsibility</button>
                    </a>
                    <a aria-label="Project Worked on" href="#section10" className={`section-com ${activeSection === 'section10' ? 'active' : ''}`}>
                        <button type='button' className={`menu-tab`}>Project Worked on</button>
                    </a>
                    <a aria-label="achivements" href="#section11" className={`section-com ${activeSection === 'section11' ? 'active' : ''}`}>
                        <button type='button' className={`menu-tab`}>achievements</button>
                    </a>
                    <a aria-label="office info" href="#section12" className={`section-com ${activeSection === 'section12' ? 'active' : ''}`}>
                        <button type='button' className={`menu-tab`}>office info</button>
                    </a>
                    <ul className="revisions">
                        {
                            EmpActivity.map((items, i) => {
                                let date_time = genrate_date_time(items.createdAt, 'date_with_time');
                                const status_msg = items.status === 'data updated' ? 'Updated' : 'Created';
                                return <li key={i}>{`${status_msg} data on ${date_time}`}</li>;
                            })
                        }
                    </ul>
                </nav>
            </header>

            <form onSubmit={handleSubmit} className={`${!isEdit && 'disable_form_fields'}`}>
                <div className='section-container'>
                    <div className="profile-header">
                        <div className="user-name">
                            <GoBack to='/human-resources' height={30} width={30} />
                            <h3 className="username">{displayUserName(names.staticName)}</h3>
                            <div className="header-join-exp">
                                <p>Joined: <span className='fw-bold'>
                                    {genrate_date_time(personalData.createdAt)}</span></p>
                                <p>Exp: <span className='fw-bold'>{calculateDifference(personalData.createdAt)}</span></p>
                            </div>
                        </div>
                        {isEdit &&
                            <>
                                <div className="me-3">
                                    <Tooltips content={"Several fields in the form below are sourced from ITS and are non-editable. Additionally, certain fields can be updated only once. For any changes, please contact HR for assistance."} />
                                </div>
                                <div className='profile-submit-section'>
                                    {
                                        param?.id ?
                                            <StatusDropdown options={options} value={{ label: personalData.status, value: personalData.status }} onChange={(e) => setPersonalData({ ...personalData, status: e.value })} />
                                            :
                                            <StatusDropdown options={user_options} value={personalData.user_status === 'publish' ? { value: 'publish', label: 'Publish' } : { value: 'draft', label: 'Draft' }} onChange={(e) => setPersonalData({ ...personalData, user_status: e.value })} />
                                    }
                                    <button type="submit" className="btn-submit">Submit</button>
                                </div>
                            </>
                        }
                    </div>
                    {componentsWithState.map(({ component: Component, data, setData }, i) => {
                        return <section id={`section${i + 1}`} key={i}>
                            <Component updateFormData={data => updateFormData(`${Component}`, data)} data={data} setData={setData} userType={UserType} mastermodule={masterData} dispName={names} isEdit={isEdit} />
                        </section>
                    })}
                </div>
            </form >

        </div >
    );
};



export default EditProfile

