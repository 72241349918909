import * as React from "react"
const CalendarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 150 150",
    }}
    aria-label="calendar-icon"
    viewBox="0 0 150 150"
    {...props}
  >
    <style>{".cal0{fill:#fff}.cal1{fill:#ffb600}"}</style>
    <path
      d="M136.3 54.8v76.7c-.1 5.9-5 10.7-10.9 10.6h-101c-5.9 0-10.8-4.7-10.9-10.7V54.7l122.8.1z"
      className="cal0"
    />
    <path d="M125.5 144.2h-101c-7.1.1-12.9-5.7-13-12.8V54.7c0-1.1 1-2.1 2.1-2h122.7c1.2 0 2.1.9 2.1 2.1v76.7c-.1 7.1-5.9 12.7-12.9 12.7zM15.7 56.9v74.5c.1 4.8 4 8.6 8.8 8.6h101c4.8.1 8.7-3.8 8.8-8.6V56.9H15.7z" />
    <path
      d="M136.3 35.4v19.4H13.6V35.4c.1-6 4.9-10.7 10.9-10.7h11.2v5c-.2 4 2.9 7.4 7 7.6s7.4-2.9 7.6-7v-5.6h17.4v5c0 4 3.3 7.3 7.3 7.3s7.3-3.3 7.3-7.3v-5h17.5v5c-.2 4 2.9 7.4 7 7.6s7.4-2.9 7.6-7v-5.6h11.2c5.8 0 10.7 4.8 10.7 10.7z"
      className="cal1"
    />
    <path d="M136.3 56.9H13.6c-1.2 0-2.1-.9-2.1-2.1V35.4c.1-7.1 5.9-12.8 13-12.8h11.2c1.2 0 2.1.9 2.1 2.1v5c-.1 2.9 2.1 5.3 4.9 5.5 2.9.1 5.3-2.1 5.5-4.9v-5.5c0-1.2.9-2.1 2.1-2.1h17.4c1.2 0 2.1.9 2.1 2.1v5c0 1.4.6 2.7 1.5 3.7 2 2 5.3 2 7.4 0 1-1 1.5-2.3 1.5-3.7v-5c0-1.2.9-2.1 2.1-2.1h17.5c1.2 0 2.1.9 2.1 2.1v5c0 1.4.6 2.7 1.5 3.7 1 1 2.3 1.5 3.7 1.5 2.9 0 5.2-2.3 5.2-5.2v-5c0-1.2.9-2.1 2.1-2.1h11.2c7.1-.1 12.9 5.7 13 12.8v19.4c-.1 1-1 2-2.3 2 .1 0 .1 0 0 0zM15.7 52.7h118.5V35.4c-.1-4.8-4-8.6-8.8-8.6h-8.9v3c.2 5.2-3.8 9.6-9 9.8-5.2.2-9.6-3.8-9.8-9v-3.7H84.4v3c0 5.2-4.2 9.4-9.4 9.4s-9.4-4.2-9.4-9.4v-3H52.3v3c.2 5.2-3.8 9.6-9 9.8s-9.6-3.8-9.8-9V27h-9c-4.8-.1-8.7 3.8-8.8 8.6v17.1z" />
    <path
      d="M50.2 15.6v14.2c-.2 4-3.6 7.2-7.6 7-3.8-.2-6.8-3.2-7-7V15.6c-.2-4 2.9-7.4 7-7.6 4-.2 7.4 2.9 7.6 7v.6z"
      className="cal0"
    />
    <path d="M42.9 39.2c-5.2 0-9.4-4.2-9.4-9.4V15.6c-.2-5.2 3.8-9.6 9-9.8 5.2-.2 9.6 3.8 9.8 9V29.7c0 5.2-4.2 9.5-9.4 9.5zm0-28.9c-2.9 0-5.2 2.3-5.2 5.2v14.2c-.1 2.9 2.1 5.3 4.9 5.5 2.9.1 5.3-2.1 5.5-4.9V15.6c0-1.4-.6-2.7-1.5-3.7-1-1-2.3-1.6-3.7-1.6z" />
    <path
      d="M82.3 15.6v14.2c0 4-3.3 7.3-7.3 7.3s-7.3-3.3-7.3-7.3V15.6c0-4 3.3-7.3 7.3-7.3s7.3 3.2 7.3 7.3z"
      className="cal0"
    />
    <path d="M75 39.2c-5.2 0-9.4-4.2-9.4-9.4V15.6c0-5.2 4.2-9.4 9.4-9.4s9.4 4.2 9.4 9.4v14.2c0 5.1-4.2 9.4-9.4 9.4zm0-28.9c-2.9 0-5.2 2.3-5.2 5.2v14.2c0 1.4.6 2.7 1.5 3.7 2 2 5.3 2 7.4 0 1-1 1.5-2.3 1.5-3.7V15.6c0-1.4-.6-2.7-1.5-3.7-1-1-2.3-1.6-3.7-1.6z" />
    <path
      d="M114.4 15.6v14.2c.2 4-2.9 7.4-7 7.6-4 .2-7.4-2.9-7.6-7V15.6c.2-4 3.6-7.2 7.6-7 3.8.1 6.8 3.2 7 7z"
      className="cal0"
    />
    <path d="M107 39.2c-5.2 0-9.4-4.2-9.4-9.4V15.6c-.2-5.2 3.8-9.6 9-9.8 5.2-.2 9.6 3.8 9.8 9V29.7c0 5.3-4.2 9.5-9.4 9.5zm0-28.9c-2.9 0-5.2 2.3-5.2 5.2v14.2c0 1.4.6 2.7 1.5 3.7 1 1 2.3 1.5 3.7 1.5 2.9 0 5.2-2.3 5.2-5.2V15.6c0-2.9-2.3-5.2-5.2-5.3z" />
    <path
      d="M102.9 98.5c.1 15.4-12.3 28-27.7 28.1-15.4.1-28-12.3-28.1-27.7s12.3-28 27.7-28.1h.2c15.3-.1 27.8 12.3 27.9 27.7z"
      className="cal1"
    />
    <path d="M75 128.2c-16.4-.1-29.7-13.5-29.5-30s13.5-29.7 30-29.5c16.4.1 29.6 13.4 29.5 29.8-.2 16.4-13.6 29.7-30 29.7zm0-55.4c-14.2.2-25.5 11.7-25.4 25.8s11.7 25.5 25.8 25.4c14-.1 25.3-11.5 25.4-25.5-.1-14.2-11.7-25.7-25.8-25.7z" />
    <path d="M75.4 115.5c-4.4 0-8.7-1.6-12-4.5-7.2-6.2-7.9-17-1.7-24.2.3-.4.7-.7 1-1.1 1.9-1.8 4.1-3.2 6.6-4.1 1.7-.6 3.5.3 4.1 1.9.3.9.2 1.8-.2 2.6-2.2 4-1.4 8.9 1.8 12.1 3.4 3.2 8.4 3.9 12.5 1.8 1.2-.6 2.6-.5 3.7.4 1 .8 1.3 2.2.9 3.4-1 2.9-2.9 5.5-5.2 7.5-3.2 2.8-7.3 4.2-11.5 4.2zm-6.9-28.9c-1 .6-2 1.3-2.8 2.1-5.2 5-5.3 13.2-.4 18.3.3.3.6.6.9.8 5.2 4.4 12.7 4.5 18 .3 1.2-1 2.2-2.1 2.9-3.4-5.3 1.6-11 .3-15-3.5-3.8-3.7-5.2-9.4-3.6-14.6z" />
  </svg>
)
export default CalendarIcon
