import React from 'react'
import { ProfileImg } from '../../components'
import { Logo } from '../../assets/images/icons'
import Nouser from './img/no-user.png'

const PdfHeader = ({user, masterData }) => {
    const { user_info, office } = user;
    const { responsibility,role } = masterData || {};
    
    const resp= responsibility?.filter(res=>office?.responsibility==res.id)
    const c_role= role?.filter(res=>office.role==res.id)
    return (
        <div id="header" className="pdf-header">
            <div className="pdf-topbar">
                <div className="address-sec">
                    <p>
                        Badri Mahal, Borabazar Precinct, Ballard Estate, Fort, <br />
                        Mumbai, Maharashtra 400001
                    </p>
                    <div className="contact-sec">
                        <div>
                            <span>+91-987 456 3212 | 022 - 456 321</span>
                        </div>
                    </div>
                </div>
                <div className="pdf-logo-sec">
                    <Logo height="100" width="100" />
                </div>
            </div>
            <div className="pdf-prof-sec">
                <div className="personal-info">
                   { <ProfileImg height="50" width="50" image={user_info.profile_pic} /> || <ProfileImg height="50" width="50" src={Nouser} />}
                    <div>
                        <p>{user_info?.its_no}</p>
                        <p className="font-arabic arabic-name">{user_info?.arabic_name}</p>
                        <p className="eng">{user_info?.name}</p>
                    </div>
                </div>
                <div className="office-info">

                    <p className="content-pair"><span className="info-label">Role:</span><span> {c_role?.[0]?.title || '-'}</span></p>
                    <p className="content-pair"><span className="info-label">Responsibility:</span><span> {resp?.[0]?.title || '-'}</span></p>
                    <p className="content-pair"><span className="info-label">Curr. Assigned Initiative:</span><span> {office.current_assigned_initiative || '-'}</span></p>
                    <p className="content-pair"><span className="info-label">Curr. Assigned Jamiat:</span><span> {office.current_assigned_jamiat || '-'}</span></p>
                </div>
            </div>
        </div>
    )
}

export default PdfHeader