
import React from 'react'
import { SearchField } from '../../components'
import './header.css'
import '../../assets/css/responsive.css'
import { NotificationIcon } from '../../assets/images/icons'
import ProfileImg from '../../components/controls/ProfileImg'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import HeaderSkeleton from '../../components/loader/headerSkeleton'
import { useAccessControl } from '../../hooks/useAccessControl'
import { setFilterText } from '../../store/auth/authSlice'
const Header = ({ slug }) => {
    const { user, filterText } = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const { checkRoleAccess } = useAccessControl();
    const handleFilterTextChange = (e) => {
        dispatch(setFilterText({ slug, text: e.target.value }))
    }
    return (
        <header className='header'>
            <div className="header-search">
                <SearchField onChange={handleFilterTextChange} value={filterText?.[slug] || ""} placeholder='What are you looking for?' />
            </div>
            <div className="header-dt">
                <span className='dt'>
                    {new Date().toUTCString().slice(0, 16)}
                </span>
            </div>
            <div className="header-profile-sec">
                {checkRoleAccess(['super_admin', 'admin'], []) && <NavLink to='/user-access-listing' className='access-panel'><button type='button' aria-label='access-panel'> Access Panel</button></NavLink>}
                <NavLink className="notification" to={'/'} aria-label='notification'>
                    <NotificationIcon isActive={true} />
                </NavLink>
                {(user &&
                    <div className="header-profile-sec">
                        <NavLink to={'/edit-profile'} replace className="user-prof">
                            <ProfileImg image={user.profile_pic} gender={user?.gender} />
                        </NavLink>
                        <div className="user-info">
                            <p className="header-user-name ellipsis text-nowrap">
                                {user?.name}
                            </p>
                            <p className="user-role">
                                {user && user.role.charAt(0).toUpperCase() + user.role.replace(/_/g, ' ').slice(1)}
                            </p>
                        </div>
                    </div>
                ) || <HeaderSkeleton />}
            </div>
        </header>)
}

export default Header