import React from 'react'

const Dashboard = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <h3>Coming soon...</h3>
    </div>
  )
}

export default Dashboard