import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getModuleTypes = createAsyncThunk('module/get_type', async ({ module_slug }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('get-master-module?module_slug=' + module_slug);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const getModuleInfo = createAsyncThunk('module/get', async ({ module_type, module_slug }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('get-master-module-info?module_type=' + module_type + '&module_slug=' + module_slug);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const saveModifyModule = createAsyncThunk('module/save_modify', async ({ module_type, module_slug, title, id }, { rejectWithValue }) => {
	try {
		let send_data = {};
		if (id) {
			send_data = { module_type, module_slug, title, id };
		} else {
			send_data = { module_type, module_slug, title };
		}
		const response = await axiosConfig.post('save-modify-master-module-info', send_data);
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});

export const deleteModuleInfo = createAsyncThunk('module/delete', async ({ module_type, module_slug, id }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('delete-master-module-info', { module_type, module_slug, id });
		return await response.data;
	} catch (error) {
		return rejectWithValue({ error: error?.response?.data?.error, message: error?.response?.data?.message || error?.error?.message });
	}
});