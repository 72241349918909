import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { BackIcon } from '../../assets/images/icons'

const GoBack = ({ to = -1, ...props }) => {
    let navigate = useNavigate();
    return (
        <button type='button' aria-label="go-back" onClick={() => navigate(to)}>
            <BackIcon {...props} />
        </button>
    )
}

export default GoBack