
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../layouts/header/Header'
import { Sidebar } from '../layouts';
import Loading from '../pages/Error/Loading';
import { HasAccess } from '../components';
import PrivacyPolicy from '../pages/Error/PrivacyPolicy';

const PrivateRoute = ({ component: Component, permissions, displayName, access, role, ...props }) => {
	const { accessToken, user } = useSelector(state => state.auth)

	if (!accessToken) return <Navigate to="/" />;
	if (!user?.role) return <Loading />;
	// if (!user?.tnc_verify) return <PrivacyPolicy />;
	return (
		<HasAccess permissions={permissions} access={access} role={role}>
			{!user?.tnc_verify && <PrivacyPolicy />}
			<div className="main-container">
				<Sidebar />
				<div className="content-cotainer">
					<div className="main-header">
						<Header slug={permissions} />
					</div>
					<div className="main-content">
						<Component displayName={displayName} {...props} />
					</div>
				</div>
			</div>
		</HasAccess>
	);
};

export default PrivateRoute;