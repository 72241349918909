import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useNavigate } from 'react-router-dom';
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import './app.css'
import { useDispatch, useSelector } from 'react-redux';
import { Suspense, useCallback, useEffect } from 'react';
import { verifyUserDetails } from '../store/auth/authActions';
import { ToastContainer } from 'react-toastify';
import router from '../routes/route';
import Loading from '../pages/Error/Loading';


const App = () => {
	const { accessToken } = useSelector((state) => state.auth);
	return (
		<Suspense fallback={<Loading />}>
			<RouterProvider router={router(accessToken)} fallbackElement={<Loading />} />
		</Suspense>
	)
}
export const Layout = () => {
	const { accessToken } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {

		if (accessToken) {
			dispatch(verifyUserDetails());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<ToastContainer />
			<Outlet />
		</div>
	)
}

export default App;