import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLoaderData, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { BackIcon, SearchIcon } from '../../assets/images/icons';
import { PrimaryButton } from '../../components';
import { useAccessControl } from '../../hooks/useAccessControl';
import axiosConfig from '../../utils/axiosConfig';
import { getAssign, saveAssign, updateAssign } from './../../store/assign/assignAction';
import { errorToast, promiseToast } from './../../utils/Toast';
import './humanresource.css';
import { color, perm } from '../../assets/constant';
import GoBack from '../../components/controls/GoBack';

const customStyles = {
    control: (provided) => ({
        ...provided,
        width: 350,
        textAlign: 'center',
        borderRadius: '20px',
        boxShadow: '#f8f8f8',
        padding: '0 10px',
        color: "#000",
        borderColor: "transparent",
        cursor: 'pointer',
        '&:hover': {
            borderColor: color.colorPrimary

        }
    }),

    option: (provided, state) => ({
        ...provided,
        fontWeight: '500',
        fontSize: '13px',
        width: '350px',
        backgroundColor: state.isSelected ? color.colorPrimary : 'white',
        color: state.isSelected ? '#fff' : '#000',
        '&:active': {
            background: color.colorPrimary,
            color: '#f00'
        },
        '&:hover': {
            background: color.colorPrimary,
            color: '#fff',
            cursor: 'pointer'
        }
    }),
    singleValue: (provided) => ({
        ...provided,
        fontWeight: '500',
    }),
};

const UserAccessForm = ({ mode }) => {
    const isEdit = mode === 'edit'
    const accessData = useLoaderData()
    const { modules, user } = useSelector(state => state.auth);
    const { UserList } = useSelector(state => state.assign);
    const [permissions, setPermissions] = useState([...modules].filter(emp => emp.role === "admin").map((data => { return { ...data, permission: [] } })));
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [assignUser, setAssignUser] = useState(null);
    const [isAdminChecked, setIsAdminChecked] = useState(false);
    let permissionTypes = useMemo(() => Object.values(perm), [])
    const { checkRoleAccess } = useAccessControl()
    useEffect(() => {
        if (checkRoleAccess(["super_admin", "admin"], [])) dispatch(getAssign());
    }, [])
    useEffect(() => {
        if (isEdit && accessData) {
            setAssignUser(accessData.user_id);

            setPermissions(prevPermissions => {
                let newPermissions = [...prevPermissions].map(module => {
                    const existingModule = {...accessData}.modules.find(p => p.module_assign_id === module.module_id);
                    if (existingModule) {
                        if (existingModule.role === "admin") module.admin = true;
                        existingModule.permission.map((per) => module[per] = true)
                        module.id = existingModule.id;
                        module.role = existingModule.role;
                        module.permission = existingModule.permission;
                    }
                    return module;
                })
                if (newPermissions.every(e => e.admin)) setIsAdminChecked(true)
                return newPermissions
            }
            );

        }
    }, [isEdit,  permissionTypes]);
    // For handling single check and row checks
    const checkUpdate = useCallback(async (module) => {
        let arr1 = {...accessData}?.modules?.find(e => e.module_assign_id === module.module_id)?.permission;
        let arr2 = module?.permission
        if (arr1?.length !== arr2?.length) return true;
        // Sort the arrays with a custom string comparison function
        const sortedArr1 = arr1.slice().sort((a, b) => a.localeCompare(b));
        const sortedArr2 = arr2.slice().sort((a, b) => a.localeCompare(b));
        // Compare each element of the sorted arrays
        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) return true;
        }

        // If all elements are equal after sorting, return true
        return false;
    }, [accessData?.modules])

    const handleIndividualPermissionChange = useCallback((module, permissionType) => {
        const { permission } = module;
        const permissionIndex = permission.indexOf(permissionType);
        if (permissionIndex === -1) {
            module.permission.push(permissionType);
            if (permissionTypes.every(ev => module.permission.includes(ev))) module.admin = true;
        } else {
            module.admin = false;
            module.permission = permission.filter(a => a !== permissionType);
        }
        if (isEdit) module.updated = checkUpdate(module);

        return module;
    }, [checkUpdate, isEdit, permissionTypes])

    const handlePermissionChange = useCallback((moduleId, permissionType) => {
        setPermissions(prevPermissions => {
            const newPermissions = [...prevPermissions].map((module) => {
                if (module.module_id !== moduleId) return module;
                if (permissionType === "admin") {
                    module.admin = !module.admin;
                    module.permission = module.admin ? permissionTypes : [];
                    module.updated = checkUpdate(module)
                } else {
                    module = handleIndividualPermissionChange(module, permissionType);
                }
                return module;
            });

            setIsAdminChecked(newPermissions.every(e => e.admin))
            return newPermissions;
        });
    }, [checkUpdate, handleIndividualPermissionChange, permissionTypes])

    // For handling column checks
    const handlePermissionChangeColumn = useCallback((isChecked, permissionType) => {
        setPermissions(prevPermissions => {
            const newPermissions = prevPermissions.map((module) => {
                module[permissionType] = isChecked;
                if (isChecked) {
                    if (!module.permission.includes(permissionType)) {
                        module.permission.push(permissionType);
                        if (permissionTypes.every(ev => module.permission.includes(ev))) module.admin = true;
                    }
                } else if (module.permission.includes(permissionType)) {
                    module.permission = module.permission.filter(a => a !== permissionType)
                    module.admin = false
                }
                module.updated = checkUpdate(module)
                return module;
            });
            setIsAdminChecked(newPermissions.every(e => e.admin))
            return newPermissions;
        });
    }, [checkUpdate, permissionTypes])

    // For handling all checks
    const handlePermissionChangeAll = useCallback((e) => {
        let isChecked = e.target.checked
        setIsAdminChecked(isChecked)
        setPermissions(prevPermissions => {
            const newPermissions = prevPermissions.map(module => {
                module.permission = isChecked ? permissionTypes : [];
                module.admin = isChecked
                permissionTypes.forEach(p => {
                    module[p] = isChecked
                })
                module.updated = checkUpdate(module)
                return module;
            });
            return newPermissions;
        });
    }, [checkUpdate, permissionTypes])

    const handleSubmit = useCallback(async () => {
        try {
            if (!assignUser) return errorToast("Please select user.")
            let res;
            if (isEdit) {
                const data = {
                    user_id: assignUser,
                    permissions: permissions.map((mod) => {
                        const { admin, permission, module_id, updated } = mod
                        return {
                            admin: !!admin,
                            permission,
                            module_id,
                            updated,
                            id: mod?.id,
                        }
                    })
                }
                res = await promiseToast(dispatch(updateAssign(data)), "Updating...", "Permission has been updated successfully.", "Failed to update please try again")
            } else {
                const data = {
                    user_id: assignUser,
                    permissions: permissions.filter((filItem) => !!filItem.permission.length).map(({ admin, permission, module_id }) => ({
                        admin: !!admin,
                        permission,
                        module_id
                    }))
                }
                res = await promiseToast(dispatch(saveAssign(data)), "Assigning...", "Permission has been assined successfully.", "Failed to assine please try again")
            }

            navigate('/user-access-listing')
        } catch (error) {

        }
    }, [assignUser, dispatch, navigate, permissions, isEdit])
    return (
        <div className="container-listing">
            <div className="">
                <div className='resource-header'>
                    <div className="modulename d-flex align-items-center gap-2">
                        <GoBack to="/user-access-listing" />
                        <span className='main-heading'>Access Panel</span>
                    </div>
                </div>
            </div>
            <div className='resource-body access_panel'>
                <div className="resource-body user-access-listing">
                    <div className="search-field">
                        <Select
                            className='user-access-dropdown'
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => <SearchIcon width={20} height={14} />
                            }}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customStyles}
                            onChange={(a) => setAssignUser(a.value)}
                            menuClassName={'custom-menu-styles'}
                            placeholder="All Users"
                            options={UserList?.map((option) => ({
                                value: option.id,
                                label: option.name,
                            }))}
                            defaultValue={isEdit ? { value: accessData?.user?.id, label: accessData?.user?.name } : undefined}
                            isDisabled={isEdit}
                            isSearchable={true}
                        />
                    </div>
                    <div className='row heading_wrapper'>
                        <div className='col-md-3 module_names'>
                            <label htmlFor="Module">Module</label>
                        </div>

                        <div className='col-md-9'>
                            <div className='row'>
                                {user.role === "super_admin" && (

                                    <div className='col'>
                                        <span>Admin</span>
                                        <input
                                            type="checkbox"
                                            className='checkbox'
                                            onChange={handlePermissionChangeAll}
                                            checked={isAdminChecked}
                                        />
                                    </div>
                                )}
                                {permissionTypes.map((perms) => {
                                    return (
                                        <div key={perms} className='col'>
                                            <span className='title-uppercase'> {perms} </span>
                                            <input
                                                type="checkbox"
                                                className='checkbox'
                                                onChange={(e) => handlePermissionChangeColumn(e.target.checked, perms)}
                                                checked={permissions.every(mod => {
                                                    return mod.permission.includes(perms)
                                                }) || false}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='body_wrapper'>
                        {permissions.map((module) => (
                            <div className='row' key={module.module_id}>
                                <div className='col-md-3 module_names'>
                                    <label htmlFor="Module">{module.module_name}</label>
                                </div>
                                <div className='col-md-9'>
                                    <div className='row permission_details'>
                                        {user.role === "super_admin" && (
                                            <div className='col'>
                                                <input
                                                    type="checkbox"
                                                    className='checkbox'
                                                    label={"admin"}
                                                    onChange={() => handlePermissionChange(module.module_id, 'admin')}
                                                    checked={module?.admin || false}
                                                />
                                            </div>
                                        )}

                                        {permissionTypes.map(permissionType => (
                                            <div key={permissionType} className='col'>
                                                <input
                                                    type="checkbox"
                                                    className='checkbox'
                                                    label={permissionType}
                                                    onChange={() => handlePermissionChange(module.module_id, permissionType)}
                                                    checked={module.permission.includes(permissionType)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='save_btn text-center mt-4'>
                            <PrimaryButton background={'transparent'} height={'40px'} onClick={handleSubmit}>
                                {isEdit ? "Update" : "Save"} Access
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const UserAccessFormLoader = async ({ params }) => {
    try {
        const response = await axiosConfig.get(`get-user-assign/${params.id}`);
        return response?.data?.assign || [];
    } catch (error) {
        return []
    }
}
export default UserAccessForm