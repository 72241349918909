import React, { useRef, useState } from "react";
import "./login.css";
import { InputField, PrimaryButton } from "../../components";
import { Logo } from "../../assets/images/icons";
import { NavLink, useParams } from "react-router-dom";
import { errorToast, promiseToast } from "../../utils/Toast";
import { validatePassword } from "../../Validation/Validation";
import { useDispatch } from "react-redux";
import { modifyPassword } from "../../store/auth/authActions";

const ChangePassword = () => {
	const dispatch = useDispatch();
	const ref = useRef(null)
	const { token } = useParams()
	const [password, setPassword] = useState({
		newPass: "",
		cnfPass: "",
	});
	const [passErr, setPassErr] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();

		const { newPass, cnfPass } = password;
		const isValidPassword = validatePassword(newPass);
		if (isValidPassword && newPass === cnfPass) {
			let res = await promiseToast(dispatch(modifyPassword({ password: password.newPass, token })), "Validating...", "Password has been updated successfully!")
			if(res.error) return;
			ref.current.click()
		}
		else if (!isValidPassword) {
			errorToast("Your password should include at least one digit, one uppercase letter, one lowercase letter, and one special symbol.")
		} else {
			errorToast("Password mis-match")
		}
	};

	return (
		<div className="login-wrapper">
			<div className="login-outer">
				<div className="login-inner">
					<div className="login-logo">
						<Logo height="150" width="150px" />
					</div>
					<form className="input-fields" onSubmit={handleSubmit}>
						<InputField
							type="password"
							id="newPass"
							label={"New Password"}
							value={password.newPass}
							onChange={e => setPassword({ ...password, newPass: e.target.value })}
							error={passErr}
							onFocus={() => setPassErr(null)}
							icon={false}
							isPass={true}
							placeholder="Type your new password"
							required
						/>
						<InputField
							type="password"
							id="cnfPass"
							label={"Confirm Password"}
							value={password.cnfPass}
							onChange={e => setPassword({ ...password, cnfPass: e.target.value })}
							error={passErr}
							onFocus={() => setPassErr(null)}
							icon={false}
							isPass={true}
							placeholder="Type confirm password"
							required
						/>

						<PrimaryButton type="submit" >
							Submit
						</PrimaryButton>
					</form>
					<div className="forget-pass">
						<NavLink to="/" ref={ref}>Back to login</NavLink>
					</div>
				</div>
				<div className="copyright">
					<span>&copy; {new Date().getFullYear()} Daerat-al-Aqeeq. All Right Reserved.</span>
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;
