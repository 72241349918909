import React, { useRef, useState } from 'react';
import { EditIcon } from '../../assets/images/icons';
import { CustomInput, DateControl, Dropdown, FamilyCard, ProfileImg } from '../../components';
import { errorToast } from '../../utils/Toast';
import { imageToBase64 } from '../../utils/commonHelpers';

const options = [
    { value: 'A+', label: 'A+' },
    { value: 'A-', label: 'A-' },
    { value: 'B+', label: 'B+' },
    { value: 'B-', label: 'B-' },
    { value: 'O+', label: 'O+' },
    { value: 'O-', label: 'O-' },
    { value: 'AB+', label: 'AB+' },
    { value: 'AB-', label: 'AB-' },
]


const Personal = ({ data, setData, userType, dispName, isEdit }) => {

    const ref = useRef(null)
    const [imagePreviewSrc, setImagePreviewSrc] = useState('')
    const [isImageSelected, setIsImageSelected] = useState('')

    const showImagePreviewAndValidate = async (e) => {
        try {
            let selectedFile = ref.current.files.item(0)
            let supportedFormat = ["image/jpeg", "image/png", "image/webp"]
            let size = selectedFile && parseFloat(selectedFile.size / 1024).toFixed(2);
            if (!selectedFile) throw new Error();
            if (!supportedFormat.includes(selectedFile.type)) throw new Error("Invalid file type.Only JPEG/PNG/webp are allowed.");
            if (size > 5000) throw new Error("File size is more then 5 MB");

            setData({ ...data, new_profile_pic: await imageToBase64(ref.current.files[0]) });
            let fileReader = new FileReader();
            fileReader.readAsDataURL(selectedFile);
            fileReader.addEventListener('load', (event) => {
                setImagePreviewSrc(event.target.result)
                setIsImageSelected(true)
            })
        } catch (error) {
            e.target.value = ""
            setImagePreviewSrc('')
            setIsImageSelected(false)
            setData({ ...data, new_profile_pic: null });
            if (error?.message) return errorToast(error.message);

        }
    }

    const handleChange = e => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value })

    }
    return (
        <div className="personal-sec">
            <h2 className='section-name'>Personal Information</h2>
            <div className="row mt-2">
                <div className="col-md-12">
                    <div style={{ display: 'flex', gap: '30px', alignItems: 'center', justifyContent: 'start' }}>
                        <figure className='prof-container'>
                            <input type="file" id='file' name='file' hidden ref={ref} onChange={showImagePreviewAndValidate} className=' focus:outline-0 w-full ' />
                            {(data?.profile_pic || imagePreviewSrc) ?
                                <ProfileImg
                                    local={isImageSelected}
                                    image={imagePreviewSrc || data.profile_pic}
                                    gender={data?.gender}
                                    // src={}
                                    height={60} width={60} />
                                : <ProfileImg
                                    gender={data?.gender}
                                    height={60} width={60} />
                            }
                            {isEdit && userType === "Other Community" && <button type='button' className="prof-edit-icon" onClick={() => { ref.current.click() }}><EditIcon height={15} width={15} /></button>}
                        </figure>
                        <div className="prof-names">
                            {userType === 'Other Community' ?
                                <div className="prof-names">
                                    <p style={{ color: "var(--color-primary)", fontSize: '15px' }}>{dispName.staticName}</p></div> :
                                <div><p className="font-arabic" style={{ color: "var(--color-primary)", fontSize: '22px' }}>{dispName.staticNameA}</p><span style={{ fontSize: '15px' }}>{dispName.staticName}</span></div>
                            }

                        </div>
                    </div>
                </div>
                {userType !== "Other Community" && <div className="col-lg-3 col-md-4 mt-3"><CustomInput label={'ITS No.'} value={data.its_no} disabled /></div>}

                <div className="col-lg-3 col-md-4 mt-3"><CustomInput label={'Full Name'} name='name' value={data.name} onChange={handleChange} placeholder='Enter Full Name' disabled={userType !== "Other Community" ? true : false} /></div>

                {userType !== "Other Community" && <div className="col-lg-3 col-md-4 mt-3 font-arabic-field"><CustomInput label={'Full Name Arabic'} name='arabic_name' value={data.arabic_name} onChange={handleChange} placeholder='Enter Full Name Arabic' disabled /></div>}

                <div className="col-lg-3 col-md-4 mt-3"><CustomInput label={'First Prefix'} name='first_prefix' value={data.first_prefix} onChange={handleChange} placeholder='Enter Prefix' disabled={userType !== "Other Community" ? true : false} /></div>

                <div className="col-lg-3 col-md-4 mt-3"><CustomInput label={'Age'} type={'number'} name='age' value={data.age} onChange={handleChange} placeholder='Enter Age' disabled={userType !== "Other Community" ? true : false} /></div>

                <div className="col-lg-3 col-md-4  mt-3"><CustomInput label={'Marital Status'} name='marital_status' value={data.marital_status} onChange={handleChange} placeholder='Enter Marital Status' disabled={userType !== "Other Community" ? true : false} /></div>
                <div className="col-lg-6 col-md-6 mt-3">
                    <div className={`emp_wrapper`} id="employee_type">
                        <span className='label'>Select Gender</span>
                        <div className='d-flex gap-4'>
                            <label htmlFor="mumin_type" className="form-check  gender-check">
                                <input className="form-check-input" type="radio" name="gender" value="M"
                                    checked={data.gender === 'M'}
                                    onChange={() => setData({ ...data, gender: 'M' })} id="mumin_type" disabled={userType !== "Other Community" ? true : false} />
                                <label className="form-check-label" htmlFor="mumin_type"> Male </label>
                            </label>
                            <label htmlFor="other_type" className="form-check gender-check">
                                <input className="form-check-input" type="radio" name="gender" value="F"
                                    checked={data.gender === 'F'}
                                    onChange={() => setData({ ...data, gender: 'F' })} id="other_type" disabled={userType !== "Other Community" ? true : false} />
                                <label className="form-check-label" htmlFor="other_type">Female</label>
                            </label>
                        </div>
                    </div>
                </div>

                {userType !== "Other Community" && <div className="col-lg-3  mt-3"><CustomInput label={'Misaq'} name='misaq' value={data.misaq} onChange={handleChange} placeholder='Enter Misaq' disabled /></div>}

                <div className="col-lg-3 col-md-4  mt-3 "><label htmlFor="date" style={{ margin: '0 0 5px 20px' }}>Blood Group</label><Dropdown options={options} value={data.blood_group ? { value: data.blood_group, label: data.blood_group } : ""} onChange={(e) => setData({ ...data, blood_group: e.value })} placeholder="Blood group" gap={'0px'} padding={'0 10px'} width={'100%'} /></div>


                <div className="col-lg-3 col-md-4  mt-3 ">
                    <DateControl placeholder='Date of Birth' placeholderText={'Please select a date'} id='dob' label={'Date of Birth'} format="YYYY-MM-DD" value={data.dob} onChange={(e) => setData({ ...data, dob: e?.format() })} />
                </div>

                {/* {userType !== "Other Community" && <> <div className="col-lg-3 col-md-4  mt-3"><CustomInput label={'Date of Nikah'} name='date_of_nikah' value={data.date_of_nikah} onChange={handleChange} placeholder='Enter Date of Nikah' disabled /></div> */}
                {userType !== "Other Community" && <> <div className="col-lg-3 col-md-4  mt-3"><DateControl placeholder='Date of Nikah' placeholderText={'Please select Date of Nikahe'} id='dob' label={'Date of Nikah'} format="YYYY-MM-DD" value={data.date_of_nikah} onChange={(e) => setData({ ...data, date_of_nikah: e?.format() })} /></div>

                    {/* <div className="col-lg-3 col-md-4  mt-3"><CustomInput label={'Date of Nikah Hijri'} name='date_of_nikah_hijri' value={data.date_of_nikah_hijri} onChange={handleChange} placeholder='Enter Date of Nikah Hijri' disabled /></div> */}

                    <div className="col-lg-3 col-md-4  mt-3"><CustomInput label={'Idara'} name='idara' value={data.idara} onChange={handleChange} placeholder='Enter Idara' disabled /></div>

                    <div className="col-lg-3 col-md-4  mt-3"><CustomInput label={'Category'} name='category' value={data.category} onChange={handleChange} placeholder='Enter Category' disabled /></div>

                    <div className="col-lg-3 col-md-4  mt-3"><CustomInput label={'Organisation'} name='organisation' value={data.organisation} onChange={handleChange} placeholder='Enter Organisation' disabled /></div>

                    <div className="col-lg-3 col-md-4  mt-3"><CustomInput label={'Vatan'} name='vatan' value={data.vatan} onChange={handleChange} placeholder='Enter Vatan' disabled /></div> </>}
                <div className="col-lg-3 col-md-4  mt-3"><CustomInput label={'Nationality'} name='nationality' value={data.nationality} onChange={handleChange} placeholder='Enter Nationality' disabled={userType !== "Other Community" ? true : false} /></div>

                {userType !== "Other Community" && <div className="col-lg-3 col-md-4  mt-3"><CustomInput label={'Jamaat'} name='jamaat' value={data.jamaat} onChange={handleChange} placeholder='Enter Jamaat' disabled /></div>}


            </div>
            <div className="row mt-2">
                {data.familly_tree && data.familly_tree.map((member) => (
                    <div key={member.its_no} className="col-xxl-4 col-md-6  mt-4">
                        <FamilyCard
                            name={member.fullname}
                            member={member}
                            image={member.profile_pic}
                            title={member.title}
                            idara={member.idara}
                            its_no={member.its_no}
                            age={member.age}
                        />
                    </div>
                ))}

            </div>
        </div>
    )
}

export default Personal