import React from 'react'
import './familycard.css'
import ProfileImg from '../controls/ProfileImg'
const FamilyCard = ({ name, gender, image, title, idara, its_no, age }) => {
    return (
        <div className='family-cart-wrapper'>
            <div className="card-title">{title}</div>
            <div className='prof-container'>
                <ProfileImg height={50} width={50} gender={gender} image={image} CustomClass="w-100 h-100" />
            </div>
            <div className="family-info overflow-hidden ">
                {name && <div className="info-group-name ">
                    <p className="f-label">Name:</p>
                        {name}
                </div>}
                <div className="info-group"><p className="f-label">Idara:</p> {idara}</div>
                <div className="its_age">{its_no && <div className="info-group"> <p className="f-label">ITS No.: </p> {its_no}</div>}
                {age && <div className="info-group"><p className="f-label">Age: </p> {age}</div>}</div>
            </div>
        </div>
    )
}

export default FamilyCard