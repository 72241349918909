import { useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EditIcon } from "../../assets/images/icons";
import { DataTableComponent, DateControl, PrimaryButton } from "../../components";
import Button from "../../components/controls/Button";
import { saveModifyJamiatInfo } from "../../store/emp/empAction";
import { promiseToast } from '../../utils/Toast';
import { calculateDifference, genrate_date_time } from "../../utils/commonHelpers";

const statusOpt = [
    { value: 'current', label: 'Current' },
    { value: 'past', label: 'Past' },
]

const JamiatResp = ({ data, setData, mastermodule }) => {
    const dispatch = useDispatch();
    let initOpt = []
    let employees = [];
    let current_slug = window.location.pathname.split('/');
    let page_slug = current_slug[2] ? current_slug[2] : '';
    let { id } = useParams();
    const CloseRef = useRef(null)

    const [userData, setUserData] = useState({
        id: '',
        jamiat_name: '',
        status: "",
        start_date: '',
        end_date: ''
    });
    if (mastermodule.jamiat_name) {
        initOpt = mastermodule?.jamiat_name?.map((data) => ({ value: data.id, label: data.title }))
    }

    const columns = [
        {
            key: "name",
            name: 'Jamiat Name',
            cell: row => initOpt?.find(op => op.value == row.name)?.label,
            width: "30%",
        },
        {
            key: "start_date",
            name: 'Start Date',
            cell: row => row?.start_date ? genrate_date_time(row?.start_date) : '-',
            width: "20%"
        },
        {
            key: "end_date",
            name: 'End Date',
            cell: row => row?.end_date ? genrate_date_time(row?.end_date) : '-',
            width: "20%"
        },
        {
            key: "duration",
            name: 'Duration',
            cell: row => row?.duration,
            width: "20%"
        },

        {
            cell: row => <Button aria-label="edit" className='action-icon' onClick={() => setUserData({ id: row.id, jamiat_name: row.name, status: row.status, start_date: row.start_date, end_date: row.end_date })} data-bs-toggle="modal" data-bs-target="#jamiatResp"><EditIcon height={15} width={15} /></Button>,
            width: "10%",
            style: { paddingRight: 0, justifyContent: 'flex-end' },
        },
    ];
    if (page_slug == 'view-employee') {
        columns.pop();
    }

    if (data.length > 0) {
        data.forEach((item) => {
            let duration;
            if (item.status == 'past') {
                duration = calculateDifference(item.start_date, item.end_date);
            }

            employees.push({
                id: item.j_res_id,
                status: item.status,
                name: item.jamiat_name,
                start_date: item.start_date,
                end_date: item.end_date,
                duration: duration ? duration : '-',
            });
        })

    }

    const handleSubmit = async () => {
        if (userData.status === 'current') {
            setUserData({ ...userData, end_date: '' });
        }

        let user_id = id;
        let row_id = userData.id;
        let jamiat_name = userData.jamiat_name;
        let status = userData.status;
        let start_date = userData.start_date;
        let end_date = userData.end_date;
        const res = await promiseToast(dispatch(saveModifyJamiatInfo({ row_id, user_id, jamiat_name, status, start_date, end_date })));
        if (!res.error) {
            employees = [];
            employees = res?.data.map((item) => {
                return {
                    j_res_id: item.j_res_id,
                    status: item.status,
                    jamiat_name: item.jamiat_name,
                    start_date: item.start_date,
                    end_date: item.end_date,
                }
            });
            setData(employees)
            CloseRef.current.click();
        }
    }
    return (<>
        <div className="modal fade section-modal" id="jamiatResp" tabIndex="-1" aria-labelledby="jamiatRespLabel" data-bs-keyboard="false" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content" style={{ position: 'relative' }}>
                    <div className="modal-header" style={{ position: 'absolute', top: '0', visibility: 'hidden' }}>
                        <Button Ref={CloseRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></Button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-7">
                                <label htmlFor="JamiatDropdown" style={{ margin: '0 0 5px 12px' }}>Jamiat</label>
                                <div className="dropdown">
                                    <Button className="dropdown-toggle modal-dropdown" aria-label="jamiat-name" id="JamiatDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                        <span className="ellipsis pe-2">{initOpt?.find(op => op.value == userData?.jamiat_name)?.label || "Select Jamiat"}</span>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="13" viewBox="0 0 320 512">
                                                <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" />
                                            </svg>
                                        </span>
                                    </Button>
                                    <div className="dropdown-menu" aria-labelledby="jamiat_nameDropdown">
                                        {initOpt?.map(option => (
                                            <Button aria-label="label" className={`dropdown-item ${userData.jamiat_name === option.value ? 'selected-item' : ''}`} key={option.value} onClick={() => setUserData({ ...userData, jamiat_name: option.value })}>
                                                {option.label}
                                            </Button>
                                        ))}
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-5">
                                <label htmlFor="statusDropdown" style={{ margin: '0 0 5px 12px' }}>Status</label>
                                <div className="dropdown">
                                    <Button className="dropdown-toggle modal-dropdown text-capitalize" aria-label="status" id="statusDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                        {userData.status ? userData.status : "Select Status"}
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="13" viewBox="0 0 320 512">
                                                <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" />
                                            </svg>
                                        </span>
                                    </Button>
                                    <div className="dropdown-menu" aria-labelledby="statusDropdown">
                                        {statusOpt.map(option => (
                                            <Button aria-label="label" className={`dropdown-item ${userData.status === option.value ? 'selected-item' : ''}`} key={option.value} onClick={() => setUserData({ ...userData, status: option.value })}>
                                                {option.label}
                                            </Button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {userData.status === 'current' || userData.status === 'past' ? <div className={`col-md-5 mt-4 offset-md-1 ${userData.status == "current" && 'mx-auto'}`}>
                                <DateControl label="Start Date" format="YYYY-MM-DD" placeholder='Start Date' value={userData.start_date} maxDate={new Date()} onChange={(e) => setUserData({ ...userData, start_date: e?.format() })} />
                            </div> : null}
                            {userData.status === 'past' && <div className="col-md-5 mt-4 ">
                                <DateControl label="End Date" format="YYYY-MM-DD" placeholder='End Date' value={userData.end_date} minDate={userData.start_date} onChange={(e) => setUserData({ ...userData, end_date: e?.format() })} disabled={userData.status === 'current' ? true : false} />
                            </div>}
                        </div>
                        <div className="col-md-4 mt-4 offset-md-4 text-center">
                            <PrimaryButton type={'button'} onClick={handleSubmit}>Submit Data</PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </div >
        <div className="tab-section-container" id={'home856'}>
            <div className="action-button d-flex align-items-center justify-content-between horizontal-p-20">
                <h2 className='section-name'>jamiat responsibility</h2> <PrimaryButton textTransform={'capitalize'} aria-label="add" data-bs-toggle="modal" data-bs-target="#jamiatResp" onClick={() => setUserData({ id: '', jamiat_name: '', status: '', start_date: '', end_date: '' })}>Add +</PrimaryButton>
            </div>
            <DataTableComponent
                columns={columns}
                style={{ overflowX: 'auto' }}
                data={employees}
                progressPending={false}
            />


        </div>
    </>

    )
}

export default JamiatResp