import React from 'react'
import { CustomInput } from '../../components'

const EducationalDetails = ({ data, setData, userType }) => {

    return (
        <div className="personal-sec">
            <h2 className='section-name'>Education Details</h2>
            <div className="row mt-2">
                
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Qualification'} name='qualification' value={data.qualification} onChange={(e)=>setData({...data,qualification:e.target.value})} placeholder='Enter Qualification' disabled={ userType != "Other Community" ? true : false} /></div>
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Occupation'} name='occupation' value={data.occupation} onChange={(e)=>setData({...data,occupation:e.target.value})} placeholder='Enter Occupation' disabled={ userType != "Other Community" ? true : false} /></div>
                { userType != "Other Community" && <><div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Hifz Degree'} name='hifz_degree' value={data.hifz_degree} placeholder='Enter Hifz Degree' disabled /></div>
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Hifz Degree Year'} name='hifz_degree_year' value={data.hifz_degree_year} placeholder='Enter Hifz Degree Year' disabled /></div>
              
                <div className="col-lg-3 col-lg-3 col-md-3 mt-3"><CustomInput label={'Jamea Standard'} name='jamea_standard' value={data.jamea_standard} placeholder='Enter Jamea Standard' disabled /></div>
               
                <div className="col-md-3 mt-3"><CustomInput label={'Jamea Degree'} name='jamea_degree' value={data.jamea_degree} placeholder='Enter Jamea Degree' disabled /></div>
                <div className="col-md-3 mt-3"><CustomInput label={'Farig Year'} name='farig_year' value={data.farig_year} placeholder='Enter Farig Year' disabled /></div>
              
                <div className="col-md-3 mt-3"><CustomInput label={'TR No'} name='tr_no' value={data.tr_no} placeholder='Enter TR No' disabled /></div></> }
                
            </div>
        </div>)
}

export default EducationalDetails