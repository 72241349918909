import * as React from "react"
const WikiIcon = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  xmlSpace="preserve"
  id="Layer_1"
  width={props.width||20}
  height={props.height||20}
  style={{
    enableBackground: "new 0 0 150 150",
  }}
    className="wiki"
    stroke={props.color||'#898989'}
    viewBox="0 0 682.667 682.667"
    {...props}
  >
    <defs>
      <clipPath id="a" clipPathUnits="userSpaceOnUse">
        <path d="M0 512h512V0H0Z" />
      </clipPath>
      <style>{".wiki{fill:#ac1c30}"}</style>
    </defs>
    <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
      <path
        d="M0 0h-253.487c-14.576 0-26.394-11.816-26.394-26.393v-363.773c0-14.577 11.818-26.394 26.394-26.394H7.975c14.576 0 26.393 11.817 26.393 26.394v223.896"
       className="wiki"
        style={{
          fill: "none",
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(317.232 480.353)"
      />
      <path
        d="M284.575 359.146H104.377v42.234h180.198z"
       className="wiki"
        style={{
          fill: "none",
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
      <path
        d="M0 0h180.198"
       className="wiki"
        style={{
          fill: "none",
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(104.377 304.367)"
      />
      <path
        d="M0 0h180.198"
       className="wiki"
        style={{
          fill: "none",
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(104.377 256.938)"
      />
      <path
        d="M0 0h180.198"
       className="wiki"
        style={{
          fill: "none",
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(104.377 209.507)"
      />
      <path
        d="M0 0h180.198"
       className="wiki"
        style={{
          fill: "none",
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(104.377 162.077)"
      />
      <path
        d="M0 0c0-52.498-42.55-95.057-95.037-95.057-52.487 0-95.036 42.559-95.036 95.057 0 52.498 42.549 95.057 95.036 95.057S0 52.498 0 0Z"
       className="wiki"
        style={{
          fill: "none",
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(474.648 406.943)"
      />
      <path
        d="M0 0v-68.379"
       className="wiki"
        style={{
          fill: "none",
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(379.655 420.044)"
      />
      <path
        d="M0 0c0-5.522-4.477-10-10-10-5.522 0-10 4.478-10 10 0 5.523 4.478 10 10 10C-4.477 10 0 5.523 0 0"
       className="wiki"
        style={{
          fill: "#000",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(389.611 452.222)"
      />
      <path
        d="M0 0v-50.793"
       className="wiki"
        style={{
          fill: "none",
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(419.724 316.3)"
      />
      <path
        d="M0 0v-138.702c0-14.577-11.816-26.394-26.394-26.394h-261.46c-14.578 0-26.394 11.817-26.394 26.394v24.4"
       className="wiki"
        style={{
          fill: "none",
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(419.724 175.096)"
      />
      <path
        d="M0 0v0"
       className="wiki"
        style={{
          fill: "none",
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(419.724 220.484)"
      />
    </g>
  </svg>
)
export default WikiIcon
