import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { UnVerifiedIcon, VerifyIcon } from "../../assets/images/icons";
import { verifyUser } from '../../store/emp/empAction';
import { promiseToast } from "../../utils/Toast";
import "./login.css";

const Verified = () => {
	const dispatch = useDispatch();
	const ref = useRef(null)
	const { token } = useParams();

	const [emailText, setEmailText] = useState({
		title: "Email verification is under process.",
		msg: "",
		verified: null,
	});

	const verify_User = useCallback(async () => {
		let res = await promiseToast(dispatch(verifyUser({ email_verify: 'yes', token })), "Validating...", "Email verify successfully!");
		if (res?.error) {
			setEmailText({ title: "Email not verified!", msg: "Your email address not verified.", verified: false, });
		} else {
			setEmailText({ title: "Email verified!", msg: "Your email address has been successfully verified.", verified: true, });
			setTimeout(() => {
				return ref.current.click()
			}, 3000);
		}
	}, [dispatch, token]);

	useEffect(() => {
		verify_User();
	}, [verify_User]);

	return (
		<div className="login-wrapper">
			<div className="login-outer" style={{ height: '400px', minHeight: '200px' }}>
				<div className="login-inner text-center  align-align-items-center justify-content-center h-100 d-flex-column " style={{ marginTop: '100px' }}>
					<div className="login-logo">
						{emailText.verified === null ? null : emailText.verified ? <VerifyIcon height="100" width="100px" /> : <UnVerifiedIcon height="100" width="100px" />}
					</div>
					<span>{emailText.title}</span>
					<p>{emailText.msg}</p>
				</div>
				<NavLink to={'/'} className="d-none" ref={ref} >Back To Login</NavLink>

			</div>
		</div>
	)
}

export default Verified