import React from 'react'
import Select from 'react-select'
import { color } from '../../assets/constant';

const Dropdown = ({ options, placeholder, width, height, gap, padding, ...props }) => {
    const findOptionByValue = () => {
        let setValue = props.set_value;

        if (setValue && typeof setValue === 'string') {
            if (setValue.includes(',')) {
                let allValues = setValue.split(",");
                if (allValues.length > 0) {
                    return allValues.map((item) => options.find(option => option.value == item));
                }
            } else {
                return options.find(option => option.value == setValue);
            }
        }
        else {
            return options.find(option => option.value == setValue);
        }

        return null;
    };



    let SetvalueData = findOptionByValue();

    const customStyles = {
        menu: provided => ({ ...provided, zIndex: 9999999 }),
        control: (provided) => ({
            ...provided,
            width: width || 150,
            height: height || 42,
            textAlign: 'center',
            borderRadius: '20px',
            boxShadow: '#f8f8f8',
            textTransform: 'capitalize',
            color: "#000",
            borderColor: "transparent",
            cursor: 'pointer',
            justifyContent: 'space-between',
            gap: gap || '20px',
            padding: '0 16px 0 2px',
            position: 'relative',
            '&:hover': {
                borderColor: 'none'
            },
        }),
        option: (provided, state) => ({
            ...provided,
            fontWeight: '500',
            fontSize: '13px',
            width: width || '150px',
            height: '40px',
            backgroundColor: state.isSelected ? color.colorPrimary : 'white',
            color: state.isSelected ? '#fff' : '#000',
            '&:active': {
                background: color.colorPrimary,
                color: '#f00'
            },
            '&:hover': {
                background: color.colorPrimary,
                color: '#fff',
                cursor: 'pointer'
            }

        }),
        singleValue: (provided) => ({
            ...provided,
            fontWeight: '500',
        }),
        
    };

    return (
        <Select
            className='user-access-dropdown'
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" height="20" width="13" viewBox="0 0 320 512"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" /></svg>
            }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            styles={customStyles}
            menuClassName={'custom-menu-styles'}
            placeholder={placeholder || "All Modules"}
            options={options?.map((option) => ({
                ...option,
            }))}
            isSearchable={false}
            value={SetvalueData}
            // menuIsOpen
            {...props}
        />
    )
}

export default Dropdown