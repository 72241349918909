import React from 'react'
import { CustomInput, Dropdown } from '../../components'

const Achievements = ({data,setData,mastermodule}) => {
    let initiveOpt = []
    if (mastermodule.tagged_initiative) {
        mastermodule.tagged_initiative.map((data) => {
            initiveOpt.push({ value: data.id, label: data.title });
        })
    }
    let achiveOpt = []
    if (mastermodule.achievement_type) {
        mastermodule.achievement_type.map((data) => {
            achiveOpt.push({ value: data.id, label: data.title });
        })
    }
    return (
        <div className="personal-sec">
            <h2 className='section-name'>achievements</h2>
            <div className="row mt-2">

                <div className="col-md-4 mt-3 "><label htmlFor="achievement_type" style={{ margin: '0 0 5px 12px' }}>Achievement Type</label><Dropdown options={achiveOpt} onChange={(e) => setData({ ...data, achievement_type: e.value })} placeholder="Achievement Type" gap={'0px'} padding={'0 10px'} width={'100%'} set_value={data.achievement_type} /></div>
                <div className="col-md-4 mt-3 "><label htmlFor="tagged_initiative" style={{ margin: '0 0 5px 12px' }}>Tagged Initiative</label><Dropdown options={initiveOpt} onChange={(e) => setData({ ...data, tagged_initiative: (e.map(e => e.value).toString() ) })} placeholder="Tagged Initiative" isMulti gap={'0px'} padding={'0 10px'} width={'100%'} set_value={data.tagged_initiative} /></div>
                <div className="col-md-4 mt-3"><CustomInput label={'Remarks'} name='remarks' value={data.remarks} onChange={(e) => setData({ ...data, remarks: e.target.value })} placeholder='Enter Remarks' /></div>
            </div>
        </div>)
}

export default Achievements