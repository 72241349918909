import React, { useCallback, useEffect, useState } from 'react'
import PdfView from './PdfView'
import './pdfpreview.css'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getEmpInfo } from '../../store/emp/empAction'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import SinglePdfView from './SinglePdfView'
import { PrimaryButton } from '../../components'
import moment from 'moment'; 

const SinglePdfPreview = () => {
  const [data, setData] = useState()
  const { user } = useSelector(state => state.auth);
  const [masterData,setMasterData]=useState({})

  const { id } = useParams()
  const dispatch = useDispatch()
  const fetchEmpInfo = useCallback(async () => {
    let login_user_id = user?.id;
    const retrun_data = await dispatch(getEmpInfo({ id, login_user_id }));
    const master_module_details = retrun_data?.payload?.master_module;
    setMasterData(master_module_details);
    const emp_details = retrun_data?.payload?.data;
    if (emp_details) setData(emp_details)
  }, [dispatch, id])
  useEffect(() => { fetchEmpInfo() }, [])
  return (
 <div className='mt-3'>
  {/* <PDFViewer style={{height:'600px',width:'100%'}}>
      <PdfView data={data} masterData={masterData} />
  </PDFViewer> */}
  { data?.user_info?.name &&
    <PDFDownloadLink className='pdf-down-link' document={<PdfView data={data} masterData={masterData} />} fileName={`${ data?.user_info?.name.replaceAll(" ", "_")}_${moment().format("DD_MM_YYYY_HH_mm")}.pdf`} >
        {({ blob, url, loading, error }) =>
          loading ? <PrimaryButton >Loading</PrimaryButton> : <PrimaryButton >Download PDF</PrimaryButton>
        }
      </PDFDownloadLink>
  }
 <SinglePdfView data={data} masterData={masterData} />
 </div>
  )
}

export default SinglePdfPreview