import React, { useMemo } from 'react';
import Select from 'react-select';
import { color } from '../../assets/constant';

const customStyles = (width, control) => ({
    control: (provided) => ({
        ...provided,
        width: width || '70px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '20px',
        boxShadow: '#f8f8f8',
        color: "#000",
        height: '40px',
        borderColor: "transparent",
        padding: "0 5px",
        cursor: 'pointer',
        '&:hover': {
            borderColor: color.colorPrimary
        },
        ...control
    }),

    option: (provided, state) => ({
        ...provided,
        fontWeight: '500',
        fontSize: '13px',
        width: '100%',
        whiteSpace: 'nowrap',
        backgroundColor: state.isSelected ? color.colorPrimary : 'white',
        color: state.isSelected ? '#fff' : '#000',
        '&:active': {
            background: color.colorPrimary,
            color: '#f00'
        },
        '&:hover': {
            background: color.colorPrimary,
            color: '#fff',
            cursor: 'pointer'
        }
    }),
    singleValue: (provided) => ({
        ...provided,
        fontWeight: '500',
    }),
    menu: provided => ({
        ...provided,
        minWidth: width || '70px',
        width: 'fit-content', // Set the width to 100%
    }),
})
const CustomSelect = ({
    option = [],
    width,
    name,
    inputRef,
    className = "",
    menuPosition = 'fixed',
    menuClassName = 'custom-menu-styles',
    isSearchable = false,
    control = {},
    styles = customStyles(width, control),
    IndicatorSeparator = () => null,
    DropdownIndicator = () => null,
    ...props
}) => {


    return (
        <div className="parent-control d-flex align-items-center" style={{ width: width || 'fit-content' }}>
            <Select
                ref={inputRef}
                options={option}

                aria-label='filter'
                isClearable={false}
                isSearchable={false}

                className={className}
                styles={styles}

                menuPosition={menuPosition}
                menuPortalTarget={document.body}
                menuClassName={'custom-menu-styles'}

                components={{
                    IndicatorSeparator: IndicatorSeparator,
                    DropdownIndicator: DropdownIndicator
                }}
            //    {value && (value={(e) => e})}
                {...props}
            />
        </div>
    );
};

export default CustomSelect;
