import { useAccessControl } from "../../hooks/useAccessControl";
import { NotFound } from "../../pages";

const HasAccess = ({
    children,
    permissions,
    renderAuthFailed,
    role,
    access
}) => {
    const { checkRoleAccess } = useAccessControl();
    if (permissions === 'dashboard') return children;

    const hasRequiredPermission = checkRoleAccess(role || [], permissions ? [permissions] : [], access)
    if (hasRequiredPermission) return children;
    return renderAuthFailed || <NotFound />;
};

export default HasAccess;
