import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Dropdown, PrimaryButton } from '../../components';
import GoBack from '../../components/controls/GoBack';
import { FetchHRRole, saveEmp } from '../../store/emp/empAction';
import { errorToast, promiseToast } from '../../utils/Toast';
import "./addemp.css";
import PhoneInput from 'react-phone-number-input';
let options = [];

const AddUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [user_type, setEmpType] = useState(null);
    const [its_no, setIts_number] = useState(null);
    const [name, setFull_name] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [emp_role, setRole] = useState(null);

    const [emp_wrapper, setEmpWrapper] = useState('');
    const [itr_wrapper, setItrWrapper] = useState('d-none');
    const [other_emp_wrapper, setOtehrWrapper] = useState('d-none');
    const [role_wrapper, setRoleWrapper] = useState('d-none');

    const buttonClick = async (e) => {
        e.preventDefault();
        if (!user_type) return errorToast('Please select User Type')
        setEmpWrapper('d-none');
        setRoleWrapper('');
    };

    const rolebuttonClick = async (e) => {
        e.preventDefault();
        if (!emp_role) return errorToast('Please select User Role');
        setRoleWrapper('d-none');

        switch (user_type) {
            case "Mumin":
                setItrWrapper('');
                setOtehrWrapper('d-none');
                break;
            case "Other Community":
                setItrWrapper('d-none');
                setOtehrWrapper('');
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let res = await promiseToast(dispatch(saveEmp({ user_type, name, email, phone, country, its_no, emp_role })), "Validating...");
        if (!res.error) return navigate("/human-resources");
    };

    const fetchRoleData = useCallback(async () => {
        const retrun_data = await dispatch(FetchHRRole());
        const active_data = retrun_data?.payload?.data;
        options = [];
        active_data?.forEach(function (item) {
            options.push({ value: item.id, label: item.title });
        });

    }, [dispatch]);

    useEffect(() => {
        fetchRoleData();
    }, [fetchRoleData]);





    return (
        <div className="full-container addemp-wrapper">
            <div className="custom-container">
                <div className="back-button">
                    <GoBack to={'/human-resources'} />
                </div>
                <span className='main-heading heading-both'>Add Employee</span>

                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className={`col-md-12 ${emp_wrapper}`} id="employee_type">
                            <p className='my-3'>Select Employee Type</p>
                            <div className='custom-d-flex mb-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" onChange={() => setEmpType('Mumin')} checked={user_type === 'Mumin'} name="flexRadioDefault" id="mumin_type" />
                                    <label className="form-check-label" htmlFor="mumin_type"> Mumin </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" onChange={() => setEmpType("Other Community")} checked={user_type === "Other Community"} name="flexRadioDefault" id="other_type" />
                                    <label className="form-check-label" htmlFor="other_type"> Other Community </label>
                                </div>
                            </div>
                            <PrimaryButton background={'transparent'} height={'40px'} onClick={buttonClick}>Next Step</PrimaryButton>
                        </div>

                        {/* Role Section */}
                        <div className={`col-md-12 ${role_wrapper}`} id="its_number_wrapper">
                            <p className='my-3'>Select Role</p>
                            <div className='mb-3'>
                                <div className="form-group">
                                    <Dropdown options={options} width="300px" placeholder="Select Role" onChange={(e) => setRole(e.value)} />
                                </div>
                            </div>
                            <PrimaryButton background={'transparent'} height={'40px'} onClick={rolebuttonClick}>Next Step</PrimaryButton>
                        </div>

                        {/* ITS Number Section */}
                        <div className={`col-md-12 ${itr_wrapper}`} id="its_number_wrapper">
                            <p className='my-3'>Since you have chosen the "Mumin" option, please enter the corresponding ITS number to retrieve the associated data.</p>
                            <div className='mb-3'>
                                <div className="form-group">
                                    <input className="form-input_field" type="text" onChange={(e) => setIts_number(e.target.value)} placeholder="Type ITS Number" />
                                </div>
                            </div>
                            <PrimaryButton type="submit" background={'transparent'} height={'40px'}>Fetch Data</PrimaryButton>
                        </div>

                        {/* Other Community Section */}
                        <div className={`col-md-12 ${other_emp_wrapper}`} id="its_number_wrapper">
                            <p className='my-3'>Since you have chosen the "Other Community" option, please enter the User details.</p>
                            <div className='mb-3 row'>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group float-right">
                                        <label htmlFor='name'>Full Name</label>
                                        <input id="name" name="name" className="form-input_field" type="text" onChange={(e) => setFull_name(e.target.value)} placeholder="Type Full Name" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group float-left">
                                        <label htmlFor='email'>Email Id</label>
                                        <input id="email" name="email" className="form-input_field" type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Type Email Id" />
                                    </div>
                                </div>
                                <div className="phone-input">
                                    <div className="form-group mobile_no">
                                        <label htmlFor='phone'>Contact number</label>

                                        <PhoneInput
                                            rules={{ required: true }}
                                            defaultCountry={"IN"}
                                            placeholder="Enter phone number"
                                            focusInputOnCountrySelection
                                            value={phone}
                                            onCountryChange={setCountry}
                                            onChange={setPhone}
                                            international
                                            countryCallingCodeEditable={false}
                                        />
                                    </div>
                                </div>
                                {/* {phone && (
                                    <div className="validation">
                                        <p>Phone Number: {phone}</p>
                                    </div>
                                )} */}
                            </div>
                            <PrimaryButton type="submit" background={'transparent'} height={'40px'} >Submit Data</PrimaryButton>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddUser