import * as React from "react"
const Info = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			enableBackground: "new 0 0 512 512",
		}}
		viewBox="0 0 512 512"
		{...props}
	>
		<path d="M290.211 360.727a24.896 24.896 0 0 1-15.011-3.375 15.593 15.593 0 0 1-4.305-12.8 66.49 66.49 0 0 1 1.396-11.636c.781-4.394 1.79-8.744 3.025-13.033l13.731-47.244a71.535 71.535 0 0 0 2.793-14.313c0-5.236.698-8.844.698-10.938a30.602 30.602 0 0 0-10.822-24.32 45.5 45.5 0 0 0-30.72-9.309 79.72 79.72 0 0 0-23.273 3.84c-8.223 2.56-16.873 5.624-25.949 9.193l-3.956 15.36c2.676-.931 5.935-1.978 9.658-3.142a39.572 39.572 0 0 1 10.938-1.629 22.343 22.343 0 0 1 14.778 3.607 16.875 16.875 0 0 1 3.84 12.684 55.19 55.19 0 0 1-1.28 11.636c-.815 4.073-1.862 8.378-3.142 12.916L218.763 325.7a135.651 135.651 0 0 0-2.676 13.382 83.118 83.118 0 0 0-.815 11.636 30.369 30.369 0 0 0 11.636 24.087 46.55 46.55 0 0 0 31.185 9.542 71.78 71.78 0 0 0 23.273-3.375c6.594-2.25 15.399-5.469 26.415-9.658l3.724-14.662a64.283 64.283 0 0 1-9.193 3.025 44.19 44.19 0 0 1-12.101 1.05zM304.756 136.727A33.163 33.163 0 0 0 281.483 128a33.396 33.396 0 0 0-23.273 8.727c-11.632 10.03-12.931 27.591-2.9 39.224a27.762 27.762 0 0 0 2.9 2.9c13.252 11.853 33.294 11.853 46.545 0 11.632-10.129 12.851-27.769 2.722-39.401a27.754 27.754 0 0 0-2.721-2.723z" />
		<path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256S397.385 0 256 0zm0 488.727C127.468 488.727 23.273 384.532 23.273 256S127.468 23.273 256 23.273 488.727 127.468 488.727 256 384.532 488.727 256 488.727z" />
	</svg>
)
export default Info
