import * as React from "react"
const EngIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 74"  
  width={props.width ||20}
  height={props.height ||20}
   {...props}>
    <path d="M70 24c0-.81-.46-.7-4.99-4.7-.16-.14-.4-.23-.61-.24-15.45-.65-23.47-.11-27.4 4.63-3.93-4.74-11.95-5.29-27.41-4.64-.65.03-.76.48-5.24 4.18-.67.57-.22-1.27-.35 41.84 0 3.87 62.52 2.25 65.42.91.93-.68.49 2.61.58-41.98zM10.02 62.12c13.42-.55 21.09-.16 24.8 3.26l-27.23-1.2zm53.96 0 2.43 2.07-27.23 1.2c3.7-3.43 11.38-3.82 24.8-3.27zm4.02.79-2.64-2.24V22.22L68 24.46zm-4.64-41.89v39.07c-13.58-.53-21.23-.05-25.36 3.63V25.69c3.06-4.64 10.71-5.25 25.36-4.67zM36 25.69v38.03c-4.93-4.4-15.98-3.99-25.36-3.63V21.02c14.65-.58 22.3.03 25.36 4.67zM6 24.46l2.64-2.24v38.45L6 62.91zM18.85 16.31h31.87c.51 0 .61-.26 7.67-4a1 1 0 0 0 0-1.76c-7.7-4.19-7.32-4.04-7.67-4.04H18.85c-2.07 0-3.76 1.69-3.76 3.76v2.28c0 2.08 1.69 3.76 3.76 3.76zm30.86-2H24.98v-5.8h24.73zm2-.67V9.19l4.1 2.23zM18.85 8.51h4.13v5.8h-4.13c-.97 0-1.76-.79-1.76-1.76.14-.72-.7-4.04 1.76-4.04z" />
    <path d="M18.48 44.67c10.48 0 12.76 1.35 13.52 1.35 1.15 0 1.38-1.65.27-1.96-5.2-1.46-12.09-1.47-17.66-1.33-.55.01-.99.47-.98 1.02.04 1.34 1.37.92 4.85.92zM18.48 50.79c13.65 0 13.93 2.57 14.49.61a.997.997 0 0 0-.69-1.23c-5.2-1.46-12.09-1.47-17.66-1.33-.55.01-.99.47-.98 1.02.03 1.35 1.36.93 4.84.93zM18.48 56.91c13.65 0 13.93 2.57 14.49.61a.997.997 0 0 0-.69-1.23c-5.2-1.46-12.09-1.47-17.66-1.33-.55.01-.99.47-.98 1.02.03 1.34 1.36.93 4.84.93zM42.27 45.98c4.97-1.39 11.74-1.38 17.07-1.26.53.03 1.01-.42 1.02-.98a.997.997 0 0 0-.98-1.02c-5.56-.13-12.48-.12-17.66 1.33-1.27.36-.72 2.29.55 1.93zM42.27 52.1c4.97-1.39 11.74-1.38 17.07-1.26 1.29.06 1.4-1.97.05-2-5.56-.13-12.48-.12-17.66 1.33-1.28.36-.73 2.29.54 1.93zM42.27 58.22c4.97-1.39 11.74-1.38 17.07-1.26 1.3.05 1.39-1.97.05-2-5.56-.13-12.48-.12-17.66 1.33-1.28.36-.73 2.29.54 1.93zM24.89 30.19c-.21.67-.62 1.61-1.47 2.64-.7-1.04-.95-2.43-2.11-1.92-1.24.56-.18 2.17.67 3.35-2.58 2.16-4.34 2.01-3.67 3.38.24.49.84.7 1.34.46 1.52-.74 2.71-1.54 3.68-2.36 1.47 1.3 3.25 1.84 3.73 1.84 1.19 0 1.38-1.72.22-1.98a5.877 5.877 0 0 1-2.52-1.26c1.42-1.64 1.98-3.18 2.2-4.14.73-.12 2.71.46 2.71-1 0-1.66-2.45-.76-5.34-1-.09-.48.4-2.34-1-2.34-1.42 0-.91 1.87-1 2.34-2.9.24-5.34-.65-5.34 1-.02.54 2.48.68 7.9.99zM46.56 37.67l1.8-3.47h4.67c1.58 2.75 1.8 4.59 3.13 3.91.49-.25.69-.85.43-1.35-5.12-9.56-4.77-10.28-5.88-10.28-.1.03-.61-.01-.89.54l-5.03 9.74c-.62 1.16 1.16 2.08 1.77.91zm4.14-8.02 1.3 2.54h-2.61z" />
  </svg>
)
export default EngIcon
