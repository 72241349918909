import React from 'react'
import { CustomInput, DateControl, Dropdown } from '../../components'
const ProjectWorked = ({ data, setData, mastermodule }) => {
    let initiveOpt = []
    if (mastermodule.tagged_initiative) {
        mastermodule.tagged_initiative.forEach((data) => {
            initiveOpt.push({ value: data.id, label: data.title });
        })
    }
    return (
        <div className="personal-sec">
            <h2 className='section-name'>Project Worked on</h2>
            <div className="row mt-2">

                <div className="col-lg-4 col-md-4 mt-3"><CustomInput label={'Project Name'} name='project_name' value={data.project_name} onChange={(e) => setData({ ...data, project_name: e.target.value })} placeholder='Enter Project Name' /></div>
                <div className="col-lg-4 col-md-4 mt-3 "><label htmlFor="tagged_initiative" style={{ margin: '0 0 5px 12px' }}>Tagged Initiative</label><Dropdown options={initiveOpt} onChange={(e) => setData({ ...data, tagged_initiative: (e.map(e => e.value)).toString() })} placeholder="Tagged Initiative" isMulti gap={'0px'} padding={'0 10px'} width={'100%'} set_value={data.tagged_initiative} /></div>
                <div className="col-lg-4 col-md-4 mt-3"><CustomInput label={'No of hours'} name='no_of_hours' value={data.no_of_hours} onChange={(e) => setData({ ...data, no_of_hours: e.target.value })} placeholder='Enter No of hours' /></div>
                <div className="col-lg-4 col-md-4 mt-3"><DateControl placeholder='Start Date' id='start_date' label={'Start Date'} format="YYYY-MM-DD" value={data.start_date} onChange={(e) => setData({ ...data, start_date: e?.format() })} maxDate={new window.Date()} /></div>
                <div className="col-lg-4 col-md-4 mt-3"> <DateControl placeholder='End Date' id='end_date' label={'End Date'} minDate={data.start_date} format="YYYY-MM-DD" value={data.end_date} onChange={(e) => setData({ ...data, end_date: e?.format() })} /></div>

            </div>
        </div>)
}

export default ProjectWorked