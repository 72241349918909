import React from 'react';

export default function ProjectIcon(props) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        width={props.width || 20}
        height={props.height || 20}
        style={{
          enableBackground: "new 0 0 150 150",
        }}
        className="wiki"
        stroke={props.color || '#898989'}
      >
        <defs id="defs1591">
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1601">
            <path d="M 0,512 H 512 V 0 H 0 Z" id="path1599" />
          </clipPath>
          <style>{".wiki{fill:#ac1c30}"}</style>
        </defs>
        <mask id="custom"><rect id="bg" x="0" y="0" width="100%" height="100%" fill="white" /><g transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)" /></mask><g mask="url(#custom)"><g id="g1593" transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
          <g id="g1595">
            <g id="g1597" clipPath="url(#clipPath1601)">
              <g id="g1603" transform="translate(497.5971,146.9995)">
                <path d="m 0,0 h -123.968 c -3.788,0 -6.887,-3.099 -6.887,-6.887 v -94.698 c 0,-3.788 3.099,-6.887 6.887,-6.887 H 0 c 3.788,0 6.887,3.099 6.887,6.887 V -6.887 C 6.887,-3.099 3.788,0 0,0 Z" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1605" />
              </g>
              <g id="g1607" transform="translate(138.3711,146.9995)">
                <path d="m 0,0 h -123.968 c -3.788,0 -6.887,-3.099 -6.887,-6.887 v -94.698 c 0,-3.788 3.099,-6.887 6.887,-6.887 H 0 c 3.788,0 6.887,3.099 6.887,6.887 V -6.887 C 6.887,-3.099 3.788,0 0,0 Z" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1609" />
              </g>
              <g id="g1611" transform="translate(169.3633,425.4878)">
                <path d="M 0,0 H 173.273" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1613" />
              </g>
              <g id="g1615" transform="translate(169.3633,390.9155)">
                <path d="M 0,0 H 173.273" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1617" />
              </g>
              <g id="g1619" transform="translate(169.3633,356.3428)">
                <path d="M 0,0 H 173.273" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1621" />
              </g>
              <g id="g1623" transform="translate(169.3633,321.77)">
                <path d="M 0,0 H 173.273" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1625" />
              </g>
              <g id="g1627" transform="translate(256,273.7852)">
                <path d="M 0,0 V -126.786" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1629" />
              </g>
              <g id="g1631" transform="translate(76.3867,146.9995)">
                <path d="m 0,0 v 61.632 c 0,3.696 2.996,6.691 6.691,6.691 h 345.844 c 3.695,0 6.692,-2.995 6.692,-6.691 V 0" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1633" />
              </g>
              <g id="g1635" transform="translate(416.3134,91.0361)">
                <path d="M 0,0 10.03,-10.565 38.6,17.381" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1637" />
              </g>
              <g id="g1639" transform="translate(236.7002,91.0361)">
                <path d="M 0,0 10.03,-10.565 38.6,17.381" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1641" />
              </g>
              <g id="g1643" transform="translate(57.0874,91.0361)">
                <path d="M 0,0 10.03,-10.565 38.599,17.381" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1645" />
              </g>
              <g id="g1647" transform="translate(273.5,38.5273)">
                <path d="m 0,0 h 44.484 c 3.788,0 6.887,3.099 6.887,6.887 v 94.698 c 0,3.788 -3.099,6.887 -6.887,6.887 H -79.484 c -3.788,0 -6.887,-3.099 -6.887,-6.887 V 6.887 C -86.371,3.099 -83.272,0 -79.484,0 H -35" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1649" />
              </g>
              <g id="g1651" transform="translate(382.7856,391.1289)">
                <path d="m 0,0 v 69.666 c 0,6.973 -5.705,12.678 -12.679,12.678 h -228.214 c -6.973,0 -12.678,-5.705 -12.678,-12.678 v -174.331 c 0,-6.973 5.705,-12.679 12.678,-12.679 h 228.214 c 6.974,0 12.679,5.706 12.679,12.679 L 0,-35" style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1 }} id="path1653" />
              </g>
            </g>
          </g>
        </g></g>
      </svg>
    </div>
  );
}