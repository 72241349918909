import React, { useRef } from 'react'
import { CalendarIcon } from '../../assets/images/icons'
import DatePicker from 'react-multi-date-picker'

const DateControl = ({ ...props }) => {
    const datePickerRef = useRef()
    return (<>
        <label htmlFor=""style={{marginLeft:'12px'}}>{props.label}</label>
        <div className="date-wrapper">
            <DatePicker
                arrow={false}
                weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                ref={datePickerRef}
                {...props}
            />
            <CalendarIcon className='cal-icon' height="20px" width="20px" onClick={() => datePickerRef.current.openCalendar()}/>
        </div>
    </>)
}

export default DateControl