import React from 'react';
import { CustomInput, Dropdown } from '../../components';
import { Roles } from '../../assets/constant';
import { useSelector } from 'react-redux';

const OfficeInfo = ({ data, setData, mastermodule }) => {
    const { role } = useSelector(state => state.auth.user)
    let respoOpt = [];
    let roleOpt = [];
    if (mastermodule?.responsibility) {
        mastermodule?.responsibility.forEach((item) => {
            respoOpt.push({ value: item.id, label: item.title });
        })
    }
    // if (mastermodule.role) {
    if (mastermodule?.role) {
        mastermodule?.role.forEach((item) => {
            roleOpt.push({ value: item.id, label: item.title });
        })
    }
    // if (mastermodule?.role) {
    //     roleOpt = mastermodule?.role?.find(e => e?.id === parseInt(data?.role))?.title
    // }
    return (
        <div className="personal-sec">
            <h2 className='section-name'>office info</h2>
            <div className="row mt-2">
                <div className="col-md-3 mt-3 "><label htmlFor="role" style={{ margin: '0 0 5px 12px' }}>Role</label><Dropdown options={roleOpt} onChange={(e) => setData({ ...data, role: e.value })} placeholder="Role" gap={'0px'} padding={'0 10px'} width={'100%'} set_value={data.role} disabled={role !== Roles.superAdmin} /></div>
                {/* <div className="col-md-3 mt-3"><CustomInput label={'Role'} name='role' defaultValue={roleOpt.find(e => e.value === data.role)} /></div> */}
                {/* <div className="col-md-4   mt-3"><CustomInput label={'Role'} name='role' value={roleOpt} disabled /></div> */}
                <div className="col-md-4   mt-3 "><label htmlFor="responsibility" style={{ margin: '0 0 5px 12px' }}>Responsibility</label><Dropdown options={respoOpt} onChange={(e) => setData({ ...data, responsibility: (e.map(e => e.value).toString()) })} placeholder="Responsibility" isMulti gap={'0px'} padding={'0 10px'} width={'100%'} set_value={data?.responsibility} /></div>
                <div className="col-md-4   mt-3"><CustomInput label={'Current Assigned Initiative'} name='current_assigned_initiative' value={data.current_assigned_initiative} onChange={(e) => setData({ ...data, current_assigned_initiative: e.target.value })} placeholder='Enter Current Assigned Initiative' /></div>
                <div className="col-md-4   mt-3"><CustomInput label={'Current assigned jamiat'} name='current_assigned_jamiat' value={data.current_assigned_jamiat} onChange={(e) => setData({ ...data, current_assigned_jamiat: e.target.value })} placeholder='Enter Current assigned jamiat' /></div>
            </div>
        </div>)
}

export default OfficeInfo