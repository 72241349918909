export const color = {
    colorPrimary: "#ac1c30",
    colorPrimaryLight: "#eac6cb",
    bgGray: "#f8f8f8",
    textGrayColor: "#898989",
    black: "#000",
    radius: " 30px",
    bgPending: "#f5a623",
    bgInactive: "#d77748",
    bgActive: "#0ea759",
    bgAdmin: "#44b678",
    bgUser: "#5999f7",
    bgStatusBox: "#f6e8e",
}

export const perm = {
    view: 'view',
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    excel: 'excel',
    pdf: 'pdf',
}

export const Roles = {
    superAdmin: 'super_admin',
    admin: 'admin',
    user: 'user'
}