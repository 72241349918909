import React from 'react'
import { CustomInput } from '../../components'

const ContactInfo = ({data,setData,userType }) => {
  
    const handleChange = e => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value })

    }
    return (
        <div className="personal-sec">
            <h2 className='section-name'>Contact Information</h2>
            <div className="row mt-2">
                
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'WhatsApp'} name='whats_app' value={data.whats_app} placeholder='Enter WhatsApp' onChange={handleChange} disabled={userType !== "Other Community" ? true : false} /></div>
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Email'} name='email' value={data.email} placeholder='Enter Email' disabled /></div>
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Phone'} name='phone' value={data.phone} placeholder='Enter Phone' disabled /></div>
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Type of House'}  name='type_of_house' value={data.type_of_house} onChange={handleChange} placeholder='Enter Type of House' /></div>
              
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Address'} name='address' value={data.address} onChange={handleChange} placeholder='Enter Address' /></div>
               
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Building'} name='building' value={data.building} onChange={handleChange} placeholder='Enter Building' /></div>
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Street'} name='street' value={data.street} onChange={handleChange} placeholder='Enter Street' /></div>
              
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'Area'} name='area' value={data.area} onChange={handleChange} placeholder='Enter Area' /></div>
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput label={'City'} name='city' value={data.city} onChange={handleChange} placeholder='Enter City' /></div>
              
                <div className="col-lg-3 col-md-3 mt-3"><CustomInput type={'number'} label={'Pincode'} name='pincode' value={data.pincode} onChange={handleChange} placeholder='Enter Pincode' /></div>

                
            </div>
        </div>)
}

export default ContactInfo