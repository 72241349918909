import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CustomInput, DataTableComponent, PrimaryButton } from '../../components';
import { BackIcon, EditIcon } from '../../assets/images/icons';
import { Link, NavLink, Outlet, useParams } from 'react-router-dom';
import { deleteModuleInfo, getModuleTypes, saveModifyModule } from '../../store/master_module/master_module_action';
import { useDispatch } from 'react-redux';
import GoBack from '../../components/controls/GoBack';
import './mastermodule.css'
import DeleteIcon from '../../assets/images/icons/DeleteIcon';
import { promiseToast } from '../../utils/Toast';
const MasterModule = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  let current_slug = window.location.pathname.split('/');
  let module_slug = current_slug[1] ? current_slug[1] : '';
  const fetchData = useCallback(async () => {
    const retrun_data = await dispatch(getModuleTypes({ module_slug }));
    const active_data = retrun_data?.payload?.data;
    setData(active_data);
  }, [dispatch, module_slug])

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const [activeSection, setActiveSection] = useState('section1');
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const sections = document.querySelectorAll('section');
  //     let found = false;

  //     sections.forEach((sec) => {
  //       const top = window.scrollY;
  //       const offset = sec.offsetTop - 150;
  //       const height = sec.offsetHeight;
  //       const id = sec.getAttribute('id');
  //       if (top >= offset && top < offset + height) {
  //         setActiveSection(id);
  //         found = true;
  //       }
  //     });
  //     if (!found) {
  //       setActiveSection(null);
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // const columns = [
  //   {
  //     key: "title",
  //     name: 'Title',
  //     cell: row => row.title,
  //     width: "50%"
  //   },
  //   {
  //     key: "action",
  //     name: 'Action',
  //     cell: row => {
  //       return (
  //         <>
  //           <Link to="/" className='action-icon' onClick={() => setModuleInfo({ id: row.id, title: row.title, type: row.module_type })} data-bs-toggle="modal" data-bs-target="#popup" ><EditIcon height={15} width={15} /></Link>
  //           <button type='button' className='action-icon' onClick={() => { if (window.confirm('Delete the item?')) { deleteData(row.id, row.module_type) } }} ><DeleteIcon height={15} width={15} /></button>
  //         </>
  //       )
  //     },
  //     width: "20%",
  //     style: { paddingRight: 0, justifyContent: 'flex-end' },
  //   },
  // ];
  // const [moduleinfo, setModuleInfo] = useState({ id: '', title: '', type: "" });

  // const { type } = useParams();
  // const ref = useRef(null)
  // let page_title = type.replace("-", " ");
  // let page_title = '';

  // const deleteData = async (id, type) => {
  //   const res = await promiseToast(dispatch(deleteModuleInfo({ module_type: type, module_slug, id })));
  //   if (!res.error) fetchData();
  // }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   let id = moduleinfo.id;
  //   let title = moduleinfo.title;
  //   const res = await promiseToast(dispatch(saveModifyModule({ module_type: moduleinfo.type, module_slug, title, id })));
  //   if (!res.error) fetchData();
  //   ref.current.click();
  // };

  return (

    <div className='profile-container2 master-module-container'>
      <header className='edit-prof-header'>

        <nav className='tab-nav'>
          {data?.map((item, i) => (
            <NavLink
              aria-label={item.title}
              to={'/human-resources/master-module/'+ item.link}
              key={i}
            >
              <button type='button' className='menu-tab'>{item.title}</button>
            </NavLink>
          ))}
        </nav>

      </header>

      <div >
        {/* <div className="profile-header">
          <div className="user-name">
            <GoBack to='/human-resources' height={30} width={30} />
            <h3 className="username">Master Module</h3>
          </div>
        </div>

        {data?.map((item, i) => {
          return (
            <section id={`section${i + 1}`} key={i} className='master-sections'>
              <div className="container-listing">
                <div className="">
                  <div className='resource-header'>
                    <div className="modulename d-flex gap-3">
                      <h3 style={{ fontSize: '20px', color: 'var(--color-primary)', fontWeight: 'bold' }}>{item.title}</h3>
                    </div>
                    <div className="filter-section">
                      <PrimaryButton onClick={() => setModuleInfo({ id: '', title: '', type: item.link })} data-bs-toggle="modal" data-bs-target="#popup" textTransform={'capitalize'} background={'#fff'} height={'35px'}>Add +</PrimaryButton>
                    </div>
                  </div>
                </div>
                <div className='resource-body'>
                  <div className="resource-body">
                    <DataTableComponent
                      columns={columns}
                      pagination
                      style={{ overflowX: 'auto' }}
                      data={item.master_module_infos}
                      paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    // paginationPerPage = {1}
                    />
                  </div>
                </div>
              </div>
            </section>

          )
        })
        } */}
       <Outlet/>
      </div>

      {/* <div className="modal fade human-resources-modal" id="popup" tabIndex="-1" aria-labelledby="csvLabel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-capitalize">{page_title}</h5>
              <button type="button" ref={ref} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-12'>
                    <CustomInput type="text" value={moduleinfo.title} borderColor={'var(--color-primary)'} label="Title" onChange={(e) => setModuleInfo({ ...moduleinfo, title: e.target.value })} />
                  </div>
                  <div className='col-md-12 text-end mt-3'>
                    <PrimaryButton type="submit">
                      Submit
                    </PrimaryButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  )
}

export default MasterModule