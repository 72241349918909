import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import KanzalMarjaan from '../../assets/fonts/Kanz-al-Marjaan.ttf'
import Quicksand from '../../assets/fonts/Quicksand-Medium.ttf'
import Logo from '../../assets/images/loginLogo.png'
import FamilyCardPDF from './FamilyCardPdf';
import Before from './img/before.png'
import After from './img/after.png'
import Nouser from './img/no-user.png'

Font.register({ family: 'Kanz-al-Marjaan', src: KanzalMarjaan });
Font.register({ family: 'Quicksand', src: Quicksand });

const styles = StyleSheet.create({

  page: {
    // fontFamily: 'Quicksand',
    position: 'relative'
  },
  watermark: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 100,
    height: 100,
    opacity: 0.9,
  },
  columnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentPair: {
    paddingBottom: 5
  },
  imageRounded: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    borderWidth: 1,
    borderColor: '#ac1c30',
    borderStyle: 'solid',
  },
  imageRoundedNouser: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    borderWidth: 1,
    objectFit: 'cover',
    borderColor: '#ac1c30',
    borderStyle: 'solid',
  },
  headerStyle: {
    marginBottom: 10,
  },
  textStyle: {
    fontWeight: 700,
    fontFamily: 'Quicksand',
    fontSize: 8
  },
  textStyleSm: {
    fontWeight: 700,
    fontSize: 8,
    fontFamily: 'Quicksand',
  },
  containerRaunded: {
    display: 'flex',
    flexDirection: 'column',

  },
  pdfTopbar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  flexColumnStart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start'
  },
  bottomHeader: {
    width: '100%',
    display: 'flex',
    padding: 10,
    flexDirection: 'row',
    backgroundColor: '#eec7b8',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  topAddress: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    fontFamily: 'Quicksand',
  },
  nameSecWrapper: {
    display: 'flex',
    flexDirection: "row",
    gap: 10,
    alignItems: 'center',
    fontFamily: 'Quicksand',
  },
  nameSec: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start'
  },
  nameArabic: {
    fontSize: 14,
    fontFamily: 'Kanz-al-Marjaan',
    color: "#ac1c30",
    fontWeight: 900,
  },
  officeInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    fontSize: 8,
    color: "#ac1c30",
    marginRight: 5,
    fontFamily: 'Quicksand',
  },
  pdfBody: {
    padding: 10,
    paddingTop: 0,
    backgroundColor: '#fff'
  },
  twoSecBody: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  sidebar: {
    width: '35%',
  },
  commonBox: {
    width: '100%',
    display: 'block',
    backgroundColor: '#dbeff6',
    padding: 10,
    borderRadius: 10,
    marginBottom: 10
  },
  flexCenter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mainHeadingWrapper: {
    position: 'relative',
    marginBottom: 10,
    textAlign: 'center'
  },
  mainHeadingWrapperPersonal: {
    position: 'relative',
    marginBottom: 10,
    width: 150,
    textAlign: 'center'
  },
  mainHeadingWrapperBank: {
    position: 'relative',
    marginBottom: 10,
    width: 115,
    textAlign: 'center'
  },
  mainHeadingWrapperGovt: {
    position: 'relative',
    marginBottom: 10,
    width: 165,
    textAlign: 'center'
  },
  mainHeadingWrapperSkill: {
    position: 'relative',
    marginBottom: 10,
    width: 140,
    textAlign: 'center'
  },
  mainHeadingWrapperMawze: {
    position: 'relative',
    marginBottom: 10,
    width: 120,
    textAlign: 'center'
  },
  mainHeading: {
    display: 'block',
    color: '#ac1c30',
    fontSize: 12,
    fontWeight: 700,
    borderBottomColor: '#ac1c30',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingHorizontal: 10,
    paddingBottom: 2,
    fontFamily: 'Quicksand',
  },
  imgBefore: {
    position: 'absolute',
    bottom: -1.5,
    left: -10,
    width: 20,
  },
  imgAfter: {
    position: 'absolute',
    right: -10,
    bottom: -1.5,
    width: 20,
  },
  bodyRight: {
    float: 'left',
    width: '65%',
  },
  commonBoxBody: {
    // marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 5
  },
  image: {
    height: 75,
    width: 75,
    display: 'block'
  },
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#eec7b8',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10,
    overflow: 'hidden',
    marginBottom: 10
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    borderTopRightRadius: 10,
  },
  tableCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    borderColor: '#eec7b8',
  },
  tableCol2Round: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    borderColor: '#eec7b8',
    borderBottomRightRadius: 10,
  },
  tableCol3Round: {
    width: '33.333%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    borderColor: '#eec7b8',
    borderBottomRightRadius: 10,
    textAlign: 'center', display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableCol4Round: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    borderColor: '#eec7b8',
    borderBottomRightRadius: 10,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableColFull: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: '#eec7b8',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: 5
  },
  // Hifz Degree style 
  degreeTable: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: '#fff',
    paddingBottom: 5
  },
  degreeTableHeadRow: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#eec7b8',
    paddingHorizontal: 5
  },
  degreeTableRow: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#fff',
  },
  degreeTableColLeft: {
    width: '75%',
    borderStyle: 'solid',
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 5
  },
  degreeTableColRight: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 5,
    // borderLeftWidth:1, 
    // borderStyle: 'solid',
    // borderColor: "#c0c0c0",
  },

  borderBottomGray: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: "#c0c0c0",
  },
  borderBottomGrayMH10: {
    borderWidth: 0,
    borderBottomWidth: 0.4,
    borderStyle: 'solid',
    borderColor: "#c0c0c0",
    marginHorizontal: 5
  },
  // family sec 
  familySec: {
    display: 'flex',
    flexDirection: "row",
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 10
  },
  // family sec 
  // full width table 
  tableBank: {
    display: 'table',
    width: '80%',
    borderStyle: 'solid',
    borderColor: '#eec7b8',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10,
    overflow: 'hidden',
    marginBottom: 20
  },
  tableGovt: {
    display: 'table',
    width: '80%',
    borderStyle: 'solid',
    borderColor: '#eec7b8',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10,
    overflow: 'hidden',
    marginBottom: 20
  },
  skillTable: {
    display: 'table',
    borderWidth: 1,
    borderTopWidth: 0,
    width: '80%',
    borderStyle: 'solid',
    borderColor: '#eec7b8',
    margin: 'auto',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10,
    overflow: 'hidden',
    marginBottom: 20,
    position: 'relative',
  },

  skillHeadRow: {
    flexDirection: 'row',
    backgroundColor: '#eec7b8',
    paddingHorizontal: 5
  },
  skillTableCol: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    textAlign: 'center',
    borderColor: '#eec7b8',
    // borderRightColor:'#5b5b5b',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  borderLeftSm: {
    borderStyle: 'solid',
    borderWidth: 0,
    borderLeftWidth: 1,
    paddingLeft: 5,
    fontSize: 8,
    borderColor: '#c0c0c0',
  },
  borderLeftTextSm: {
    borderStyle: 'solid',
    borderWidth: 0,
    borderLeftWidth: 1,
    paddingLeft: 5,
    fontSize: 8,
    borderColor: '#c0c0c0',
    fontFamily: 'Quicksand',
  },
  mawzeTable: {
    display: 'table',
    borderWidth: 1,
    borderTopWidth: 0,
    width: '100%',
    borderStyle: 'solid',
    borderColor: '#eec7b8',
    margin: 'auto',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10,
    overflow: 'hidden'
  },
  mawzeHeadRow: {
    flexDirection: 'row',
    backgroundColor: '#eec7b8',
    paddingHorizontal: 5
  },
  mawzeTableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    textAlign: 'center',
    borderColor: '#eec7b8',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

});

const PdfView = ({ data, masterData }) => {
  const { user_info } = data || {};
  const { office } = data || {};
  const { education } = data || {};
  const { bank } = data || {};
  const { govt_docs } = data || {};
  const { mawze } = data || {};
  const { skill } = data || {};
  // const { user_info, office } = props.data || {};
  // const [prof, setProf] = useState()
  // let profImg;
  // const profRef = useRef(null)
  // const imageTo64 = async () => {
  //   try {
  //     const file = profile_pic;
  //     if (file) {
  //       const base64String = await imageToBase64(file);
  //       setProf(base64String)
  //     }
  //   } catch (error) {
  //     console.error('Error converting image to base64:', error);
  //   }
  // }

  // new code 
  // const { user_info } = data || {};
  const { its_no, whats_app, phone, email, address, city, arabic_name, name, pincode, building, street, area, age, blood_group, dob, gender, misaq, marital_status, date_of_nikah, date_of_nikah_hijri, vatan, nationality, jamaat, jamiaat, idara, category, organisation, familly_tree, profile_pic } = user_info || {};
  const { qualification, occupation, jamea_standard, jamea_degree, tr_no, hifz_degree_year, hifz_degree, farig_year } = education || {};
  const { account_name, account_number, bank_name, branch_name, swift, ifsc } = bank || {};
  const { passport_name, passport_number, issue_date, expiry_date, aadhar_no, pan_no } = govt_docs || {};
  const { mawze_name, nature_of_visit, tagged_initiative, other_participants } = mawze || {};
  const { level_of_competence, industry_type, skill_set_type } = skill || {};

  // header data 
  // const resp= masterData?.responsibility?.filter(res=>office?.responsibility==res.id)
  // const c_role= masterData?.role?.filter(res=>office.role==res.id)
  console.log(masterData, mawze, 'masterData');
  const resp = masterData?.responsibility?.filter(res => {
    const parsedResId = parseInt(res.id);
    return office?.responsibility?.includes(parsedResId);
  });
  const c_role = masterData?.role?.filter(res => {
    const parsedResId = parseInt(res.id);
    return office?.role?.includes(parsedResId);
  });
  const mawzeName = masterData?.mawze_name?.filter(res => {
    const parsedResId = parseInt(res.id);
    return mawze?.mawze_name?.includes(parsedResId);
  });
  // skillset  data 
  const ind_type = masterData?.industry_type?.filter(res => {
    const parsedResId = parseInt(res.id);
    return skill?.industry_type?.includes(parsedResId);
  });
  const nature_visit = masterData?.nature_of_visit?.filter(res => {
    const parsedResId = parseInt(res.id);
    return mawze?.nature_of_visit?.includes(parsedResId);
  });
  const tag_ini = masterData?.tagged_initiative?.filter(res => {
    const parsedResId = parseInt(res.id);
    return mawze?.tagged_initiative?.includes(parsedResId);
  });
  const other_parti = masterData?.other_participants?.filter(res => {
    const parsedResId = parseInt(res.id);
    return mawze?.other_participants?.includes(parsedResId);
  });
  let skill_set = []
  skill_set = masterData?.skill_set_type?.filter(res => skill?.skill_set_type == res.id)
  const mawze_tag_init = masterData?.tagged_initiative?.filter(res => mawze?.tagged_initiative == res.id)

  return <Document>
    <Page size="A4" wrap>
      <View style={styles.containerRaunded}>
        <Image src={Logo} crossOrigin="anonymous" style={styles.watermark} />
        {/* header start  */}
        <View className="test_class" style={styles.headerStyle} fixed>
          <View style={styles.pdfTopbar}>
            <View style={styles.flexColumnStart}>
              <Text style={styles.textStyle}>
                Badri Mahal, Borabazar Precinct, Ballard Estate, Fort,

              </Text>
              <Text style={styles.textStyle}>
                Mumbai, Maharashtra 400001
              </Text>
              <View style={{ marginTop: 5 }}>
                <Text style={styles.textStyle}>+91-987 456 3212 | 022 - 456 321</Text>
              </View>
            </View>
            <View style={{ position: 'relative' }}>
              <Image src={Logo} style={styles.image} />
            </View>
          </View>
          <View style={styles.bottomHeader}>
            <View style={styles.nameSecWrapper}>
              {profile_pic ? <Image src={`${process.env.REACT_APP_FILE_BASE_URL}/${profile_pic}`} crossOrigin="anonymous" style={styles.imageRounded} /> : <Image src={Nouser} crossOrigin="anonymous" style={styles.imageRoundedNouser} />}
              <View style={styles.nameSec}>
                <Text style={styles.textStyle}>
                  {its_no || ""}
                </Text>
                <Text style={styles.nameArabic}>
                  {arabic_name || ""}
                </Text>
                <Text style={styles.textStyle}>
                  {name || ""}
                </Text>
              </View>
            </View>
            <View style={styles.officeInfo}>
              <Text style={styles.contentPair}><Text style={styles.label}>Role:</Text><Text style={styles.textStyle}> {c_role ? c_role[0]?.title || '-' : null}</Text></Text>
              <Text style={styles.contentPair}><Text style={styles.label}>Responsibility:</Text><Text style={styles.textStyle}> {resp ? resp[0]?.title || '-' : null}</Text></Text>
              <Text style={styles.contentPair}><Text style={styles.label}>Curr. Assigned Initiative:</Text><Text style={styles.textStyle}> {office?.current_assigned_initiative || '-'}</Text></Text>
              <Text style={styles.contentPair}><Text style={styles.label}>Curr. Assigned Jamiat:</Text><Text style={styles.textStyle}> {office?.current_assigned_jamiat || '-'}</Text></Text>
            </View>
          </View>
          {/* header end  */}
          {/* body start  */}
        </View>
        <View style={styles.pdfBody}>
          <View style={styles.twoSecBody}>
            {/* sidebar start  */}
            <View style={styles.sidebar}>
              <View style={styles.commonBox}>
                <View style={styles.flexCenter}>
                  <View style={styles.mainHeadingWrapper}>
                    <Image src={Before} style={styles.imgBefore} />
                    <Text style={styles.mainHeading}>
                      Contact Info
                    </Text>
                    <Image src={After} style={styles.imgAfter} />
                  </View>
                </View>
                <View style={styles.commonBoxBody}>
                  <View style={styles.borderBottomGray}> <Text style={styles.contentPair}><Text style={styles.label}>WhatsApp:</Text><Text style={styles.textStyle}> {whats_app || '-'}</Text></Text></View>
                  <View style={styles.borderBottomGray}> <Text style={styles.contentPair}><Text style={styles.label}>Phone:</Text><Text style={styles.textStyle}> {phone || '-'}</Text></Text></View>
                  <View style={styles.borderBottomGray}> <Text style={styles.contentPair}><Text style={styles.label}>Email:</Text><Text style={styles.textStyle}> {email || '-'}</Text></Text></View>
                  <View style={{ display: 'flex', flexDirection: 'column', marginBottom: 5, gap: 2 }}>
                    <Text style={styles.label}>Address:</Text>
                    <Text style={styles.textStyle}>{address}{building ? `, ${building}` : ''}{street ? `, ${street}` : ''}{area ? `, ${area},` : ''} {city ? `${city.trim()}` : ''}{pincode ? `, ${pincode}` : ''}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.commonBox}>
                <View style={styles.flexCenter}>
                  <View style={styles.mainHeadingWrapper}>
                    <Image src={Before} style={styles.imgBefore} />
                    <Text style={styles.mainHeading}>
                      Education Details
                    </Text>
                    <Image src={After} style={styles.imgAfter} />
                  </View>
                </View>
                <View style={styles.commonBoxBody}>
                  <View style={styles.borderBottomGray}><View style={{ display: 'flex', flexDirection: 'column', marginBottom: 5, gap: 2 }}><Text style={styles.label}>Qualification:</Text><Text style={styles.textStyle}>{qualification || '-'}</Text></View></View>
                  <View style={styles.borderBottomGray}><View style={{ display: 'flex', flexDirection: 'column', marginBottom: 5, gap: 2 }}><Text style={styles.label}>Occupation:</Text><Text style={styles.textStyle}>{occupation || '-'}</Text></View></View>
                  <View style={styles.borderBottomGray}><Text style={styles.contentPair}><Text style={styles.label}>Jamea Standard:</Text><Text style={styles.textStyle}> {jamea_standard || '-'}</Text></Text></View>
                  <View style={styles.borderBottomGray}><View style={{ display: 'flex', flexDirection: 'column', marginBottom: 5, gap: 2 }}><Text style={styles.label}>Jamea Degree:</Text><Text style={styles.textStyle}>{jamea_degree || '-'}</Text></View></View>
                  <View style={styles.borderBottomGray}><Text style={styles.contentPair}><Text style={styles.label}>Farig Year:</Text><Text style={styles.textStyle}> {farig_year || '-'}</Text></Text></View>
                  <Text style={styles.contentPair}><Text style={styles.label}>TR No:</Text><Text style={styles.textStyle}> {tr_no || '-'}</Text></Text>
                </View>
                <View style={styles.degreeTable}>
                  <View style={styles.degreeTableHeadRow}>
                    <View style={styles.degreeTableColLeft}>
                      <Text style={styles.textStyleSm}>Hifz Degree:</Text>
                    </View>
                    <View style={styles.degreeTableColRight}>
                      <Text style={styles.borderLeftTextSm} >Year</Text>
                    </View>
                  </View>
                  <View style={styles.borderBottomGrayMH10}>
                    <View style={styles.degreeTableRow}>
                      <View style={styles.degreeTableColLeft}>
                        <Text style={styles.textStyleSm}>{hifz_degree || '-'}</Text>
                      </View>
                      <View style={styles.degreeTableColRight}>
                        <Text style={styles.borderLeftSm}>{hifz_degree_year || '-'}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {/* sidebar end  */}
            {/* right side start  */}
            <View style={styles.bodyRight}>
              <View style={styles.container}>
                <View style={styles.mainHeadingWrapperPersonal}>
                  <Image src={Before} style={styles.imgBefore} />
                  <Text style={styles.mainHeading}>
                    Personal Information
                  </Text>
                  <Image src={After} style={styles.imgAfter} />
                </View>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Age:</Text><Text style={styles.textStyle}> {age || "-"}</Text></Text></Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Blood Group:</Text><Text style={styles.textStyle}> {blood_group || "-"}</Text></Text></Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>D.O.B:</Text><Text style={styles.textStyle}> {dob || "-"}</Text></Text></Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Gender:</Text><Text style={styles.textStyle}> {gender == "M" ? "Male" : gender == "F" ? "Female" : "Other" || "-"}</Text></Text></Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Misaq:</Text><Text style={styles.textStyle}> {misaq || "-"}</Text></Text></Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Marital Status:</Text><Text style={styles.textStyle}> {marital_status || "-"}</Text></Text></Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Date_Of_Nikah:</Text><Text style={styles.textStyle}> {date_of_nikah || "-"}</Text></Text></Text>
                    </View>
                    <View style={styles.tableCol}>
                      {/* <Text><Text style={styles.contentPair}><Text style={styles.label}>Date_Of_Nikah_Hijri:</Text><Text style={styles.textStyle}> { date_of_nikah_hijri || "-"}</Text></Text></Text> */}
                      <Text><Text style={styles.contentPair}><Text style={styles.label}></Text><Text style={styles.textStyle}></Text></Text></Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Vatan:</Text><Text style={styles.textStyle}> {vatan || "-"}</Text></Text></Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Nationality:</Text><Text style={styles.textStyle}> {nationality || "-"}</Text></Text></Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Jamaat:</Text><Text style={styles.textStyle}> {jamaat || "-"}</Text></Text></Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Jamiaat:</Text><Text style={styles.textStyle}> {jamiaat || "-"}</Text></Text></Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Idara:</Text><Text style={styles.textStyle}> {idara || "-"}</Text></Text></Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Category:</Text><Text style={styles.textStyle}> {category || "-"}</Text></Text></Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableColFull}>
                      <Text><Text style={styles.contentPair}><Text style={styles.label}>Organisation:</Text><Text style={styles.textStyle}> {organisation || "-"}</Text></Text></Text>
                    </View>
                  </View>
                </View>
                {/* family cards section  */}
                <View style={styles.familySec} wrap={false}>
                  {familly_tree?.map((member) => (
                    <FamilyCardPDF
                      key={member.its_no}
                      image={member.profile_pic}
                      title={member.title}
                      name={member.fullname}
                      idara={member.idara}
                      its_no={member.its_no}
                      age={member.age}
                    />
                  ))
                  }
                </View>
                {/* family cards section  */}


              </View>
            </View>
            {/* right side end  */}

          </View>
          {/* full width body start  */}
          <View wrap={false} style={styles.columnCenter}>
            <View style={styles.mainHeadingWrapperBank}>
              <Image src={Before} style={styles.imgBefore} />
              <Text style={styles.mainHeading}>
                Banking Details
              </Text>
              <Image src={After} style={styles.imgAfter} />
            </View>
            <View style={styles.tableBank}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Bank Name:</Text><Text style={styles.textStyle}> {bank_name || "-"}</Text></Text></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Branch Name:</Text><Text style={styles.textStyle}> {branch_name || "-"}</Text></Text></Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Account Name:</Text><Text style={styles.textStyle}> {account_name || "-"}</Text></Text></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Account No:</Text><Text style={styles.textStyle}> {account_number || "-"}</Text></Text></Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>IFSC:</Text><Text style={styles.textStyle}> {ifsc || "-"}</Text></Text></Text>
                </View>
                <View style={styles.tableCol2Round}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>SWIFT:</Text><Text style={styles.textStyle}> {swift || "-"}</Text></Text></Text>
                </View>
              </View>
            </View>
          </View>
          <View wrap={false} style={styles.columnCenter}>
            <View style={styles.mainHeadingWrapperGovt}>
              <Image src={Before} style={styles.imgBefore} />
              <Text style={styles.mainHeading}>
                Government Documents
              </Text>
              <Image src={After} style={styles.imgAfter} />
            </View>
            <View style={styles.tableGovt}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Passport Name:</Text><Text style={styles.textStyle}> {passport_name || "-"}</Text></Text></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Passport No:</Text><Text style={styles.textStyle}> {passport_number || "-"}</Text></Text></Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Issue Date:</Text><Text style={styles.textStyle}> {issue_date || "-"}</Text></Text></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Expiry Date:</Text><Text style={styles.textStyle}> {expiry_date || "-"}</Text></Text></Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Aadhar No:</Text><Text style={styles.textStyle}> {aadhar_no || "-"}</Text></Text></Text>
                </View>
                <View style={styles.tableCol2Round}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>PAN No:</Text><Text style={styles.textStyle}> {pan_no || "-"}</Text></Text></Text>
                </View>
              </View>
            </View>
          </View>
          <View wrap={false} style={styles.columnCenter}>
            <View style={styles.mainHeadingWrapperSkill}>
              <Image src={Before} style={styles.imgBefore} />
              <Text style={styles.mainHeading}>
                Skill Set (evaluated)
              </Text>
              <Image src={After} style={styles.imgAfter} />
            </View>
            <View style={styles.skillTable}>
              <View style={styles.skillHeadRow}>
                <View style={styles.skillTableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Skill Set</Text></Text></Text>
                </View>
                <View style={styles.skillTableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Level of Competence</Text></Text></Text>
                </View>
                <View style={styles.skillTableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Industry Type</Text></Text></Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.skillTableCol}>
                  <Text style={{ textAlign: 'center' }}><Text style={styles.textStyle}>{skill_set ? skill_set[0]?.title : '-'}</Text></Text>
                </View>
                <View style={styles.skillTableCol}>
                  <Text><Text style={styles.textStyle}>{level_of_competence?.toUpperCase() || '-'}</Text></Text>
                </View>
                <View style={styles.tableCol3Round}>
                  <Text><Text style={styles.textStyle}>{ind_type?.map(ind => ind.title).join(', ') || '-'}</Text></Text>
                </View>
              </View>
            </View>
          </View>
          <View wrap={false} style={styles.columnCenter}>
            <View style={styles.mainHeadingWrapperMawze}>
              <Image src={Before} style={styles.imgBefore} />
              <Text style={styles.mainHeading}>
                Mawze Visit List
              </Text>
              <Image src={After} style={styles.imgAfter} />
            </View>
            <View style={styles.mawzeTable}>
              <View style={styles.mawzeHeadRow}>
                <View style={styles.mawzeTableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Mawze Name</Text></Text></Text>
                </View>
                <View style={styles.mawzeTableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Nature of Visit</Text></Text></Text>
                </View>
                <View style={styles.mawzeTableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Tagged Initiative</Text></Text></Text>
                </View>
                <View style={styles.mawzeTableCol}>
                  <Text><Text style={styles.contentPair}><Text style={styles.label}>Other Participants</Text></Text></Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.mawzeTableCol}>
                  <Text style={styles.textStyle}>{mawzeName?.map(ind => ind.title) || "-"}</Text>
                </View>
                <View style={styles.mawzeTableCol}>
                  <Text style={styles.textStyle}>{nature_visit?.map(ind => ind.title).join(', ') || '-'}</Text>
                </View>
                <View style={styles.mawzeTableCol}>
                  <Text style={styles.textStyle}>{tag_ini?.map(ind => ind.title).join(', ') || '-'}</Text>
                </View>
                <View style={styles.tableCol4Round}>
                  <Text style={styles.textStyle}>{other_parti?.map(ind => ind.title).join(', ') || '-'}</Text>
                </View>
              </View>
            </View>
          </View>
          {/* full width body end  */}
        </View>
      </View>
      {/* <Text render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`} fixed /> */}
    </Page>
  </Document>
}

export default PdfView;

